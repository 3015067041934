import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';

import { toast } from 'react-toastify';
import { addContentService, getContentService, upload } from './HomeContent.service.js';

import { Player } from 'video-react';

import './HomeContent.scss';

function HomeContent() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	const {
		fields: reviewFields,
		append: reviewAppends,
		remove: reviewRemove,
	} = useFieldArray({
		control,
		name: 'reviews',
	});

	const {
		fields: portFolioFields,
		append: portFolioAppends,
		remove: portFolioRemove,
	} = useFieldArray({
		control,
		name: 'portFolio',
	});

	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');

	const [content, setContent] = useState({});
	const [reRender, setReRender] = useState(0);

	const [bannerImg, setBannerImg] = useState('');
	const [mobileBannerImg, setMobileBannerImg] = useState('');

	const [vdoFile, setVdoFile] = useState('');
	const [vdoPreview, setVdoPreview] = useState('');
	const [vdoPlaceholder, setVdoPlaceholder] = useState('');
	const [reviewLogo, setReviewLogo] = useState([]);
	const [reviewerImg, setReviewerImg] = useState([]);

	const [portFolioImg1, setPortFolioImg1] = useState([]);
	const [portFolioImg2, setPortFolioImg2] = useState([]);
	const [portFolioClientLogo, setPortFolioClientLogo] = useState([]);

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.bannerTitle = content?.content?.banner?.title;
		defaultValues.bannerDescription = content?.content?.banner?.description;
		defaultValues.vdo_section_desc = content?.content?.VdoSection?.description;
		defaultValues.serviceTitle = content?.content?.service?.title;
		defaultValues.serviceDescription = content?.content?.service?.description;

		defaultValues.service1Title = content?.content?.service?.letftItem?.title1;
		defaultValues.service1Description = content?.content?.service?.letftItem?.description1;
		defaultValues.service2Title = content?.content?.service?.letftItem?.title2;
		defaultValues.service2Description = content?.content?.service?.letftItem?.description2;
		defaultValues.service3Title = content?.content?.service?.letftItem?.title3;
		defaultValues.service3Description = content?.content?.service?.letftItem?.description3;
		defaultValues.service4Title = content?.content?.service?.rightItem?.title4;
		defaultValues.service4Description = content?.content?.service?.rightItem?.description4;
		defaultValues.service5Title = content?.content?.service?.rightItem?.title5;
		defaultValues.service5Description = content?.content?.service?.rightItem?.description5;
		defaultValues.service6Title = content?.content?.service?.rightItem?.title6;
		defaultValues.service6Description = content?.content?.service?.rightItem?.description6;

		/* const rImg = [];
		const rLogo = [];
		const reviewArr = [];

		content?.content?.review.map((val, i) => {
			reviewArr.push({
				reviewerName: val?.reviewerName,
				reviewerDesig: val?.reviewerDesig,
				reviewContent: val?.reviewContent,
				link: val?.link,
			});
			rImg.push(`${content?.fileBaseUrl}/uploads/${val?.reviewerImg}`);
			rLogo.push(`${content?.fileBaseUrl}/uploads/${val?.logo}`);
		});

		defaultValues.reviews = reviewArr;

		const portImg1Arr = [];
		const portImg2Arr = [];
		const portLogoArr = [];
		const portArr = [];

		content?.content?.portFolio?.map((val, i) => {
			portArr.push({
				backGroundText: val?.backGroundText,
				title: val?.title,
				link: val?.link,
				color: val?.color,
				description: val?.description,
			});
			portImg1Arr.push(`${content?.fileBaseUrl}/uploads/${val?.img1}`);
			portImg2Arr.push(`${content?.fileBaseUrl}/uploads/${val?.img2}`);
			portLogoArr.push(`${content?.fileBaseUrl}/uploads/${val?.clientLogo}`);
		});

		defaultValues.portFolio = portArr; */

		await reset({ ...defaultValues });

		if (content?.content?.banner?.img) {
			setBannerImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.img}`);
		}
		if (content?.content?.banner?.mobileImg) {
			setMobileBannerImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.mobileImg}`);
		}
		if (content?.content?.VdoSection?.video) {
			setVdoPreview(`${content?.fileBaseUrl}/uploads/${content?.content?.VdoSection?.video}`);
			setVdoFile(`${content?.fileBaseUrl}/uploads/${content?.content?.VdoSection?.video}`);
		}
		if (content?.content?.VdoSection?.vdoPlaceholder) {
			setVdoPlaceholder(`${content?.fileBaseUrl}/uploads/${content?.content?.VdoSection?.vdoPlaceholder}`);
		}

		/* if (rImg.length > 0) {
			setReviewLogo(rImg);
		}
		if (rLogo.length > 0) {
			setReviewerImg(rLogo);
		}

		if (portImg1Arr.length > 0) {
			setPortFolioImg1(portImg1Arr);
		}
		if (portImg2Arr.length > 0) {
			setPortFolioImg2(portImg2Arr);
		}
		if (portLogoArr.length > 0) {
			setPortFolioClientLogo(portLogoArr);
		} */
	};

	const getContent = async () => {
		// dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			console.log(result?.data?.data);
			setContent(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
		// dispatch(allActions.loadingAction.hideLoading());
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				// console.log("Called", reader);
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const handleMobileBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setMobileBannerImg(img);
		}
	};

	const handleVdoPlaceHolder = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setVdoPlaceholder(img);
		}
	};

	const handleVideo = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 100000000) {
			toast.error('Video size is too large. Video size must be less than 100 MB.');
			return false;
		}
		let vdo = await getBase64(file);
		if (vdo) {
			setVdoFile(vdo);
		}
		var url = URL.createObjectURL(file);
		setVdoPreview(url);
	};

	const handleReviewLogo = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			reviewLogo[index] = img;
			setReviewLogo([...reviewLogo]);
			setReRender(!reRender);
		}
	};

	const handleReviewerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			reviewerImg[index] = img;
			setReviewerImg([...reviewerImg]);
			setReRender(!reRender);
		}
	};

	const removeReviewItem = async (index) => {
		reviewRemove(index);
		setReviewLogo(reviewLogo.filter((val, i) => i !== index));
		setReviewerImg(reviewerImg.filter((val, i) => i !== index));
	};

	const onSubmit = async (data) => {
		// const img1 = reviewLogo.filter(function (element) {
		// 	return element !== undefined;
		// });
		// const img2 = reviewerImg.filter(function (element) {
		// 	return element !== undefined;
		// });

		// const img3 = portFolioImg1.filter(function (element) {
		// 	return element !== undefined;
		// });
		// const img4 = portFolioImg2.filter(function (element) {
		// 	return element !== undefined;
		// });
		// const img5 = portFolioClientLogo.filter(function (element) {
		// 	return element !== undefined;
		// });

		if (
			// data?.reviews?.length !== img1?.length ||
			// data?.reviews?.length !== img2?.length ||
			!bannerImg ||
			!mobileBannerImg ||
			!vdoFile ||
			!vdoPlaceholder
			// img3?.length !== data?.portFolio?.length ||
			// img4?.length !== data?.portFolio?.length ||
			// img5?.length !== data?.portFolio?.length
		) {
			toast.error(`Please select all image`);
			return false;
		}

		let formData = {
			...data,
			bannerImg,
			mobileBannerImg,
			vdoFile,
			vdoPlaceholder,
			// reviewLogo,
			// reviewerImg,
			// portFolioImg1,
			// portFolioImg2,
			// portFolioClientLogo,
		};

		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	const handlePortFolioImg1 = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			portFolioImg1[index] = img;
			setPortFolioImg1([...portFolioImg1]);
			setReRender(!reRender);
		}
	};
	const handlePortFolioImg2 = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			portFolioImg2[index] = img;
			setPortFolioImg2([...portFolioImg2]);
			setReRender(!reRender);
		}
	};

	const handlePortFolioClientLogo = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			portFolioClientLogo[index] = img;
			setPortFolioClientLogo([...portFolioClientLogo]);
			setReRender(!reRender);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Home' },
				]}
				title="Home Content"
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<div className="row">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Banner Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Banner Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="content_img" onChange={handleBannerImg} />
														<label htmlFor="content_img">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Mobile Banner Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="mobileBannerImg" onChange={handleMobileBannerImg} />
														<label htmlFor="mobileBannerImg">
															{!mobileBannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{mobileBannerImg && (
																<div className="preview-img">
																	<img src={mobileBannerImg} className="img-block" alt="mobileBannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													{/* <Controller
														name="bannerTitle"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																initData={content?.content?.banner?.title}
																onChange={(event) => {
																	onChange(event.editor.getData());
																}}
															/>
														)}
														rules={{ required: 'Title is required' }}
													/> */}
													<textarea
														className="form-control"
														placeholder="Title"
														rows={5}
														{...register('bannerTitle', { required: 'Title is required' })}
													></textarea>
													{errors.bannerTitle && <InputErrorMsg error={errors.bannerTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-6 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('bannerDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingTwo">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseTwo"
										aria-expanded="false"
										aria-controls="panelsStayOpen-collapseTwo"
									>
										Video Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
									<div className="accordion-body">
										<div className="row g-3">
											<div className="col-sm-12 col-md-6 col-lg-4 col-12">
												<div className="upload-video-box">
													<div className="upload-video-wrap">
														<input type="file" className="form-control" id="content_vdo" onChange={handleVideo} />
														<label htmlFor="content_vdo">
															<div className="upload-text">
																<i className="fa fa-download"></i>
																<p>Upload Video...</p>
															</div>
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-4 col-12">
												<div className="video-preview">{vdoPreview && <Player playsInline src={vdoPreview} />}</div>
											</div>
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="vdo_placeholder" onChange={handleVdoPlaceHolder} />
														<label htmlFor="vdo_placeholder">
															{!vdoPlaceholder && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload video placeholder image...</p>
																</div>
															)}
															{vdoPlaceholder && (
																<div className="preview-img">
																	<img src={vdoPlaceholder} className="img-block" alt="video placeholder" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('vdo_section_desc', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.vdo_section_desc && <InputErrorMsg error={errors.vdo_section_desc?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading7">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse7"
										aria-expanded="false"
										aria-controls="panelsStayOpen-collapse7"
									>
										PortFolio Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse7" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading7">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												{portFolioFields.map((item, index) => (
													<>
														<div className="card" key={index}>
															<div className="card-header">
																<div className="row justify-content-between align-items-center">
																	<div className="col-auto">
																		<div className="list-head">item {index + 1}</div>
																	</div>
																	<div className="col-auto">
																		<div className="quotes-delete">
																			<button
																				type="button"
																				className="cmn-btn-tag"
																			// onClick={() => removePortFolioItem(index)}
																			>
																				<i className="fas fa-trash-alt"></i>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card-body">
																<div className="row justify-content-center">
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Image 1</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`portfolioImg1${index}`} onChange={(e) => handlePortFolioImg1(e, index)} />
																				<label htmlFor={`portfolioImg1${index}`}>
																					{!portFolioImg1?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Image...</p>
																						</div>
																					)}
																					{portFolioImg1?.[index] && (
																						<div className="preview-img">
																							<img src={portFolioImg1?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Image 2</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`portFolioImg2${index}`} onChange={(e) => handlePortFolioImg2(e, index)} />
																				<label htmlFor={`portFolioImg2${index}`}>
																					{!portFolioImg2?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Image...</p>
																						</div>
																					)}
																					{portFolioImg2?.[index] && (
																						<div className="preview-img">
																							<img src={portFolioImg2?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>

																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Client Logo</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input
																					type="file"
																					id={`clientLogo${index}`}
																					onChange={(e) => handlePortFolioClientLogo(e, index)}
																				/>
																				<label htmlFor={`clientLogo${index}`}>
																					{!portFolioClientLogo?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload client logo...</p>
																						</div>
																					)}
																					{portFolioClientLogo?.[index] && (
																						<div className="preview-img">
																							<img src={portFolioClientLogo?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12">
																		<div className="row">
																			<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																				<label className="admin-lbl">Background Text</label>
																				<div className="admim-input-wrap">
																					<input
																						type="text"
																						className="form-control admim-input-style"
																						placeholder="Background Text"
																						{...register(`portFolio[${index}].backGroundText`, {
																							required: 'Background Text is required',
																						})}
																					/>
																					{errors?.portFolio?.[index]?.backGroundText && (
																						<InputErrorMsg error={errors?.portFolio?.[index]?.backGroundText?.message} />
																					)}
																				</div>
																			</div>
																			<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																				<label className="admin-lbl">Title</label>
																				<div className="admim-input-wrap">
																					<input
																						type="text"
																						className="form-control admim-input-style"
																						placeholder="Title"
																						{...register(`portFolio[${index}].title`, {
																							required: 'Title is required',
																						})}
																					/>
																					{errors?.portFolio?.[index]?.title && (
																						<InputErrorMsg error={errors?.portFolio?.[index]?.title?.message} />
																					)}
																				</div>
																			</div>
																			<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																				<label className="admin-lbl">Link</label>
																				<div className="admim-input-wrap">
																					<input
																						type="text"
																						className="form-control admim-input-style"
																						placeholder="Link"
																						{...register(`portFolio[${index}].link`, {
																							required: 'Link is required',
																						})}
																					/>
																					{errors?.portFolio?.[index]?.link && (
																						<InputErrorMsg error={errors?.portFolio?.[index]?.link?.message} />
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-lg-6 col-md-6 col-sm-6 col-12">
																			<label className="admin-lbl">Description:</label>
																			<div className="admim-input-wrap">
																				<textarea
																					className="form-control"
																					placeholder="Description"
																					rows={5}
																					{...register(`portFolio[${index}].description`, {
																						required: 'Description is required',
																					})}
																				></textarea>
																				{errors?.portFolio?.[index]?.description && (
																					<InputErrorMsg error={errors?.portFolio?.[index]?.description?.message} />
																				)}
																			</div>
																		</div>
																		<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																			<label className="admin-lbl">Color</label>
																			<div className="admim-input-wrap">
																				<input
																					type="color"
																					className="form-control admim-input-style"
																					placeholder="Color"
																					{...register(`portFolio[${index}].color`, {
																						required: 'Color is required',
																					})}
																				/>
																				{errors?.portFolio?.[index]?.color && (
																					<InputErrorMsg error={errors?.portFolio?.[index]?.color?.message} />
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												))}
												<div className="cmn-btn-tag-wrap">
													<button
														type="button"
														className="cmn-btn-tag black-bg"
														onClick={() =>
															portFolioAppends({
																logo: '',
																reviewerName: '',
																reviewerDesig: '',
																link: '',
																reviewerImg: '',
																reviewContent: '',
															})
														}
													>
														Add more item
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading4">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse4"
										aria-expanded="false"
										aria-controls="panelsStayOpen-collapse4"
									>
										Service Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse4" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
									<div className="accordion-body">
										<div className="row g-2">
											<div className="col-sm-12 col-md-6 col-lg-6 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control admim-input-style"
														placeholder="Title"
														{...register('serviceTitle', { required: 'Title is required' })}
													/>
													{errors.serviceTitle && <InputErrorMsg error={errors.serviceTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control admim-input-style"
														placeholder="Description"
														{...register('serviceDescription', {
															required: 'Description is required',
														})}
													/>
													{errors.serviceDescription && <InputErrorMsg error={errors.serviceDescription?.message} />}
												</div>
											</div>
										</div>
										<div className="row g-2 mt-0">
											<div className="col-sm-12 col-md-6 col-lg-6 col-12">
												<div className="card">
													<div className="card-header">Left Side</div>
													<div className="card-body">
														<div className="row">
															<h4>Item 1</h4>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Title:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Title"
																		{...register('service1Title', {
																			required: 'Title is required',
																		})}
																	/>
																	{errors.service1Title && <InputErrorMsg error={errors.service1Title?.message} />}
																</div>
															</div>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Description:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Description"
																		{...register('service1Description', {
																			required: 'Description is required',
																		})}
																	/>
																	{errors.service1Description && <InputErrorMsg error={errors.service1Description?.message} />}
																</div>
															</div>

															<h4>Item 2</h4>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Title:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Title"
																		{...register('service2Title', {
																			required: 'Title is required',
																		})}
																	/>
																	{errors.service2Title && <InputErrorMsg error={errors.service2Title?.message} />}
																</div>
															</div>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Description:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Description"
																		{...register('service2Description', {
																			required: 'Description is required',
																		})}
																	/>
																	{errors.service2Description && <InputErrorMsg error={errors.service2Description?.message} />}
																</div>
															</div>

															<h4>Item 3</h4>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Title:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Title"
																		{...register('service3Title', {
																			required: 'Title is required',
																		})}
																	/>
																	{errors.service3Title && <InputErrorMsg error={errors.service3Title?.message} />}
																</div>
															</div>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Description:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Description"
																		{...register('service3Description', {
																			required: 'Description is required',
																		})}
																	/>
																	{errors.service3Description && <InputErrorMsg error={errors.service3Description?.message} />}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-12">
												<div className="card">
													<div className="card-header">Right Side</div>
													<div className="card-body">
														<div className="row">
															<h4>Item 4</h4>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Title:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Title"
																		{...register('service4Title', {
																			required: 'Title is required',
																		})}
																	/>
																	{errors.service4Title && <InputErrorMsg error={errors.service4Title?.message} />}
																</div>
															</div>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Description:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Description"
																		{...register('service4Description', {
																			required: 'Description is required',
																		})}
																	/>
																	{errors.service4Description && <InputErrorMsg error={errors.service4Description?.message} />}
																</div>
															</div>

															<h4>Item 5</h4>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Title:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Title"
																		{...register('service5Title', {
																			required: 'Title is required',
																		})}
																	/>
																	{errors.service5Title && <InputErrorMsg error={errors.service5Title?.message} />}
																</div>
															</div>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Description:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Description"
																		{...register('service5Description', {
																			required: 'Description is required',
																		})}
																	/>
																	{errors.service5Description && <InputErrorMsg error={errors.service5Description?.message} />}
																</div>
															</div>

															<h4>Item 6</h4>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Title:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Title"
																		{...register('service6Title', {
																			required: 'Title is required',
																		})}
																	/>
																	{errors.service6Title && <InputErrorMsg error={errors.service6Title?.message} />}
																</div>
															</div>
															<div className="col-sm-12 col-md-6 col-lg-6 col-12">
																<label className="admin-lbl">Description:</label>
																<div className="admim-input-wrap">
																	<input
																		type="text"
																		className="form-control admim-input-style"
																		placeholder="Description"
																		{...register('service6Description', {
																			required: 'Description is required',
																		})}
																	/>
																	{errors.service6Description && <InputErrorMsg error={errors.service6Description?.message} />}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading5">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse5"
										aria-expanded="false"
										aria-controls="panelsStayOpen-collapse4"
									>
										Review Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse5" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading5">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												{reviewFields.map((item, index) => (
													<>
														<div className="card" key={index}>
															<div className="card-header">
																<div className="row justify-content-between align-items-center">
																	<div className="col-auto">
																		<div className="list-head">item</div>
																	</div>
																	<div className="col-auto">
																		<div className="quotes-delete">
																			<button type="button" className="cmn-btn-tag" onClick={() => removeReviewItem(index)}>
																				<i className="fas fa-trash-alt"></i>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card-body">
																<div className="row justify-content-center">
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Logo</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`review_logo${index}`} onChange={(e) => handleReviewLogo(e, index)} />
																				<label htmlFor={`review_logo${index}`}>
																					{!reviewLogo?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Logo...</p>
																						</div>
																					)}
																					{reviewLogo?.[index] && (
																						<div className="preview-img">
																							<img src={reviewLogo?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>

																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Reviewer Image</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`reviewerImg${index}`} onChange={(e) => handleReviewerImg(e, index)} />
																				<label htmlFor={`reviewerImg${index}`}>
																					{!reviewerImg?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload reviewer image...</p>
																						</div>
																					)}
																					{reviewerImg?.[index] && (
																						<div className="preview-img">
																							<img src={reviewerImg?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12">
																		<div className="row">
																			<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																				<label className="admin-lbl">Reviewer Name</label>
																				<div className="admim-input-wrap">
																					<input
																						type="text"
																						className="form-control admim-input-style"
																						placeholder="Reviewer Name"
																						{...register(`reviews[${index}].reviewerName`, {
																							required: 'Reviewer name is required',
																						})}
																					/>
																					{errors?.reviews?.[index]?.reviewerName && (
																						<InputErrorMsg error={errors?.reviews?.[index]?.reviewerName?.message} />
																					)}
																				</div>
																			</div>
																			<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																				<label className="admin-lbl">Reviewer Designation</label>
																				<div className="admim-input-wrap">
																					<input
																						type="text"
																						className="form-control admim-input-style"
																						placeholder="Reviewer Designation"
																						{...register(`reviews[${index}].reviewerDesig`, {
																							required: 'Reviewer designation is required',
																						})}
																					/>
																					{errors?.reviews?.[index]?.reviewerDesig && (
																						<InputErrorMsg error={errors?.reviews?.[index]?.reviewerDesig?.message} />
																					)}
																				</div>
																			</div>
																			<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																				<label className="admin-lbl">Link</label>
																				<div className="admim-input-wrap">
																					<input
																						type="text"
																						className="form-control admim-input-style"
																						placeholder="Link"
																						{...register(`reviews[${index}].link`, {
																							required: 'Link is required',
																						})}
																					/>
																					{errors?.reviews?.[index]?.link && (
																						<InputErrorMsg error={errors?.reviews?.[index]?.link?.message} />
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-lg-6 col-md-6 col-sm-6 col-12">
																			<label className="admin-lbl">Review Content:</label>
																			<div className="admim-input-wrap">
																				<textarea
																					className="form-control"
																					placeholder="Review Content"
																					rows={5}
																					{...register(`reviews[${index}].reviewContent`, {
																						required: 'Review Content is required',
																					})}
																				></textarea>
																				{errors?.reviews?.[index]?.reviewContent && (
																					<InputErrorMsg error={errors?.reviews?.[index]?.reviewContent?.message} />
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												))}
												<div className="cmn-btn-tag-wrap">
													<button
														type="button"
														className="cmn-btn-tag black-bg"
														onClick={() =>
															reviewAppends({
																logo: '',
																reviewerName: '',
																reviewerDesig: '',
																link: '',
																reviewerImg: '',
																reviewContent: '',
															})
														}
													>
														Add more item
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</div>

						<div className="row">
							<div className="col-12">
								<div className="cmn-btn-tag-wrap">
									<button type="submit" className="cmn-btn-tag">
										Save
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default HomeContent;
