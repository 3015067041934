import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Navigate, Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import NoImage from 'assets/images/avatar.png';
import { toast } from 'react-toastify';
import { listCareerCategoryService } from './ListCareerCategory.service.js';

const ListCareerCategory = () => {
	const [careerCategory, setCareerCategory] = useState([]);
	const [careerCategoryMeta, setCareerCategoryMeta] = useState({});
	const [page, setPage] = useState(1);
	const [redirect, setRedirect] = useState(0);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');

	useEffect(() => {
		getCareerCategoryList();
	}, [page]);

	const getCareerCategoryList = async () => {
		const list = await listCareerCategoryService(page);
		if (list.data.status === 200) {
			setCareerCategory(list.data.data.records);
			setCareerCategoryMeta(list.data.data.meta);
		} else {
			toast.error(list.data.error);
		}
	};

	const columns = [
		{
			name: 'Image',
			cell: (row) => (
				<span className="img-block">
					<img alt={row?.img} src={`${process.env.REACT_APP_RESOURCE_URL}/${row?.img}`} height={100} />
				</span>
			),
		},
		{
			name: 'Title',
			selector: (row) => row?.title,
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link to={`/admin/cms/edit-career-category/${row?.id}`} className="dropdown-item">
							Edit
						</Link>
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Career Category' },
					{ link: '#', name: 'List' },
				]}
				title="Career Category List"
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="row mb-3 justify-content-between">
				<div className="col-lg-6">
					<div className="cmn-a-tag-wrap">
						<Link to={'/admin/cms/add-career-category'} className="cmn-a-tag">
							Add career Category
						</Link>
					</div>
				</div>
			</div>
			<div className="datatable-wrap">
				<DataTable
					// title="Employees"
					columns={columns}
					data={careerCategory}
					striped
					// highlightOnHover
					// selectableRows
					pagination
					paginationServer
					paginationTotalRows={careerCategoryMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
};

export default ListCareerCategory;
