import { default as api } from 'config/frontApiConf';

export const getContentService = async () => {
	try {
		let result = await api.get(`/get-portfolio-content`);
		return result;
	} catch (error) {
		return error;
	}
};
