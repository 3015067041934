import React, { useEffect, useState } from 'react';
import './Testimonial.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import scheduale from 'assets/images/common-image/scheduale.jpg';
import google from 'assets/images/common-image/google-icon.png';
import clutch from 'assets/images/common-image/clutch-icon.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService } from './Testimonial.service.js';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

const Testimonial = () => {
	const dispatch = useDispatch();

	const [testimonial, setTestimonial] = useState([]);
	const [testimonialMeta, setTestimonialMeta] = useState({});
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, [page]);

	const getContent = async () => {
		const result = await getContentService(page);
		if (result.data.status === 200) {
			setTestimonial(result?.data?.data?.records);
			setTestimonialMeta(result?.data?.data?.meta);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<section class="testimonialTitle">
				<h2>Testimonial</h2>
			</section>
			<section className="testimonialPage">
				<div className="container">
					<div className="row">
						{testimonial.length > 0 ? (
							<>
								{testimonial.map((val, i) => (
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="testiContent">
											<div className="headIcon">
												<img src={val?.logo ? `${process.env.REACT_APP_RESOURCE_URL}/${val?.logo}` : google} alt="logo" />
											</div>
											<p
												dangerouslySetInnerHTML={{
													__html:
														val?.description ??
														`Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a fundamental
														level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital solution highly
														innovative and impactful!`,
												}}
											></p>
											<div className="d-flex flex-wrap justify-content-between align-items-center">
												<ul className="d-flex align-items-center">
													<li className="clintImg">
														<span>
															<img src={val?.avatar ? `${process.env.REACT_APP_RESOURCE_URL}/${val?.avatar}` : scheduale} alt="avatar" />
														</span>
													</li>
													<li className="clintInfo">
														<h5
															dangerouslySetInnerHTML={{
																__html: val?.clientName ?? `Jacob Eiting`,
															}}
														></h5>
														<h6
															dangerouslySetInnerHTML={{
																__html: val?.clientDesignation ?? `CEO & Co-Founder`,
															}}
														></h6>
													</li>
												</ul>
												<div className="link_wrap">
													<a className="link_text" target={'_blank'} href={val?.projectLink} rel="noreferrer">
														View Project
													</a>
												</div>
											</div>
										</div>
									</div>
								))}
							</>
						) : (
							<>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={google} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={clutch} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={google} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={clutch} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={google} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={google} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={clutch} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="testiContent">
										<div className="headIcon">
											<img src={google} alt="" />
										</div>
										<p>
											Getweb is the best software development company we’ve worked with so far. Unlike many developers, they understand UI/UX design on a
											fundamental level and create a smooth user journey no matter the technical difficulties. Highly recommended if you want your digital
											solution highly innovative and impactful!
										</p>
										<div className="d-flex flex-wrap justify-content-between align-items-center">
											<ul className="d-flex align-items-center">
												<li className="clintImg">
													<span>
														<img src={scheduale} alt="" />
													</span>
												</li>
												<li className="clintInfo">
													<h5>Jacob Eiting</h5>
													<h6>CEO & Co-Founder</h6>
												</li>
											</ul>
											<div className="link_wrap">
												<Link className="link_text" to={'/'}>
													View Project
												</Link>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						{testimonial.length > 0 && (
							<ReactPaginate
								previousLabel={'<'}
								nextLabel={'>'}
								breakLabel={'...'}
								pageCount={testimonialMeta?.totalPages}
								marginPagesDisplayed={2}
								pageRangeDisplayed={3}
								onPageChange={(page) => setPage(page.selected + 1)}
								containerClassName={'pagination justify-content-center custom-paginate'}
								pageClassName={'page-item'}
								pageLinkClassName={'page-link'}
								previousClassName={'page-item'}
								previousLinkClassName={'page-link'}
								nextClassName={'page-item'}
								nextLinkClassName={'page-link'}
								breakClassName={'page-item'}
								breakLinkClassName={'page-link'}
								activeClassName={'active'}
							/>
						)}
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Testimonial;
