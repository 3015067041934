import React from 'react';
import './PortfolioTechnologis.scss';
import './PortfolioTechnologisResponsive.scss';
import react from 'assets/images/common-image/react.svg';
import angular from 'assets/images/common-image/angular.svg';
import wordpress from 'assets/images/common-image/wordpress.svg';
import laravel from 'assets/images/common-image/laravel.svg';
import { useSelector, useDispatch } from 'react-redux';

const PortfolioTechnologis = () => {
	const portfolioDtlContent = useSelector((state) => state.content?.portfolioDetail);
	const portfolioDtlData = portfolioDtlContent?.portfolioDetailData?.content ? JSON.parse(portfolioDtlContent?.portfolioDetailData?.content) : '';

	return (
		<>
			<section className="PortfolioTechnologis">
				<div className="container">
					<div className="row">
						<div className="col-12 d-flex flex-wrap justify-content-center">
							<div className="technologiesList d-flex flex-wrap align-items-center">
								<h4>TECHNOLOGIES </h4>
								<ul className="d-flex align-items-center">
									{
										portfolioDtlData?.mainSection?.technologyImg?.map((val, i) => (
											<li key={`techonology-${i}`}>
												<span>
													<img src={val ? `${process.env.REACT_APP_RESOURCE_URL}/${val}` : react} alt="" />
												</span>
											</li>
										))
									}
									{/* <li>
										<span>
											<img src={react} alt="" />
										</span>
									</li> */}
									{/* <li>
										<span>
											<img src={angular} alt="" />
										</span>
									</li>
									<li>
										<span>
											<img src={laravel} alt="" />
										</span>
									</li>
									<li>
										<span>
											<img src={wordpress} alt="" />
										</span>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default PortfolioTechnologis;
