import { default as api } from 'config/authApiConf.js';
import { default as frontApi } from 'config/frontApiConf.js';

export const testimonialDetailService = async (slug = null) => {
	try {
		let result = await api.get(`/cms/testimonial-details/${slug}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const testimonialEditService = async (id = null, data = {}) => {
	try {
		let result = await api.put(`/cms/edit-testimonial/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const listLogoService = async (data = {}) => {
	try {
		let result = await frontApi.get('/list-logo');
		return result;
	} catch (error) {
		return error;
	}
};
