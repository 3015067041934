import axios from 'axios';

const auth_token = localStorage.getItem('auth_token');

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/front',
	timeout: 15000,
	headers: { Authorization: 'Bearer ' + auth_token },
});

export default api;
