import React from 'react';
import './HomePartner.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

import partnerOne from 'assets/images/common-image/partner/1.png';
import partnerTwo from 'assets/images/common-image/partner/2.png';
import partnerThree from 'assets/images/common-image/partner/3.png';
import partnerFour from 'assets/images/common-image/partner/4.png';
import partnerFive from 'assets/images/common-image/partner/5.png';
// import partnersix from 'assets/images/common-image/partner/6.png';

const HomePartner = () => {
	return (
		<>
			<section className="relative partnerArea">
				<div className="container-fluid ph-40">
					<div className="row">
						<div className="col-12">
							<OwlCarousel
								className="owl-theme partnerSlider"
								margin={20}
								autoWidth={true}
								loop={true}
								nav={false}
								dots={false}
								items={9}
								autoplay={true}
								smartSpeed={1200}
							>
								<div className="item">
									<div className="partnerBox">
										<Link to="/">
											<img src={partnerOne} alt="" />
										</Link>
									</div>
								</div>
								<div className="item">
									<div className="partnerBox">
										<Link to="/">
											<img src={partnerTwo} alt="" />
										</Link>
									</div>
								</div>
								<div className="item">
									<div className="partnerBox">
										<Link to="/">
											<img src={partnerThree} alt="" />
										</Link>
									</div>
								</div>
								<div className="item">
									<div className="partnerBox">
										<Link to="/">
											<img src={partnerFour} alt="" />
										</Link>
									</div>
								</div>
								<div className="item">
									<div className="partnerBox">
										<Link to="/">
											<img src={partnerFive} alt="" />
										</Link>
									</div>
								</div>
							</OwlCarousel>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HomePartner;
