import { default as api } from 'config/authApiConf.js';

export const addContentService = async (data = {}) => {
	try {
		let result = await api.post('/cms/add-about-content', data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getContentService = async (pageType = 2) => {
	try {
		let result = await api.get(`/cms/get-page-content/${pageType}`);
		return result;
	} catch (error) {
		return error;
	}
};
