import { default as api } from 'config/authApiConf.js';

export const addContentService = async (data = {}) => {
	try {
		let result = await api.post('/cms/add-logo', data);
		return result;
	} catch (error) {
		return error;
	}
};
