import { default as api } from 'config/authApiConf.js';

export const getAuthUser = async () => {
	try {
		let result = await api.get('/get-auth-user');
		return result;
	} catch (error) {
		return error;
	}
};
