import React, { useEffect, useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import logo from 'assets/images/logo.png';
import allActions from 'redux/actions';
import './Sidebar.scss';

function Sidebar() {
	const dispatch = useDispatch();
	const [windowHeight, setWindowHeight] = useState(1000);
	useEffect(() => {
		setWindowHeight(window.innerWidth);
	}, []);

	const collapse = useSelector((state) => state.collapseSidebar);
	const auth = useSelector((state) => state.auth);

	useMemo(() => {
		if (windowHeight < 991.98) {
			const track = document.querySelectorAll('.sidebar-item');
			console.log(track);
			track.forEach((el) => {
				el.onclick = (event) => {
					dispatch(allActions.sidebarAction.toggleSidebar());
				};
			});
		}
	}, [windowHeight, auth]);

	return (
		<>
			<nav id="sidebar" className={collapse ? 'sidebar js-sidebar collapsed' : 'sidebar js-sidebar'}>
				<SimpleBar style={{ maxHeight: '100vh' }}>
					<div className="sidebar-content js-simplebar">
						<Link className="sidebar-brand d-flex align-items-center" to="/">
							<div className="sidebar-logo-wrap">
								<span className="sidebar-logo">
									<img className="img-block" src={logo} alt="logo" />
								</span>
							</div>
							{/* <span className="align-middle">Aqualeaf IT Solutions</span> */}
						</Link>
						<ul className="sidebar-nav">
							{/* <li className="sidebar-header">Pages</li> */}
							<li className="sidebar-item ">
								<NavLink className="sidebar-link" to="/admin/dashboard">
									<i className="fas fa-tachometer-alt"></i>
									<span className="align-middle">Dashboard</span>
								</NavLink>
							</li>
							<li className="sidebar-header">Pages</li>

							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/home">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Home</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/how-we-work">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">How We Work</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/career">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Career</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/about-us">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">About Us</span>
								</NavLink>
							</li>

							<li className="sidebar-header">Content</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-portfolio">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Portfolio</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-service">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Service</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-testimonial">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Testimonial</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-contact">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Contact Us</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-blog">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Blog</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-industry">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Industry</span>
								</NavLink>
							</li>

							<li className="sidebar-header">Section</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-logo">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Logo</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/list-career-category">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Career Category</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/add-portfolio-banner">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Portfolio Banner</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/settings">
									<i className="fas fa-cog"></i>
									<span className="align-middle">Settings</span>
								</NavLink>
							</li>
							{/* <li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/about-us">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">About Us</span>
								</NavLink>
							</li>
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/cms/data-observatory">
									<i className="fas fa-file-alt"></i>
									<span className="align-middle">Data Observatory</span>
								</NavLink>
							</li> */}
						</ul>
					</div>
				</SimpleBar>
			</nav>
		</>
	);
}

export default Sidebar;
