import { default as api } from 'config/authApiConf.js';

export const serviceDetailService = async (id = null) => {
	try {
		let result = await api.get(`/cms/service-details/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const serviceEditService = async (id = null, data = {}) => {
	try {
		let result = await api.put(`/cms/edit-service/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
