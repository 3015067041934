import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';
import { addContentService } from './AddCareerCategory.service';

const AddCareerCategory = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'content',
	});
	const [img, setImg] = useState('');

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const handleImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setImg(img);
		}
	};

	const onSubmit = async (data) => {
		console.log(data);

		if (!img) {
			toast.error(`Please select all image`);
			return false;
		}

		let formData = {
			...data,
			img,
		};

		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
			reset();
			setImg('');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Career Category' },
				]}
				title="Career Category Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="upload-img-box">
								<label htmlFor="">Image</label>
								<div className="upload-img-wrap">
									<input type="file" id="img" onChange={handleImg} />
									<label htmlFor="img">
										{!img && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Image...</p>
											</div>
										)}
										{img && (
											<div className="preview-img">
												<img src={img} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Title:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Title" {...register('title', { required: 'Title is required' })} />
								{errors.title && <InputErrorMsg error={errors.title?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Hiring:</label>
							<div className="admim-input-wrap">
								<select className="form-select" {...register('isHiring', { required: 'Hiring is required' })}>
									<option value="">--Select--</option>
									<option value="1">Yes</option>
									<option value="0">No</option>
								</select>
								{errors.isHiring && <InputErrorMsg error={errors.isHiring?.message} />}
							</div>
						</div>
					</div>
					<hr />
					<div className="row">
						<h4>Content: </h4>
						<div className="col-12">
							{fields.length > 0 ? (
								<>
									{fields.map((item, index) => (
										<>
											<div className="card" key={index}>
												<div className="card-header">
													<div className="row justify-content-between align-items-center">
														<div className="col-auto">
															<div className="list-head">item</div>
														</div>
														{index > 0 && (
															<div className="col-auto">
																<div className="quotes-delete">
																	<button type="button" className="cmn-btn-tag" onClick={() => remove(index)}>
																		<i className="fas fa-trash-alt"></i>
																	</button>
																</div>
															</div>
														)}
													</div>
												</div>
												<div className="card-body">
													<div className="row">
														<div className="col-sm-6 col-md-6 col-lg-4 col-12">
															<label className="admin-lbl">Title:</label>
															<div className="admim-input-wrap">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Title"
																	rows={5}
																	{...register(`content[${index}].title`, { required: 'Title is required' })}
																/>
																{errors.content?.[index]?.title && <InputErrorMsg error={errors.content?.[index]?.title?.message} />}
															</div>
														</div>
														<div className="col-sm-6 col-md-6 col-lg-4 col-12">
															<label className="admin-lbl">Description:</label>
															<div className="admim-input-wrap">
																<textarea
																	type="text"
																	className="form-control"
																	placeholder="Description"
																	rows={5}
																	{...register(`content[${index}].description`, { required: 'Description is required' })}
																/>
																{errors.content?.[index]?.description && <InputErrorMsg error={errors.content?.[index]?.description?.message} />}
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									))}
								</>
							) : (
								<div className="card" key={0}>
									<div className="card-header">
										<div className="row justify-content-between align-items-center">
											<div className="col-auto">
												<div className="list-head">item</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														rows={5}
														{...register(`content[0].title`, { required: 'Title is required' })}
													/>
													{errors.content?.[0]?.title && <InputErrorMsg error={errors.content?.[0]?.title?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														type="text"
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register(`content[0].description`, { required: 'Description is required' })}
													/>
													{errors.content?.[0]?.description && <InputErrorMsg error={errors.content?.[0]?.description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="cmn-btn-tag-wrap">
								<button type="button" className="cmn-btn-tag black-bg" onClick={() => append({ title: '', description: '' })}>
									Add more item
								</button>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddCareerCategory;
