import { default as api } from 'config/authApiConf.js';
export const upload = async (data) => {
	try {
		let result = await api.post('/upload', data);
		return result;
	} catch (error) {
		return error;
	}
};
export const addContentService = async (data = {}) => {
	try {
		let result = await api.post('/cms/add-home-content', data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getContentService = async (pageType = 1) => {
	try {
		let result = await api.get(`/cms/get-page-content/${pageType}`);
		return result;
	} catch (error) {
		return error;
	}
};
