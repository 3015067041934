import React, { useEffect, useState, useMemo } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './PortFolio.scss';
import './PortFolioResponsive.scss';

import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LetsTalk from 'components/LetsTalk';

import himalyan from 'assets/images/common-image/himalyan.png';
import bgOne from 'assets/images/common-image/portfolio/bg-1.jpg';
import bgTwo from 'assets/images/common-image/portfolio/bg-2.jpg';
import bgThree from 'assets/images/common-image/portfolio/bg-3.jpg';
import bgFour from 'assets/images/common-image/portfolio/bg-4.jpg';
import bgFive from 'assets/images/common-image/portfolio/bg-5.jpg';
import bgSix from 'assets/images/common-image/portfolio/bg-6.jpg';

import react from 'assets/images/common-image/react.svg';
import angular from 'assets/images/common-image/angular.svg';
import wordpress from 'assets/images/common-image/wordpress.svg';
import laravel from 'assets/images/common-image/laravel.svg';

import sectionTwo from 'assets/images/common-image/portfolio/section-2.png';

import sectionFour from 'assets/images/common-image/portfolio/section-4.png';

import portfolioBann from 'assets/images/common-image/portfolio/portfolioBann.svg';
import isoOne from 'assets/images/common-image/portfolio/iso-1.svg';

import { getContentService } from './PortFolio.service.js';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import $ from 'jquery'
// import isoTwo from 'assets/images/common-image/portfolio/iso-2.png';

// import banOne from 'assets/images/common-image/portfolio/1.webp';
// import banTwo from 'assets/images/common-image/portfolio/2.webp';
// import banThree from 'assets/images/common-image/portfolio/3.webp';
// import banFour from 'assets/images/common-image/portfolio/4.webp';

const anchors = [];
// const loop = [1]

function PortFolio() {

	const dispatch = useDispatch();
	const [portfolio, setPortfolio] = useState([]);
	const [portfolioBanner, setPortfolioBanner] = useState({});
	const [loop, setLoop] = useState([1]);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, []);

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			setPortfolio(result?.data?.data?.portfolio);
			setPortfolioBanner(JSON.parse(result?.data?.data?.portfolioBanner?.content));
			setLoop([1])
		} else {
			toast.error(result.data.error);
		}
	};
	useMemo(() => {
		$('first').remove();
	}, [portfolioBanner])

	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<section className="portfolioHeader">
				<Header />
			</section>

			<ReactFullpage
				// debug
				anchors={anchors}
				navigation
				// navigationTooltips={anchors}
				navigat
				// sectionsColor={["#7fff00", "#00ffff", "#29ab87"]}
				onLeave={(origin, destination, direction) => {
					// console.log('onLeave event', { origin, destination, direction });
				}}
				// onRender={() => { $('first').remove(); }}
				render={({ state, fullpageApi }) => {
					// console.log('render prop change', state, fullpageApi);

					return (
						<div className="portfolioPage">
							<div className="section portfolioBanner" style={{ background: `url(${portfolioBanner?.banner?.backgroundImg ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioBanner?.banner?.backgroundImg}` : bgOne})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="portfolioInner relative">
												<div className="isoArea">
													<img src={isoOne} alt="" />
												</div>
												<h2 dangerouslySetInnerHTML={{
													__html: portfolioBanner?.banner?.title ?? `Case Studies`,
												}}></h2>
												<p dangerouslySetInnerHTML={{
													__html: portfolioBanner?.banner?.description ?? `Over the years, our main goal of uplifting businesses has remained the same. We strive to provide innovative mobile and web
													solutions that put your brand in the spotlight.`,
												}}>

												</p>
												<Link to="/contact" className="caseStudy">
													Discuss your Idea <i className="fas fa-long-arrow-alt-right"></i>
												</Link>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="proBann relative">
												<img src={portfolioBanner?.banner?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioBanner?.banner?.img}` : portfolioBann} alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* {
								loop?.map((val, i) => {
									return (
										<div className="section portfolioBanner" style={{ background: `url(${portfolioBanner?.banner?.backgroundImg ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioBanner?.banner?.backgroundImg}` : bgOne})` }}>
											<div className="container">
												<div className="row align-items-center">
													<div className="col-lg-6 col-md-6 col-sm-12 col-12">
														<div className="portfolioInner relative">
															<div className="isoArea">
																<img src={isoOne} alt="" />
															</div>
															<h2 dangerouslySetInnerHTML={{
																__html: portfolioBanner?.banner?.title ?? `Case Studies`,
															}}></h2>
															<p dangerouslySetInnerHTML={{
																__html: portfolioBanner?.banner?.description ?? `Over the years, our main goal of uplifting businesses has remained the same. We strive to provide innovative mobile and web
													solutions that put your brand in the spotlight.`,
															}}>

															</p>
															<Link to="/contact" className="caseStudy">
																Discuss your Idea <i className="fas fa-long-arrow-alt-right"></i>
															</Link>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 col-12">
														<div className="proBann relative">
															<img src={portfolioBanner?.banner?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioBanner?.banner?.img}` : portfolioBann} alt="" />
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})
							} */}

							{/* <div className="section portfolioBanner" style={{ background: `url(${portfolioBanner?.banner?.backgroundImg ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioBanner?.banner?.backgroundImg}` : bgOne})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="portfolioInner relative">
												<div className="isoArea">
													<img src={isoOne} alt="" />
												</div>
												<h2 dangerouslySetInnerHTML={{
													__html: portfolioBanner?.banner?.title ?? `Case Studies`,
												}}></h2>
												<p dangerouslySetInnerHTML={{
													__html: portfolioBanner?.banner?.description ?? `Over the years, our main goal of uplifting businesses has remained the same. We strive to provide innovative mobile and web
													solutions that put your brand in the spotlight.`,
												}}>

												</p>
												<Link to="/contact" className="caseStudy">
													Discuss your Idea <i className="fas fa-long-arrow-alt-right"></i>
												</Link>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="proBann relative">
												<img src={portfolioBanner?.banner?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioBanner?.banner?.img}` : portfolioBann} alt="" />
											</div>
										</div>
									</div>
								</div>
							</div> */}
							{
								portfolio?.length > 0 && (
									<>
										{
											portfolio?.map((val, i) => {
												const content = val?.content ? JSON.parse(val?.content) : '';
												console.log(content)
												if ((i + 1) % 2 !== 0) {
													return (
														<div className="section section-2 sectionFrame-even" key={`portfolio-${i}`} style={{ backgroundImage: `url(${content?.mainSection?.backgroundImg ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.mainSection?.backgroundImg}` : bgTwo})` }}>
															<div className="container">
																<div className="row align-items-center">
																	<div className="col-lg-6 col-md-6 col-sm-12 col-12">
																		<div className="sectionInner">
																			<div className="sectionInnerLogo">
																				<img src={content?.mainSection?.clientLogo ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.mainSection?.clientLogo}` : himalyan} alt="" />
																			</div>
																			<p dangerouslySetInnerHTML={{
																				__html: content?.mainSection?.description ?? `Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
																				Chairman of the Executive Council.`,
																			}}>

																			</p>
																			<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
																				<Link to={`/portfolio-details/${val?.slug}`} className="caseStudy">
																					View Case Study <i className="fas fa-long-arrow-alt-right"></i>
																				</Link>
																				<ul className="d-flex align-items-center developIcon">
																					{
																						content?.mainSection?.technologyImg?.map((value, i) => (
																							<li>
																								<img src={value ? `${process.env.REACT_APP_RESOURCE_URL}/${value}` : react} alt="" />
																							</li>
																						))
																					}
																				</ul>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-6 col-md-6 col-sm-12 col-12">
																		<div className="sectionImg">
																			<img src={content?.mainSection?.displayImg ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.mainSection?.displayImg}` : sectionTwo} alt="" />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)
												} else {
													return (
														<div className="section section-2 sectionFrame-even" style={{ backgroundImage: `url(${content?.mainSection?.backgroundImg ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.mainSection?.backgroundImg}` : bgTwo})` }}>
															<div className="container">
																<div className="row align-items-center">
																	<div className="col-lg-6 col-md-6 col-sm-12 col-12">
																		<div className="sectionImg">
																			<img src={content?.mainSection?.displayImg ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.mainSection?.displayImg}` : sectionTwo} alt="" />
																		</div>
																	</div>
																	<div className="col-lg-6 col-md-6 col-sm-12 col-12">
																		<div className="sectionInner">
																			<div className="sectionInnerLogo">
																				<img src={content?.mainSection?.clientLogo ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.mainSection?.clientLogo}` : himalyan} alt="" />
																			</div>
																			<p dangerouslySetInnerHTML={{
																				__html: content?.mainSection?.description ?? `Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
																				Chairman of the Executive Council.`,
																			}}>

																			</p>
																			<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
																				<Link to={`/portfolio-details/${val?.slug}`} className="caseStudy">
																					View Case Study <i className="fas fa-long-arrow-alt-right"></i>
																				</Link>
																				<ul className="d-flex align-items-center developIcon">
																					{
																						content?.mainSection?.technologyImg?.map((value, i) => (
																							<li>
																								<img src={value ? `${process.env.REACT_APP_RESOURCE_URL}/${value}` : react} alt="" />
																							</li>
																						))
																					}
																				</ul>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</div>
													)
												}
											})
										}
									</>
								)
							}
							{/* <div className="section section-2 sectionFrame-even" style={{ backgroundImage: `url(${bgTwo})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionInner">
												<div className="sectionInnerLogo">
													<img src={himalyan} alt="" />
												</div>
												<p>
													Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
													Chairman of the Executive Council.
												</p>
												<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
													<Link to="/portfolio-details" className="caseStudy">
														View Case Study <i className="fas fa-long-arrow-alt-right"></i>
													</Link>
													<ul className="d-flex align-items-center developIcon">
														<li>
															<img src={react} alt="" />
														</li>
														<li>
															<img src={angular} alt="" />
														</li>
														<li>
															<img src={wordpress} alt="" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionImg">
												<img src={sectionTwo} alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="section section-3 sectionFrame-odd" style={{ backgroundImage: `url(${bgThree})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionImg">
												<img src={sectionTwo} alt="" />
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionInner">
												<div className="sectionInnerLogo">
													<img src={himalyan} alt="" />
												</div>
												<p>
													Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
													Chairman of the Executive Council.
												</p>
												<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
													<Link to="/portfolio-details" className="caseStudy">
														View Case Study <i className="fas fa-long-arrow-alt-right"></i>
													</Link>
													<ul className="d-flex align-items-center developIcon">
														<li>
															<img src={react} alt="" />
														</li>
														<li>
															<img src={angular} alt="" />
														</li>
														<li>
															<img src={laravel} alt="" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="section section-4 sectionFrame-even" style={{ backgroundImage: `url(${bgFour})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionInner">
												<div className="sectionInnerLogo">
													<img src={himalyan} alt="" />
												</div>
												<p>
													Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
													Chairman of the Executive Council.
												</p>
												<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
													<Link to="/portfolio-details" className="caseStudy">
														View Case Study <i className="fas fa-long-arrow-alt-right"></i>
													</Link>
													<ul className="d-flex align-items-center developIcon">
														<li>
															<img src={react} alt="" />
														</li>
														<li>
															<img src={angular} alt="" />
														</li>
														<li>
															<img src={wordpress} alt="" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionImg">
												<img src={sectionFour} alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="section section-5 sectionFrame-odd" style={{ backgroundImage: `url(${bgFive})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionImg">
												<img src={sectionTwo} alt="" />
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionInner">
												<div className="sectionInnerLogo">
													<img src={himalyan} alt="" />
												</div>
												<p>
													Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
													Chairman of the Executive Council.
												</p>
												<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
													<Link to="/portfolio-details" className="caseStudy">
														View Case Study <i className="fas fa-long-arrow-alt-right"></i>
													</Link>
													<ul className="d-flex align-items-center developIcon">
														<li>
															<img src={react} alt="" />
														</li>
														<li>
															<img src={laravel} alt="" />
														</li>
														<li>
															<img src={wordpress} alt="" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="section section-6 sectionFrame-even" style={{ backgroundImage: `url(${bgSix})` }}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionInner">
												<div className="sectionInnerLogo">
													<img src={himalyan} alt="" />
												</div>
												<p>
													Day for Dubai is an initiative launched by Sheikh Hamdan bin Muhammed bin Rashid Al Maktoum, the Crown Prince of Dubai and
													Chairman of the Executive Council.
												</p>
												<div className="d-flex flex-wrap align-items-center justify-content-between mt-4 csbList">
													<Link to="/portfolio-details" className="caseStudy">
														View Case Study <i className="fas fa-long-arrow-alt-right"></i>
													</Link>
													<ul className="d-flex align-items-center developIcon">
														<li>
															<img src={angular} alt="" />
														</li>
														<li>
															<img src={laravel} alt="" />
														</li>
														<li>
															<img src={wordpress} alt="" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="sectionImg">
												<img src={sectionTwo} alt="" />
											</div>
										</div>
									</div>
								</div>
							</div> */}
							{/* {
								loop?.map((val, i) => {
									return (
										<section className="section sectionFooter fp-auto-height">
											<Footer />
										</section>
									)
								})
							} */}
							<section className="section sectionFooter fp-auto-height">
								<Footer />
							</section>
						</div>
					);
				}}
			/>
			<LetsTalk />
		</>
	);
}

export default PortFolio;
