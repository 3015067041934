import React from 'react';
import './PortfolioBanner.scss';
import proDtlBann from 'assets/images/common-image/portfolio/prodetails-1.jpg';
import { useSelector, useDispatch } from 'react-redux';

const PortfolioBanner = () => {
	// alert();
	const portfolioDtlContent = useSelector((state) => state.content?.portfolioDetail);
	const portfolioDtlData = portfolioDtlContent?.portfolioDetailData?.content ? JSON.parse(portfolioDtlContent?.portfolioDetailData?.content) : '';
	console.log(portfolioDtlData)
	return (
		<>
			<section className="proDtlBann">
				<img src={portfolioDtlData?.mainSection?.bannerImg ? `${process.env.REACT_APP_RESOURCE_URL}/${portfolioDtlData?.mainSection?.bannerImg}` : proDtlBann} alt="" />
			</section>
		</>
	);
};

export default PortfolioBanner;
