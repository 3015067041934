import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { blogDetailService, blogEditService } from './EditBlog.service.js';

const EditBlog = () => {
	const { id } = useParams();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	const [img, setImg] = useState('');
	const [blogDtl, setBlogDtl] = useState({});

	useEffect(() => {
		getBlogDetails();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [blogDtl]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};

		defaultValues.title = blogDtl?.title;
		defaultValues.description = blogDtl?.description;

		await reset({ ...defaultValues });

		if (blogDtl?.img) {
			setImg(`${process.env.REACT_APP_RESOURCE_URL}/${blogDtl?.img}`);
		}
	};

	const getBlogDetails = async () => {
		const result = await blogDetailService(id);
		if (result.data.status === 200) {
			setBlogDtl(result?.data?.data?.blog);
		} else {
			toast.error(result.data.error);
		}
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const handleImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setImg(img);
		}
	};

	const onSubmit = async (data) => {
		console.log(data);

		if (!img) {
			toast.error(`Please select all image`);
			return false;
		}

		let formData = {
			...data,
			img,
		};

		const result = await blogEditService(id, formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Blog' },
				]}
				title="Blog Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="upload-img-box">
								<label htmlFor="">Image</label>
								<div className="upload-img-wrap">
									<input type="file" id="img" onChange={handleImg} />
									<label htmlFor="img">
										{!img && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload image...</p>
											</div>
										)}
										{img && (
											<div className="preview-img">
												<img src={img} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Title:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Title" {...register('title', { required: 'Title is required' })} />
								{errors.title && <InputErrorMsg error={errors.title?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Description:</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control"
									placeholder="Description"
									rows={5}
									{...register('description', {
										required: 'Description is required',
									})}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default EditBlog;
