import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { addContentService, listLogoService } from './PortFolioContent.service.js';
import './PortFolioContent.scss';

function PortFolioContent() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const [content, setContent] = useState({});
	const [reRender, setReRender] = useState(0);
	const [bannerImg, setBannerImg] = useState('');

	const [clientLogo, setClientLogo] = useState('');
	// const [technologyImg, setTechnologyImg] = useState([]);
	const [problemGallery, setProblemGallery] = useState([]);
	const [weDidGallery, setWeDidGallery] = useState([]);
	const [clientAvatar, setClientAvatar] = useState('');
	const [logo, setLogo] = useState('');

	const [homeImg1, setHomeImg1] = useState('');
	const [homeImg2, setHomeImg2] = useState('');
	const [backgroundImg, setBackgroundImg] = useState('');
	const [displayImg, setDisplayImg] = useState('');
	const [logoList, setLogoList] = useState([]);

	useEffect(() => {
		// horizontal scroll
		/* const scrollContainer = document.querySelectorAll(".file-scroll");
		scrollContainer.forEach((e) => {
			e.addEventListener("wheel", (evt) => {
				evt.preventDefault();
				e.scrollLeft += evt.deltaY;
			});
		}); */
		getLogoList();
	}, []);
	const getLogoList = async () => {
		const result = await listLogoService();
		console.log('result', result);
		if (result.data.status === 200) {
			const logoData = result?.data?.data?.map((val) => {
				return { value: val.id, label: val.name };
			});
			console.log('logoData', logoData);
			setLogoList(logoData);
		} else {
			toast.error(result.data.error);
		}
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const handleDisplayImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setDisplayImg(img);
		}
	};
	const handleBackgroundImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBackgroundImg(img);
		}
	};

	const handleClientLogo = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setClientLogo(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				// console.log("Called", reader);
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const unique = (value, index, self) => {
		return self.indexOf(value) === index;
	};
	// const handleFile = async (e) => {
	// 	let files = Array.from(e.target.files);
	// 	Promise.all(
	// 		files.map((file) => {
	// 			return getBase64(file);
	// 		})
	// 	).then(
	// 		(images) => {
	// 			let datas = [...images, ...technologyImg];
	// 			let uniquedata = datas.filter(unique);
	// 			setTechnologyImg(uniquedata);
	// 		},
	// 		(error) => {
	// 			console.error(error);
	// 		}
	// 	);
	// };
	// function deleteFile(e) {
	// 	const img = technologyImg.filter((item, index) => index !== e);
	// 	setTechnologyImg(img);
	// }

	const handleProblemGallery = async (e) => {
		let files = Array.from(e.target.files);
		Promise.all(
			files.map((file) => {
				return getBase64(file);
			})
		).then(
			(images) => {
				let datas = [...images, ...problemGallery];
				let uniquedata = datas.filter(unique);
				setProblemGallery(uniquedata);
			},
			(error) => {
				console.error(error);
			}
		);
	};
	function deleteProblemGallery(e) {
		const img = problemGallery.filter((item, index) => index !== e);
		setProblemGallery(img);
	}

	const handleWeDidGallery = async (e) => {
		let files = Array.from(e.target.files);
		Promise.all(
			files.map((file) => {
				return getBase64(file);
			})
		).then(
			(images) => {
				let datas = [...images, ...weDidGallery];
				let uniquedata = datas.filter(unique);
				setWeDidGallery(uniquedata);
			},
			(error) => {
				console.error(error);
			}
		);
	};
	function deleteWeDidGallery(e) {
		const img = weDidGallery.filter((item, index) => index !== e);
		setWeDidGallery(img);
	}

	const handleClientAvatar = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setClientAvatar(img);
		}
	};

	const handleLogo = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setLogo(img);
		}
	};
	const handleHomeImg1 = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setHomeImg1(img);
		}
	};
	const handleHomeImg2 = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setHomeImg2(img);
		}
	};

	const onSubmit = async (data) => {
		console.log(data);

		if (
			!bannerImg ||
			!backgroundImg ||
			!displayImg ||
			!clientLogo ||
			!clientAvatar ||
			!logo ||
			!homeImg1 ||
			!homeImg2 ||
			problemGallery?.length <= 0 ||
			weDidGallery?.length <= 0
		) {
			toast.error(`Please select all image`);
			return false;
		}

		let formData = {
			...data,
			bannerImg,
			backgroundImg,
			displayImg,
			clientLogo,
			// technologyImg,
			clientAvatar,
			logo,
			problemGallery,
			weDidGallery,
			homeImg1,
			homeImg2,
		};
		console.log('formData', formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
			reset();
			setBannerImg('');
			setClientLogo('');
			// setTechnologyImg([]);
			setProblemGallery([]);
			setWeDidGallery([]);
			setClientAvatar('');
			setLogo('');
			setHomeImg1('');
			setHomeImg2('');
			setBackgroundImg('');
			setDisplayImg('');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'PortFolio' },
				]}
				title="PortFolio Content"
			/>
			<div className="admin-white-box">
				<div className="row">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Main Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-3">
												<div className="upload-img-box">
													<label htmlFor="">Porfolio Details Banner Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="content_img" onChange={handleBannerImg} />
														<label htmlFor="content_img">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-3">
												<div className="upload-img-box">
													<label htmlFor="">Client Logo</label>
													<div className="upload-img-wrap">
														<input type="file" id="clientLogo" onChange={handleClientLogo} />
														<label htmlFor="clientLogo">
															{!clientLogo && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{clientLogo && (
																<div className="preview-img">
																	<img src={clientLogo} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-3">
												<div className="upload-img-box">
													<label htmlFor="">Background Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="backgroundImg" onChange={handleBackgroundImg} />
														<label htmlFor="backgroundImg">
															{!backgroundImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{backgroundImg && (
																<div className="preview-img">
																	<img src={backgroundImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-3">
												<div className="upload-img-box">
													<label htmlFor="">Portfolio Page Display Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="displayImg" onChange={handleDisplayImg} />
														<label htmlFor="displayImg">
															{!displayImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{displayImg && (
																<div className="preview-img">
																	<img src={displayImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														rows={5}
														{...register('title', { required: 'Title is required' })}
													/>
													{errors.title && <InputErrorMsg error={errors.title?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-6 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.description && <InputErrorMsg error={errors.description?.message} />}
												</div>
											</div>

											{/* <div className="col-12">
												<div className="overfolow-hid">
													<label className="admin-lbl">Technologies</label>
													<div className="row g-2">
														<div className="col-auto image-upload-wrap">
															<div className="admim-input-wrap">
																<input type="file" id="image_upload" className="form-control" multiple onChange={handleFile} />
																<label htmlFor="image_upload" className="image-upload-lbl">
																	<i className="fas fa-upload"></i>Upload Images
																	{technologyImg.length > 0 && <span className="doc-count">{technologyImg.length} images selected</span>}
																</label>
															</div>
														</div>
														<div className="col d-flex file-scroll">
															{technologyImg.map((res, i) => {
																return (
																	<>
																		<div className="multiple-img-wrap">
																			<img className="img-block" src={res} alt="" />
																			<button type="button" className="image-del" onClick={() => deleteFile(i)}>
																				-
																			</button>
																		</div>
																	</>
																);
															})}
														</div>
														
													</div>
												</div>
											</div> */}
											<div className="col-4">
												<div className="admim-select-wrap">
													<Controller
														name="technology"
														control={control}
														render={({ field }) => <Select isMulti {...field} options={logoList} />}
														rules={{ required: 'Technology is required' }}
													/>
													{errors.technology && <InputErrorMsg error={errors.technology?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="headingTwo">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										About Client Section
									</button>
								</h2>
								<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('clientHeading', { required: 'Heading is required' })}
													/>
													{errors.clientHeading && <InputErrorMsg error={errors.clientHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-6 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('clientDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.clientDescription && <InputErrorMsg error={errors.clientDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="headingThree">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Problem Section
									</button>
								</h2>
								<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('problemHeading', { required: 'Heading is required' })}
													/>
													{errors.problemHeading && <InputErrorMsg error={errors.problemHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-6 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('problemDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.problemDescription && <InputErrorMsg error={errors.problemDescription?.message} />}
												</div>
											</div>

											<div className="col-12">
												<div className="overfolow-hid">
													<label className="admin-lbl">Gallery</label>
													<div className="row g-2">
														<div className="col-auto image-upload-wrap">
															<div className="admim-input-wrap">
																<input type="file" id="problemGallery" className="form-control" multiple onChange={handleProblemGallery} />
																<label htmlFor="problemGallery" className="image-upload-lbl">
																	<i className="fas fa-upload"></i>Upload Images
																	{problemGallery.length > 0 && <span className="doc-count">{problemGallery.length} images selected</span>}
																</label>
															</div>
														</div>
														<div className="col d-flex file-scroll">
															{problemGallery.map((res, i) => {
																return (
																	<>
																		<div className="multiple-img-wrap">
																			<img className="img-block" src={res} alt="" />
																			<button type="button" className="image-del" onClick={() => deleteProblemGallery(i)}>
																				-
																			</button>
																		</div>
																	</>
																);
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="heading4">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse4"
										aria-expanded="false"
										aria-controls="collapse4"
									>
										What We Did Section
									</button>
								</h2>
								<div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('weDidHeading', { required: 'Heading is required' })}
													/>
													{errors.weDidHeading && <InputErrorMsg error={errors.weDidHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('weDidDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.weDidDescription && <InputErrorMsg error={errors.weDidDescription?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">What We Did Item (item can be multiple and comma seperated):</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="What We Did Item"
														rows={5}
														{...register('weDidItem', {
															required: 'What We Did Item is required',
														})}
													></textarea>
													{errors.weDidItem && <InputErrorMsg error={errors.weDidItem?.message} />}
												</div>
											</div>

											<div className="col-12">
												<div className="overfolow-hid">
													<label className="admin-lbl">Gallery</label>
													<div className="row g-2">
														<div className="col-auto image-upload-wrap">
															<div className="admim-input-wrap">
																<input type="file" id="weDidGallery" className="form-control" multiple onChange={handleWeDidGallery} />
																<label htmlFor="weDidGallery" className="image-upload-lbl">
																	<i className="fas fa-upload"></i>Upload Images
																	{weDidGallery.length > 0 && <span className="doc-count">{weDidGallery.length} images selected</span>}
																</label>
															</div>
														</div>
														<div className="col d-flex file-scroll">
															{weDidGallery.map((res, i) => {
																return (
																	<>
																		<div className="multiple-img-wrap">
																			<img className="img-block" src={res} alt="" />
																			<button type="button" className="image-del" onClick={() => deleteWeDidGallery(i)}>
																				-
																			</button>
																		</div>
																	</>
																);
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="heading5">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse5"
										aria-expanded="false"
										aria-controls="collapse5"
									>
										What Client Say Section
									</button>
								</h2>
								<div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">client Avatar</label>
													<div className="upload-img-wrap">
														<input type="file" id="clientAvatar" onChange={handleClientAvatar} />
														<label htmlFor="clientAvatar">
															{!clientAvatar && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{clientAvatar && (
																<div className="preview-img">
																	<img src={clientAvatar} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Logo</label>
													<div className="upload-img-wrap">
														<input type="file" id="logo" onChange={handleLogo} />
														<label htmlFor="logo">
															{!logo && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{logo && (
																<div className="preview-img">
																	<img src={logo} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>

											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('clientSayHeading', { required: 'Heading is required' })}
													/>
													{errors.clientSayHeading && <InputErrorMsg error={errors.clientSayHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('clientSayDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.clientSayDescription && <InputErrorMsg error={errors.clientSayDescription?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Client Name:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Client Name"
														{...register('clientName', {
															required: 'Client Name is required',
														})}
													/>
													{errors.clientName && <InputErrorMsg error={errors.clientName?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Client Designation:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Client Designation"
														{...register('clientDesignation', {
															required: 'Client Designation is required',
														})}
													/>
													{errors.clientDesignation && <InputErrorMsg error={errors.clientDesignation?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="heading6">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse6"
										aria-expanded="false"
										aria-controls="collapse6"
									>
										In Home Page
									</button>
								</h2>
								<div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Image 1</label>
													<div className="upload-img-wrap">
														<input type="file" id="image1" onChange={handleHomeImg1} />
														<label htmlFor="image1">
															{!homeImg1 && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{homeImg1 && (
																<div className="preview-img">
																	<img src={homeImg1} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Image 2</label>
													<div className="upload-img-wrap">
														<input type="file" id="image2" onChange={handleHomeImg2} />
														<label htmlFor="image2">
															{!homeImg2 && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{homeImg2 && (
																<div className="preview-img">
																	<img src={homeImg2} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>

											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Background Text:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Background Text"
														{...register('homeBackgroundText', { required: 'Background Text is required' })}
													/>
													{errors.homeBackgroundText && <InputErrorMsg error={errors.homeBackgroundText?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Color:</label>
												<div className="admim-input-wrap">
													<input
														type="color"
														className="form-control admim-input-style"
														placeholder="Color"
														{...register(`homeColor`, {
															required: 'Color is required',
														})}
													/>
													{errors.homeColor && <InputErrorMsg error={errors.homeColor?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="cmn-btn-tag-wrap">
									<button type="submit" className="cmn-btn-tag">
										Save
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default PortFolioContent;
