import React from 'react';
import './StoStep.scss';
import './StoStepResponsive.scss';
import bgOne from 'assets/images/common-image/services/stepbg.jpg';
import { useSelector } from 'react-redux';

const StoStep = () => {
	const serviceContent = useSelector((state) => state.content?.service);
	const serviceData = serviceContent?.content ? JSON.parse(serviceContent?.content) : '';

	return (
		<>
			<section className="stepSection">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="stepTitle text-center">
								<h2
									dangerouslySetInnerHTML={{
										__html: serviceData?.section5?.heading ?? `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
									}}
								></h2>
								<p
									dangerouslySetInnerHTML={{
										__html:
											serviceData?.section5?.description ??
											`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
										suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.`,
									}}
								></p>
							</div>
						</div>
						{serviceData?.section5?.item?.map((val, i) => (
							<div className="col-lg-4 col-md-4 col-sm-6 col-12" key={`stepInner-${i}`}>
								<div className="stepInner text-center">
									<span>{i + 1}</span>
									<h4>{val?.title}</h4>
									<p>{val?.description}</p>
								</div>
							</div>
						))}
						{/* <div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<div className="stepInner text-center">
								<span>1</span>
								<h4>Research & Discovery</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
							</div>
						</div> */}
						{/* <div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<div className="stepInner text-center">
								<span>2</span>
								<h4>Research & Discovery</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<div className="stepInner text-center">
								<span>3</span>
								<h4>Research & Discovery</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<div className="stepInner text-center">
								<span>4</span>
								<h4>Research & Discovery</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<div className="stepInner text-center">
								<span>5</span>
								<h4>Research & Discovery</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<div className="stepInner text-center">
								<span>6</span>
								<h4>Research & Discovery</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
							</div>
						</div> */}
					</div>
				</div>
			</section>
		</>
	);
};

export default StoStep;
