import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';
import notfoungBg from 'assets/images/404bg.jpg';
import notZero from 'assets/images/0.png';
function NotFound() {
	return (
		<>
			<section className="not-found-wrap d-flex align-items-center justify-content-center" style={{ background: `url(${notfoungBg}) center center no-repeat` }}>
				<div className="eror-404-text">
					<h3>
						4
						<span className="zero">
							<img src={notZero} alt="" />
						</span>
						4
					</h3>
					<p>Nothing to see here. try another link</p>
					<Link to="/" className="error-bkhome">
						back to home
					</Link>
				</div>
			</section>
		</>
	);
}

export default NotFound;
