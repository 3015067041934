import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';

import { addContentService, listLogoService } from './AddTestimonial.service.js';

const AddTestimonial = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	const [logoList, setLogoList] = useState([]);
	const [avatar, setAvatar] = useState('');

	// const handleLogo = async (e, index) => {
	// 	let file = e.target.files[0];
	// 	if (file.size > 1000000) {
	// 		toast.error('Image size is too large. Image size must be less than 1 MB.');
	// 		return false;
	// 	}
	// 	let img = await getBase64(file);
	// 	if (img) {
	// 		setLogo(img);
	// 	}
	// };
	useEffect(() => {
		getLogoList();
	}, []);
	const handleAvatar = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setAvatar(img);
		}
	};
	const onSubmit = async (data) => {
		console.log(data);
		if (!avatar) {
			toast.error(`Please select all image`);
			return false;
		}

		let formData = {
			...data,
			avatar,
		};

		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
			reset();
			// setLogo('');
			setAvatar('');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	const getLogoList = async () => {
		const result = await listLogoService();
		console.log('result', result);
		if (result.data.status === 200) {
			setLogoList(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Testimonial' },
				]}
				title="Testimonial Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						{/* <div className="col-6">
							<div className="upload-img-box">
								<label htmlFor="">Logo</label>
								<div className="upload-img-wrap">
									<input type="file" id="logo" onChange={handleLogo} />
									<label htmlFor="logo">
										{!logo && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Logo...</p>
											</div>
										)}
										{logo && (
											<div className="preview-img">
												<img src={logo} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div> */}
						<div className="col-6">
							<div className="upload-img-box">
								<label htmlFor="">Avatar</label>
								<div className="upload-img-wrap">
									<input type="file" id="avatar" onChange={handleAvatar} />
									<label htmlFor="avatar">
										{!avatar && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Avatar...</p>
											</div>
										)}
										{avatar && (
											<div className="preview-img">
												<img src={avatar} className="img-block" alt="avatar" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Logo:</label>
							<div className="admim-input-wrap">
								{/* <input type="text" className="form-control" placeholder="Client Name" {...register('clientName', { required: 'Client Name is required' })} /> */}
								<select {...register('logo', { required: 'logo is required' })} className="form-select">
									<option value="">-- select --</option>
									{logoList?.map((val) => (
										<option value={val.id}>{val.name}</option>
									))}
								</select>
								{errors.logo && <InputErrorMsg error={errors.logo?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Client Name:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Client Name" {...register('clientName', { required: 'Client Name is required' })} />
								{errors.clientName && <InputErrorMsg error={errors.clientName?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Client Designation:</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control"
									placeholder="Client Designation"
									{...register('clientDesignation', { required: 'Client Designation is required' })}
								/>
								{errors.clientDesignation && <InputErrorMsg error={errors.clientDesignation?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Project Link:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Project Link" {...register('projectLink', { required: 'Project Link is required' })} />
								{errors.projectLink && <InputErrorMsg error={errors.projectLink?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Show In Home Page:</label>
							<div className="admim-input-wrap">
								<input type="checkbox" {...register('showInHome')} />
								{errors.showInHome && <InputErrorMsg error={errors.showInHome?.message} />}
							</div>
						</div>

						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Description:</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control"
									placeholder="Description"
									rows={5}
									{...register('description', {
										required: 'Description is required',
									})}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddTestimonial;
