import React from 'react';
import './CareerInner.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
// import CareerInnerBanner from 'components/CareerInnerBanner';
import CareerOpenPosition from 'components/CareerOpenPosition';

const CareerInner = () => {
	return (
		<>
			<Header />
			{/* <CareerInnerBanner /> */}
			<CareerOpenPosition />
			<Footer />
		</>
	);
};

export default CareerInner;
