import React, { useEffect } from 'react';
import './ServicesTemplate.scss';
import './ServicesTemplateResponsive.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import ServicesTempOne from 'components/ServicesTempOne/ServicesTempOne';
import ServicesTempTwo from 'components/ServicesTempTwo/ServicesTempTwo';
import ServicesTempOneBan from 'components/ServicesTempOneBan';
import ServicesDesignAgency from 'components/ServicesDesignAgency';
import StobWorkWithUs from 'components/StobWorkWithUs/WorkWithUs';
import BuildReact from 'components/BuildReact';
import StoStep from 'components/StoStep';
import ServicesFaq from 'components/ServicesFaq';
import StobGetBusiness from 'components/StobGetBusiness';
import HomeSuccessProduct from 'components/HomeSuccessProduct';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService } from './ServicesTemplate.service.js';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const ServicesTemplate = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();

	const serviceContent = useSelector((state) => state.content?.service);
	const serviceData = serviceContent?.content ? JSON.parse(serviceContent?.content) : '';
	console.log('serviceData', serviceData);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, [slug]);

	const getContent = async () => {
		const result = await getContentService(slug);
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setSeviceContent(result?.data?.data?.service));
		} else {
			toast.error(result.data.error);
		}
	};
	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			{Number(serviceData?.banner?.status) === 1 && <ServicesTempOneBan />}
			{Number(serviceData?.section1?.status) === 1 && <StobGetBusiness />}
			{Number(serviceData?.section2?.status) === 1 && <ServicesDesignAgency />}
			{Number(serviceData?.section3?.status) === 1 && <StobWorkWithUs />}
			{Number(serviceData?.section4?.status) === 1 && <BuildReact />}
			{Number(serviceData?.section5?.status) === 1 && <StoStep />}
			{Number(serviceData?.section6?.status) === 1 && <ServicesFaq />}

			{/* <StobGetBusiness /> */}
			{/* <ServicesDesignAgency /> */}
			{/* <StobWorkWithUs />
			<BuildReact />
			<StoStep />
			<ServicesFaq /> */}
			<HomeSuccessProduct />
			<Footer />
			{/* <ServicesTempOne /> */}
			{/* <ServicesTempTwo /> */}
		</>
	);
};

export default ServicesTemplate;
