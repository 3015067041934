import { default as api } from 'config/frontApiConf';

export const getContentService = async (page = 1) => {
	try {
		let result = await api.get(`/get-blog-content?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};
