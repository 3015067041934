import React, { useEffect, useState } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { addContentService, getContentService } from './AboutUsContent.service.js';
import './AboutUsContent.scss';

function AboutUsContent() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'ourValueItem',
	});

	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [bannerImg, setBannerImg] = useState('');
	const [storyImg, setStoryImg] = useState('');
	const [ourValueImg, setOurValueImg] = useState('');

	const [weTalkImg, setWeTalkImg] = useState('');

	const [ourValueItemImg, setOurValueItemImg] = useState([]);
	const [content, setContent] = useState({});
	const [reRender, setReRender] = useState(0);

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};

		defaultValues.bannerTitle = content?.content?.banner?.title;
		defaultValues.bannerDescription = content?.content?.banner?.description;
		defaultValues.bannerLink = content?.content?.banner?.link;

		defaultValues.storyTitle = content?.content?.story?.title;
		defaultValues.storyHeading = content?.content?.story?.heading;
		defaultValues.storyDescription = content?.content?.story?.description;

		defaultValues.coreValueTitle = content?.content?.ourValue?.title;

		const ourValArr = [];
		const ourValImgArr = [];

		content?.content?.ourValueItem.map((val, i) => {
			ourValArr.push({
				title: val?.title,
				description: val?.description,
			});
			ourValImgArr.push(`${content?.fileBaseUrl}/uploads/${val?.img}`);
		});

		if (ourValArr.length > 0) {
			defaultValues.ourValueItem = ourValArr;
		} else {
			defaultValues.ourValueItem = [1, 2, 3];
		}

		defaultValues.weSayCeoName = content?.content?.weTalk?.ceoName;
		defaultValues.weSayDescription = content?.content?.weTalk?.description;

		await reset({ ...defaultValues });

		if (content?.content?.banner?.img) {
			setBannerImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.img}`);
		}
		if (content?.content?.story?.img) {
			setStoryImg(`${content?.fileBaseUrl}/uploads/${content?.content?.story?.img}`);
		}
		if (content?.content?.ourValue?.img) {
			setOurValueImg(`${content?.fileBaseUrl}/uploads/${content?.content?.ourValue?.img}`);
		}
		if (content?.content?.weTalk?.img) {
			setWeTalkImg(`${content?.fileBaseUrl}/uploads/${content?.content?.weTalk?.img}`);
		}
		if (ourValImgArr.length > 0) {
			setOurValueItemImg(ourValImgArr);
		}
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};

	const handleStoryImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setStoryImg(img);
		}
	};

	const handleWeTalkImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setWeTalkImg(img);
		}
	};

	const handleOurValueImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setOurValueImg(img);
		}
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				// console.log("Called", reader);
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const onSubmit = async (data) => {
		const img = ourValueItemImg.filter(function (element) {
			return element !== undefined;
		});
		if (!bannerImg || !storyImg || !ourValueImg || !weTalkImg || data?.ourValueItem?.length !== img?.length) {
			toast.error(`Please select all image`);
			return false;
		}
		const formData = {
			...data,
			bannerImg,
			storyImg,
			weTalkImg,
			ourValueItemImg,
			ourValueImg,
		};
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	const getContent = async () => {
		// dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			console.log(result?.data?.data);
			setContent(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
		// dispatch(allActions.loadingAction.hideLoading());
	};

	const handleOurValueItemImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			ourValueItemImg[index] = img;
			setOurValueItemImg([...ourValueItemImg]);
			setReRender(!reRender);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'About Us' },
				]}
				title="About Us Content"
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Banner Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="content_img" onChange={handleBannerImg} />
														<label htmlFor="content_img">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('bannerTitle', { required: 'Title is required' })}
													/>
													{errors.bannerTitle && <InputErrorMsg error={errors.bannerTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Link:</label>
												<div className="admim-input-wrap">
													<input type="text" className="form-control" placeholder="Link" {...register('bannerLink', { required: 'Link is required' })} />
													{errors.bannerLink && <InputErrorMsg error={errors.bannerLink?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('bannerDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading2">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse2"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse2"
									>
										Story Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading2">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="story_img" onChange={handleStoryImg} />
														<label htmlFor="story_img">
															{!storyImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload Icon...</p>
																</div>
															)}
															{storyImg && (
																<div className="preview-img">
																	<img src={storyImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('storyTitle', { required: 'Title is required' })}
													/>
													{errors.storyTitle && <InputErrorMsg error={errors.storyTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('storyHeading', {
															required: 'Heading is required',
														})}
													/>
													{errors.storyHeading && <InputErrorMsg error={errors.storyHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('storyDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.storyDescription && <InputErrorMsg error={errors.storyDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading3">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse3"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse3"
									>
										Our Value Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse3" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading3">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="ourValueImg" onChange={handleOurValueImg} />
														<label htmlFor="ourValueImg">
															{!ourValueImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload Image...</p>
																</div>
															)}
															{ourValueImg && (
																<div className="preview-img">
																	<img src={ourValueImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('coreValueTitle', {
															required: 'Title is required',
														})}
													/>
													{errors.coreValueTitle && <InputErrorMsg error={errors.coreValueTitle?.message} />}
												</div>
											</div>

											<div className="separetaor">
												<h4>Our Value Items:</h4>
											</div>
											<div className="col-12">
												{fields.map((item, index) => (
													<>
														<div className="card" key={index}>
															<div className="card-header">
																<div className="row justify-content-between align-items-center">
																	<div className="col-auto">
																		<div className="list-head">Item</div>
																	</div>
																</div>
															</div>
															<div className="card-body">
																<div className="row justify-content-center">
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Icon</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input
																					type="file"
																					id={`ourValueItemImg${index}`}
																					onChange={(e) => handleOurValueItemImg(e, index)}
																				/>
																				<label htmlFor={`ourValueItemImg${index}`}>
																					{!ourValueItemImg?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Icon...</p>
																						</div>
																					)}
																					{ourValueItemImg?.[index] && (
																						<div className="preview-img">
																							<img src={ourValueItemImg?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Title:</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control"
																				placeholder="Title"
																				rows={5}
																				{...register(`ourValueItem[${index}].title`, { required: 'Title is required' })}
																			/>
																			{errors.ourValueItem?.[index]?.title && (
																				<InputErrorMsg error={errors.ourValueItem?.[index]?.title?.message} />
																			)}
																		</div>
																	</div>
																	<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																		<label className="admin-lbl">Description:</label>
																		<div className="admim-input-wrap">
																			<textarea
																				className="form-control"
																				placeholder="Description"
																				rows={5}
																				{...register(`ourValueItem[${index}].description`, {
																					required: 'Description is required',
																				})}
																			></textarea>
																			{errors?.ourValueItem?.[index]?.description && (
																				<InputErrorMsg error={errors?.ourValueItem?.[index]?.description?.message} />
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading4">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse4"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse4"
									>
										What We Say Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse4" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="weTalkImg" onChange={handleWeTalkImg} />
														<label htmlFor="weTalkImg">
															{!weTalkImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload Image...</p>
																</div>
															)}
															{weTalkImg && (
																<div className="preview-img">
																	<img src={weTalkImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">CEO Name:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="CEO Name"
														{...register('weSayCeoName', {
															required: 'CEO Name is required',
														})}
													/>
													{errors.weSayCeoName && <InputErrorMsg error={errors.weSayCeoName?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														rows="5"
														className="form-control"
														placeholder="Description"
														{...register('weSayDescription', {
															required: 'Description is required',
														})}
													/>
													{errors.weSayDescription && <InputErrorMsg error={errors.weSayDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AboutUsContent;
