import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import ForgetPassword from 'pages/ForgetPassword';
import NotFound from 'components/NotFound';
import Dashboard from 'pages/Admin/Dashboard';
import AdminLayout from 'pages/Admin/AdminLayout';
import HowWeWork from 'pages/HowWeWork';
import About from 'pages/About';
import PortFolio from 'pages/PortFolio';
import PortfolioDetails from 'pages/PortfolioDetails';
import Contact from 'pages/Contact';
import Carrer from 'pages/Carrer';
import HomeContent from 'pages/Admin/HomeContent';
import HowWeWorkContent from 'pages/Admin/HowWeWorkContent';
import CareerContent from 'pages/Admin/CareerContent';
import CareerInner from 'pages/CareerInner';
import AboutUsContent from 'pages/Admin/AboutUsContent';
import Services from 'pages/Services';
import ServicesTemplate from 'pages/ServicesTemplate';
import Blog from 'pages/Blog';
import BlogDetails from 'pages/BlogDetails';
import Testimonial from 'pages/Testimonial';
import Industry from 'pages/Industry';
import PortFolioContent from 'pages/Admin/PortFolioContent';
import ListPortFolio from 'pages/Admin/ListPortFolio';
import EditPortFolio from 'pages/Admin/EditPortFolio';
import AddService from 'pages/Admin/AddService';
import ListService from 'pages/Admin/ListService/ListService';
import EditService from 'pages/Admin/EditService';

import AddTestimonial from 'pages/Admin/AddTestimonial';
import ListTestimonial from 'pages/Admin/ListTestimonial';
import EditTestimonial from 'pages/Admin/EditTestimonial';
import ListContactUs from 'pages/Admin/ListContactUs';
import AddBlog from 'pages/Admin/AddBlog';
import ListBlog from 'pages/Admin/ListBlog';
import EditBlog from 'pages/Admin/EditBlog';
import PortFolioBanner from 'pages/Admin/PortFolioBanner';
import Settings from 'pages/Admin/Settings/Settings';
import AddIndustry from 'pages/Admin/AddIndustry';
import ListIndustry from 'pages/Admin/ListIndustry';
import EditIndustry from 'pages/Admin/EditIndustry';
import AddLogo from 'pages/Admin/AddLogo';
import ListLogo from 'pages/Admin/ListLogo';

import { useLocation } from 'react-router';
import EditLogo from 'pages/Admin/EditLogo';
import AddCareerCategory from 'pages/Admin/AddCareerCategory';
import ListCareerCategory from 'pages/Admin/ListCareerCategory';
import EditCareerCategory from 'pages/Admin/EditCareerCategory';
import AddCareerInner from 'pages/Admin/AddCareerInner';

const AppRouter = () => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<Routes>
			<Route path="*" element={<NotFound />} />
			<Route path="/login" element={<Login />} />
			<Route path="/" element={<Home />} />
			<Route path="/home" element={<Home />} />
			<Route path="/how-we-work" element={<HowWeWork />} />
			<Route path="/about" element={<About />} />
			<Route path="/portfolio" element={<PortFolio />} />
			<Route path="/portfolio-details/:slug" element={<PortfolioDetails />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/career" element={<Carrer />} />
			<Route path="/career-inner" element={<CareerInner />} />
			{/* <Route path="/services" element={<Services />} /> */}
			<Route path="/service/:slug" element={<ServicesTemplate />} />
			<Route path="/industry/:slug" element={<Industry />} />
			<Route path="/blog" element={<Blog />} />
			<Route path="/blog-details/:slug" element={<BlogDetails />} />
			<Route path="/testimonial" element={<Testimonial />} />

			{/* <Route path="/otp-confirm" element={<OtpConfirm />} /> */}

			{/* admin route */}
			<Route path="/admin" element={<AdminLayout />}>
				<Route path="dashboard" element={<Dashboard />} />

				{/* admin cms */}
				<Route path="cms/home" element={<HomeContent />} />
				<Route path="cms/how-we-work" element={<HowWeWorkContent />} />
				<Route path="cms/career" element={<CareerContent />} />
				<Route path="cms/about-us" element={<AboutUsContent />} />
				<Route path="cms/portfolio-content" element={<PortFolioContent />} />
				<Route path="cms/list-portfolio" element={<ListPortFolio />} />
				<Route path="cms/edit-portfolio/:id" element={<EditPortFolio />} />
				<Route path="cms/add-service" element={<AddService />} />
				<Route path="cms/list-service" element={<ListService />} />
				<Route path="cms/edit-service/:id" element={<EditService />} />

				<Route path="cms/add-testimonial" element={<AddTestimonial />} />
				<Route path="cms/list-testimonial" element={<ListTestimonial />} />
				<Route path="cms/edit-testimonial/:id" element={<EditTestimonial />} />
				<Route path="cms/list-contact" element={<ListContactUs />} />
				<Route path="cms/add-blog" element={<AddBlog />} />
				<Route path="cms/list-blog" element={<ListBlog />} />
				<Route path="cms/edit-blog/:id" element={<EditBlog />} />
				<Route path="cms/add-portfolio-banner" element={<PortFolioBanner />} />
				<Route path="cms/settings" element={<Settings />} />
				<Route path="cms/add-industry" element={<AddIndustry />} />
				<Route path="cms/list-industry" element={<ListIndustry />} />
				<Route path="cms/edit-industry/:id" element={<EditIndustry />} />
				<Route path="cms/add-logo" element={<AddLogo />} />
				<Route path="cms/list-logo" element={<ListLogo />} />
				<Route path="cms/edit-logo/:id" element={<EditLogo />} />
				<Route path="cms/add-career-category" element={<AddCareerCategory />} />
				<Route path="cms/list-career-category" element={<ListCareerCategory />} />
				<Route path="cms/edit-career-category/:id" element={<EditCareerCategory />} />

				<Route path="cms/add-career-inner" element={<AddCareerInner />} />

				{/* end admin cms */}
			</Route>

			{/* end admin route */}
		</Routes>
	);
};

export default AppRouter;
