const content = (state = {}, action) => {
	// let obj = { ...state };
	let obj = JSON.parse(JSON.stringify(state));
	switch (action.type) {
		case 'SET_HOME_CONTENT':
			obj.home = action.content;
			return obj;
		case 'SET_HOW_WE_WORK_CONTENT':
			obj.howWeWork = action.content;
			return obj;
		case 'SET_CAREER_CONTENT':
			obj.career = action.content;
			return obj;
		case 'SET_ABOUT_CONTENT':
			obj.about = action.content;
			return obj;
		case 'SET_PORTFOLIO_DETAIL_CONTENT':
			obj.portfolioDetail = action.content;
			return obj;
		case 'SET_SETTINGS_CONTENT':
			obj.settings = action.content;
			return obj;
		case 'SET_SERVICE_CONTENT':
			obj.service = action.content;
			return obj;
		case 'SET_INDUSTRY_CONTENT':
			obj.industry = action.content;
			return obj;
		default:
			return state;
	}
};
export default content;
