import React from 'react';
import './PortfolioReliableApp.scss';
import './PortfolioReliableAppResponsive.scss';
import appOne from 'assets/images/common-image/portfolio/app-1.png';
import appTwo from 'assets/images/common-image/portfolio/app-2.png';
import appThree from 'assets/images/common-image/portfolio/app-3.png';
import appFour from 'assets/images/common-image/portfolio/app-4.png';
import { useSelector, useDispatch } from 'react-redux';

const PortfolioReliableApp = () => {
	const portfolioDtlContent = useSelector((state) => state.content?.portfolioDetail);
	const portfolioDtlData = portfolioDtlContent?.portfolioDetailData?.content ? JSON.parse(portfolioDtlContent?.portfolioDetailData?.content) : '';

	return (
		<>
			<section className="PortfolioReliableApp">
				<div className="container">
					<div className="row gx-lg-5 gx-md-4 gx-sm-3 gx-2 g-2 align-items-center">
						{
							portfolioDtlData?.whatWeDid?.gallery?.map((val, i) => (
								<div className="col-lg-3 col-md-3 col-sm-6 col-6" key={`PortfolioReliableApp-${i}`}>
									<div className="prAppImg text-center">
										<img src={val ? `${process.env.REACT_APP_RESOURCE_URL}/${val}` : appOne} alt="" />
									</div>
								</div>
							))
						}
						{/* <div className="col-lg-3 col-md-3 col-sm-6 col-6">
							<div className="prAppImg text-center">
								<img src={appOne} alt="" />
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-6">
							<div className="prAppImg text-center">
								<img src={appTwo} alt="" />
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-6">
							<div className="prAppImg text-center">
								<img src={appThree} alt="" />
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-6">
							<div className="prAppImg text-center">
								<img src={appFour} alt="" />
							</div>
						</div> */}
					</div>
				</div>
			</section>
		</>
	);
};

export default PortfolioReliableApp;
