import React from 'react';
import './BuildReact.scss';
import './BuildReactResponsive.scss';
import { FaWalking, FaHeartbeat } from 'react-icons/fa';
import { GiShoppingBag, GiKnifeFork } from 'react-icons/gi';
import { AiFillCreditCard } from 'react-icons/ai';
import { RiBookFill } from 'react-icons/ri';
import { HiBuildingOffice2, HiTruck2 } from 'react-icons/hi2';
import { SiYourtraveldottv } from 'react-icons/si';
import { IoMdWallet } from 'react-icons/io';
import { HiTruck } from 'react-icons/hi';

import Fitness from 'assets/images/common-image/service-build-react/Fitness.svg';
import ecommerce from 'assets/images/common-image/service-build-react/E-commerce.svg';
import { useDispatch, useSelector } from 'react-redux';

const BuildReact = () => {
	const serviceContent = useSelector((state) => state.content?.service);
	const serviceData = serviceContent?.content ? JSON.parse(serviceContent?.content) : '';

	return (
		<>
			<section className="StobGetBusinessSection">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-5 col-md-5 col-sm-12 col-12">
							<div className="StobGetBusinessLeft relative">
								<h3
									dangerouslySetInnerHTML={{
										__html: serviceData?.section4?.heading ?? `<span>We build </span>React Native application <span>for the almost any industry</span>`,
									}}
								></h3>
							</div>
						</div>
						<div className="col-lg-7 col-md-7 col-sm-12 col-12">
							<div className="bulidReactRight relative">
								<ul className="d-flex flex-wrap">
									{serviceData?.section4?.item?.map((val, i) => (
										<li key={`bulidReactRight-${i}`}>
											<span>
												<img src={val?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${val?.img}` : Fitness} alt="fitness" /> {val?.title}
											</span>
										</li>
									))}
									{/* <li>
										<span>
											<img src={Fitness} alt="fitness" /> Fitness
										</span>
									</li>
									<li>
										<span>
											<img src={ecommerce} alt="E-commerce" /> E-commerce
										</span>
									</li> */}
									{/* <li>
										<span>
											<AiFillCreditCard /> Banking
										</span>
									</li>
									<li>
										<span>
											<GiKnifeFork /> Restaurants
										</span>
									</li>
									<li>
										<span>
											<RiBookFill /> Education
										</span>
									</li>
									<li>
										<span>
											<FaHeartbeat /> Healthcare
										</span>
									</li>
									<li>
										<span>
											<HiBuildingOffice2 /> Realestate
										</span>
									</li>
									<li>
										<span>
											<SiYourtraveldottv /> Travel
										</span>
									</li>
									<li>
										<span>
											<IoMdWallet /> Finance
										</span>
									</li>
									<li>
										<span>
											<HiTruck /> Logistic
										</span>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default BuildReact;
