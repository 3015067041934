import React from 'react';
import './ForgetPassword.scss';
import LoginBg from 'assets/images/loginbg.jpg';
import LoginReglogo from 'assets/images/logo.png';
import OtpConfirm from 'components/OtpConfirm';
function ForgetPassword() {
	return (
		<>
			<section className="login-regbg d-flex align-items-center justify-content-center" style={{ background: `url(${LoginBg})` }}>
				<div className="login-reg-wrap">
					<div className="login-reg-box">
						<span className="log-reg-logo">
							<img className="img-block" src={LoginReglogo} alt="LoginReglogo" />
						</span>
						<div className="login-input">
							<label className="lbl-heading">Email</label>
							<input type="text" className="form-control login-input-style" placeholder="Enter Your Email" />
						</div>
						<div className="logreg-btn-wrap">
							<button type="submit" className="logreg-btn">
								Send OTP
							</button>
						</div>
					</div>
				</div>
			</section>
			{/* <OtpConfirm /> */}
		</>
	);
}

export default ForgetPassword;
