import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';

const AddCareerInner = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'content',
	});
	return (
		<>
			{fields.length > 0 ? (
				<>
					{fields.map((item, index) => (
						<>
							<div className="card" key={index}>
								<div className="card-header">
									<div className="row justify-content-between align-items-center">
										<div className="col-auto">
											<div className="list-head">item</div>
										</div>
										{index > 0 && (
											<div className="col-auto">
												<div className="quotes-delete">
													<button type="button" className="cmn-btn-tag" onClick={() => remove(index)}>
														<i className="fas fa-trash-alt"></i>
													</button>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-sm-6 col-md-6 col-lg-4 col-12">
											<label className="admin-lbl">Title:</label>
											<div className="admim-input-wrap">
												<input
													type="text"
													className="form-control"
													placeholder="Title"
													rows={5}
													{...register(`content[${index}].title`, { required: 'Title is required' })}
												/>
												{errors.content?.[index]?.title && <InputErrorMsg error={errors.content?.[index]?.title?.message} />}
											</div>
										</div>
										<div className="col-sm-6 col-md-6 col-lg-4 col-12">
											<label className="admin-lbl">Description:</label>
											<div className="admim-input-wrap">
												<textarea
													type="text"
													className="form-control"
													placeholder="Description"
													rows={5}
													{...register(`content[${index}].description`, { required: 'Description is required' })}
												/>
												{errors.content?.[index]?.description && <InputErrorMsg error={errors.content?.[index]?.description?.message} />}
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					))}
				</>
			) : (
				<div className="card" key={0}>
					<div className="card-header">
						<div className="row justify-content-between align-items-center">
							<div className="col-auto">
								<div className="list-head">item</div>
							</div>
						</div>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-sm-6 col-md-6 col-lg-4 col-12">
								<label className="admin-lbl">Title:</label>
								<div className="admim-input-wrap">
									<input type="text" className="form-control" placeholder="Title" rows={5} {...register(`content[0].title`, { required: 'Title is required' })} />
									{errors.content?.[0]?.title && <InputErrorMsg error={errors.content?.[0]?.title?.message} />}
								</div>
							</div>
							<div className="col-sm-6 col-md-6 col-lg-4 col-12">
								<label className="admin-lbl">Description:</label>
								<div className="admim-input-wrap">
									<textarea
										type="text"
										className="form-control"
										placeholder="Description"
										rows={5}
										{...register(`content[0].description`, { required: 'Description is required' })}
									/>
									{errors.content?.[0]?.description && <InputErrorMsg error={errors.content?.[0]?.description?.message} />}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="cmn-btn-tag-wrap">
				<button type="button" className="cmn-btn-tag black-bg" onClick={() => append({ title: '', description: '' })}>
					Add more item
				</button>
			</div>
		</>
	);
};

export default AddCareerInner;
