import { default as api } from 'config/frontApiConf';

export const getContentService = async (slug = null) => {
	try {
		let result = await api.get(`/service-details/${slug}`);
		return result;
	} catch (error) {
		return error;
	}
};
