import React, { useEffect } from 'react';
import { useState } from 'react';
import './CareerOpenPosition.scss';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

const CareerOpenPosition = () => {
	const [animate, setAnimate] = useState(0);
	useEffect(() => {
		document.addEventListener('click', function (e) {
			var container = document.querySelector('.applyForm');
			var button = document.querySelectorAll('.caseStudy');

			if (!container.contains(e.target)) {
				setAnimate(0);
			}
			Array.from(button).forEach((val) => {
				if (val.contains(e.target)) {
					setAnimate(1);
				}
			});

			// 	// if (button.contains(e.target)) {
			// 	// 	setAnimate(1);
			// 	// }
			e.stopPropagation();
		});
	}, []);
	const handleClick = () => {
		// alert('dfdf');
		setAnimate(!animate);
	};
	return (
		<>
			<section className="openPosition">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="OpenPositionTitle">
								<h2>Open Position</h2>
							</div>
							<div className="opAccordion">
								<Accordion>
									<Accordion.Item eventKey="0">
										<Accordion.Header>Sales Development Representative (USA, Canada)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>IBM Sterling QA Engineer (Poland)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>Full-stack (ReactJS/NodeJS) Developer (LATAM, Poland)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header>SFCC QA Engineer (Poland)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4">
										<Accordion.Header>Java Developer (LATAM)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="5">
										<Accordion.Header>React Native Developer (LATAM, Poland)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6">
										<Accordion.Header>Release Engineer (LATAM, Poland)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="7">
										<Accordion.Header>Test Automation Infrastructure Architect (LATAM, Poland)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="9">
										<Accordion.Header>Data Platform Engineer (LATAM)</Accordion.Header>
										<Accordion.Body>
											<div className="accordionBodyInner">
												<h4>We are seeking a self-motivated Sales Development Representative to join our growing team.</h4>
												<div className="abiBox">
													<h5>Responsibilities:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Requirements:</h5>
													<ul>
														<li>Partner with sales and marketing teams to create customized plans for sales territories</li>
														<li>Conduct lead generation, prospecting, and qualifying potential new clients</li>
														<li>
															Outreach to senior executives at organizations to generate interest in our services and create commercial opportunities
															– outreach channels include cold calls, tailored emails, video-mails, events and LinkedIn
														</li>
														<li>Understand and deliver our value proposition for a wide range of industries, personas and pain points</li>
														<li>Track all prospective client interactions through CRM to ensure accurate and consistent reporting</li>
														<li>Share ideas and best practices with the team to contribute to Solvd growth</li>
													</ul>
												</div>
												<div className="abiBox">
													<h5>Nice to have:</h5>
													<ul>
														<li>Experience in B2B sector</li>
														<li>Experience in software outsourcing</li>
													</ul>
												</div>
												<div className="abiBox mt-4">
													<Link to="#" className="caseStudy" onClick={handleClick}>
														Apply
													</Link>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
					</div>
				</div>
				<div className={animate ? 'applyForm active' : 'applyForm'}>
					<div className="applyFormInner">
						<h3>Respond to the vacancy</h3>
						<p>IBM Sterling QA Engineer (Poland)</p>
						<div>
							<div className="form-floating mb-3">
								<input type="email" className="form-control applyInput" id="floatingInput" placeholder="Full name" />
								<label htmlFor="floatingInput">Full name</label>
							</div>
							<div className="form-floating mb-3">
								<input type="email" className="form-control applyInput" id="floatingInput" placeholder="Phone Number *" />
								<label htmlFor="floatingInput">Phone Number *</label>
							</div>

							<div className="form-floating mb-3">
								<input type="email" className="form-control applyInput" id="floatingInput" placeholder="name@example.com" />
								<label htmlFor="floatingInput">Email address</label>
							</div>
							{/* <div className="form-floating mb-3">
								<input
									type="password"
									className="form-control applyInput"
									id="floatingPassword"
									placeholder="Password"
								/>
								<label htmlFor="floatingPassword">Password</label>
							</div> */}
							<div className="form-floating mb-3">
								<textarea className="form-control applyInput applyTextarea" id="comment" name="text" placeholder="Comment goes here" defaultValue={''} />
								<label htmlFor="comment">Comments</label>
							</div>

							<div className="file-upload mb-4">
								<div className="file-select">
									<div className="file-select-button" id="fileName">
										Choose File
									</div>
									<div className="file-select-name" id="noFile">
										No file chosen...
									</div>
									<input type="file" name="chooseFile" id="chooseFile" />
								</div>
							</div>

							{/* <div className="checkbox checkbox-success checkbox-inline mt-3 mb-3 ps-2">
								<input type="checkbox" className="styled" id="cb-1" defaultValue="option1" />
								<label htmlFor="cb-1"> I have read and agree to the Privacy Policy</label>
							</div> */}
							<div className="relative">
								<button type="button" class="sendBtn">
									<span>Send</span>
								</button>
							</div>
						</div>
					</div>
					<Link to="#" className="consHolClose" onClick={handleClick}>
						<i className="fas fa-times"></i>
					</Link>
				</div>
			</section>
		</>
	);
};

export default CareerOpenPosition;
