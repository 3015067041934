import { default as api } from 'config/authApiConf.js';

export const addContentService = async (id, data = {}) => {
	try {
		let result = await api.put(`/cms/edit-industry/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const industryDetailService = async (id = null) => {
	try {
		let result = await api.get(`/cms/industry-details/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
