import { default as api } from 'config/frontApiConf';

export const getContentService = async (data = {}) => {
	try {
		let result = await api.post(`/add-contact`, data);
		return result;
	} catch (error) {
		return error;
	}
};
