import React, { useEffect } from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CarrerBanner from 'components/CarrerBanner';
import CarrerComeOnBoard from 'components/CarrerComeOnBoard';
import CarrierRightMatch from 'components/CarrierRightMatch';
// import CarrerForm from 'components/CarrerForm';
import CareerWorkHard from 'components/CareerWorkHard';

import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService } from './carrer.service.js';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import './Carrer.scss';

const Carrer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, []);

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setCareerContent(result?.data?.data));
		} else {
			toast.error(result.data.error);
		}
	};
	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<CarrerBanner />
			<CarrerComeOnBoard />
			<CareerWorkHard />
			<CarrierRightMatch />

			{/* <CarrerForm /> */}

			<Footer />
		</>
	);
};

export default Carrer;
