import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Navigate, Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import NoImage from 'assets/images/avatar.png';
import { toast } from 'react-toastify';

import { listContactService } from './ListContactUs.service.js';

const ListContactUs = () => {
	const [contactUs, setConatactUs] = useState([]);
	const [contactUsMeta, setContactUsMeta] = useState({});
	const [page, setPage] = useState(1);
	const [redirect, setRedirect] = useState(0);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');

	useEffect(() => {
		getContactUsList();
	}, [page]);

	const getContactUsList = async () => {
		const list = await listContactService(page);
		if (list.data.status === 200) {
			setConatactUs(list.data.data.records);
			setContactUsMeta(list.data.data.meta);
		} else {
			toast.error(list.data.error);
		}
	};
	const columns = [

		{
			name: 'Name',
			selector: (row) => row?.name,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row?.email,
			sortable: true,
		},
		{
			name: 'Phone Number',
			selector: (row) => row?.phone,
			sortable: true,
		},
		{
			name: 'Message',
			selector: (row) => row?.message,
			sortable: true,
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Contact' },
					{ link: '#', name: 'List' },
				]}
				title="Contact List"
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="datatable-wrap">
				<DataTable
					// title="Employees"
					columns={columns}
					data={contactUs}
					striped
					// highlightOnHover
					// selectableRows
					pagination
					paginationServer
					paginationTotalRows={contactUsMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
};

export default ListContactUs;
