// import Home from 'pages/Home';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import Loader from 'components/Loader';

// import AdminRoute from "routes/AdminRoute";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/style.scss';
import 'assets/admin/styles/styles.scss';
import 'assets/styles/build.css';
import AppRouter from 'routes/AppRoute';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

function App() {
	// const dispatch = useDispatch();
	// useEffect(() => {
	//   dispatch(allActions.loadingAction.showLoading());
	// }, [])

	const loading = useSelector((state) => state.loading);
	return (
		<>
			{loading && <Loader />}
			{/* {<Loader />} */}
			<BrowserRouter>
				{/* <Routes> */}
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>

				<AppRouter />

				{/* <AdminRoute /> */}
				{/* </Routes> */}
			</BrowserRouter>
		</>
	);
}

export default App;
