import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';
import { addContentService, getContentService } from './CareerContent.service.js';

function CareerContent() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'comeOnBoard',
	});
	const [bannerImg, setBannerImg] = useState('');
	const [section3Img, setSection3Img] = useState('');
	const [section4Img, setSection4Img] = useState('');
	const [section5Img, setSection5Img] = useState('');
	const [comeOnBoardImg, setComeOnBoardImg] = useState([]);
	const [reRender, setReRender] = useState(0);
	const [content, setContent] = useState({});

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.bannerTitle = content?.content?.banner?.title;
		defaultValues.bannerDescription = content?.content?.banner?.description;

		defaultValues.section3Title = content?.content?.section3?.title;

		defaultValues.section4Title = content?.content?.section4?.title;
		defaultValues.section4Description = content?.content?.section4?.description;

		defaultValues.section5Title = content?.content?.section5?.title;
		defaultValues.section5Description = content?.content?.section5?.description;

		defaultValues.section6Title = content?.content?.section6?.title;
		defaultValues.section6Description = content?.content?.section6?.description;

		// const boardArr = [];
		// const boardImgArr = [];
		// content?.content?.comeOnBoard.map((val, i) => {
		// 	boardArr.push({
		// 		boardTitle: val?.title,
		// 		boardHiring: val?.hiring,
		// 	});
		// 	boardImgArr.push(`${content?.fileBaseUrl}/uploads/${val?.img}`);
		// });

		// if (boardArr.length > 0) {
		// 	defaultValues.comeOnBoard = boardArr;
		// } else {
		// 	defaultValues.comeOnBoard = [1, 2, 3];
		// }

		await reset({ ...defaultValues });

		if (content?.content?.section3?.img) {
			setSection3Img(`${content?.fileBaseUrl}/uploads/${content?.content?.section3?.img}`);
		}

		if (content?.content?.section4?.img) {
			setSection4Img(`${content?.fileBaseUrl}/uploads/${content?.content?.section4?.img}`);
		}
		if (content?.content?.section5?.img) {
			setSection5Img(`${content?.fileBaseUrl}/uploads/${content?.content?.section5?.img}`);
		}

		if (content?.content?.banner?.img) {
			setBannerImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.img}`);
		}

		// if (boardImgArr.length > 0) {
		// 	setComeOnBoardImg(boardImgArr);
		// }
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const handleSection3Img = async (e) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setSection3Img(img);
		}
	};
	const handleSection4Img = async (e) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setSection4Img(img);
		}
	};
	const handleSection5Img = async (e) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setSection5Img(img);
		}
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const onSubmit = async (data) => {
		if (!bannerImg || !section3Img || !section4Img || !section5Img) {
			toast.error(`Please select all image`);
			return false;
		}
		const formData = {
			...data,
			bannerImg,
			// comeOnBoardImg,
			section3Img,
			section4Img,
			section5Img,
		};
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	const removeBoardItem = async (index) => {
		remove(index);
		setComeOnBoardImg(comeOnBoardImg.filter((val, i) => i !== index));
	};

	const handleBoardLogo = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			comeOnBoardImg[index] = img;
			setComeOnBoardImg([...comeOnBoardImg]);
			setReRender(!reRender);
		}
	};

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			console.log(result?.data?.data);
			setContent(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Career' },
				]}
				title="Career Content"
			/>
			<div className="admin-white-box">
				<div className="row">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Banner Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="content_img" onChange={handleBannerImg} />
														<label htmlFor="content_img">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														rows={5}
														{...register('bannerTitle', { required: 'Title is required' })}
													/>
													{errors.bannerTitle && <InputErrorMsg error={errors.bannerTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('bannerDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading2">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse2"
										aria-expanded="false"
										aria-controls="panelsStayOpen-collapse2"
									>
										Come On Board Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading2">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												{fields.map((item, index) => (
													<>
														<div className="card" key={index}>
															<div className="card-header">
																<div className="row justify-content-between align-items-center">
																	<div className="col-auto">
																		<div className="list-head">item</div>
																	</div>
																	<div className="col-auto">
																		<div className="quotes-delete">
																			<button type="button" className="cmn-btn-tag" onClick={() => removeBoardItem(index)}>
																				<i className="fas fa-trash-alt"></i>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card-body">
																<div className="row justify-content-center">
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Logo</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`boardImg${index}`} onChange={(e) => handleBoardLogo(e, index)} />
																				<label htmlFor={`boardImg${index}`}>
																					{!comeOnBoardImg?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Logo...</p>
																						</div>
																					)}
																					{comeOnBoardImg?.[index] && (
																						<div className="preview-img">
																							<img src={comeOnBoardImg?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Title:</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control"
																				placeholder="Title"
																				rows={5}
																				{...register(`comeOnBoard[${index}].boardTitle`, { required: 'Title is required' })}
																			/>
																			{errors.comeOnBoard?.[index]?.boardTitle && (
																				<InputErrorMsg error={errors.comeOnBoard?.[index]?.boardTitle?.message} />
																			)}
																		</div>
																	</div>
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Hiring:</label>
																		<div className="admim-input-wrap">
																			<select
																				className="form-control"
																				{...register(`comeOnBoard[${index}].boardHiring`, { required: 'Hiring is required' })}
																			>
																				<option value="">--Select--</option>
																				<option value="1">Yes</option>
																				<option value="0">No</option>
																			</select>
																			{errors.comeOnBoard?.[index]?.boardHiring && (
																				<InputErrorMsg error={errors.comeOnBoard?.[index]?.boardHiring?.message} />
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												))}
												<div className="cmn-btn-tag-wrap">
													<button type="button" className="cmn-btn-tag black-bg" onClick={() => append({ logo: '', boardHiring: '', boardTitle: '' })}>
														Add more item
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}

							<div className="accordion-item">
								<h2 className="accordion-header" id="heading3">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse3"
										aria-expanded="false"
										aria-controls="collapse3"
									>
										Section 3
									</button>
								</h2>
								<div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="section3Img" onChange={handleSection3Img} />
														<label htmlFor="section3Img">
															{!section3Img && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{section3Img && (
																<div className="preview-img">
																	<img src={section3Img} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('section3Title', {
															required: 'Title is required',
														})}
													/>
													{errors.section3Title && <InputErrorMsg error={errors.section3Title?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="heading4">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse4"
										aria-expanded="false"
										aria-controls="collapse4"
									>
										Section 4
									</button>
								</h2>
								<div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="section4Img" onChange={handleSection4Img} />
														<label htmlFor="section4Img">
															{!section4Img && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{section4Img && (
																<div className="preview-img">
																	<img src={section4Img} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('section4Title', {
															required: 'Title is required',
														})}
													/>
													{errors.section4Title && <InputErrorMsg error={errors.section4Title?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														rows={5}
														className="form-control"
														placeholder="Description"
														{...register('section4Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section4Description && <InputErrorMsg error={errors.section4Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="heading5">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse5"
										aria-expanded="false"
										aria-controls="collapse5"
									>
										Section 5
									</button>
								</h2>
								<div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="section5Img" onChange={handleSection5Img} />
														<label htmlFor="section5Img">
															{!section5Img && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{section5Img && (
																<div className="preview-img">
																	<img src={section5Img} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('section5Title', {
															required: 'Title is required',
														})}
													/>
													{errors.section5Title && <InputErrorMsg error={errors.section5Title?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														rows={5}
														className="form-control"
														placeholder="Description"
														{...register('section5Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section5Description && <InputErrorMsg error={errors.section5Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="heading6">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapse6"
										aria-expanded="false"
										aria-controls="collapse6"
									>
										Section 6
									</button>
								</h2>
								<div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('section6Title', {
															required: 'Title is required',
														})}
													/>
													{errors.section6Title && <InputErrorMsg error={errors.section6Title?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														rows={5}
														className="form-control"
														placeholder="Description"
														{...register('section6Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section6Description && <InputErrorMsg error={errors.section6Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="cmn-btn-tag-wrap">
									<button type="submit" className="cmn-btn-tag">
										Save
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default CareerContent;
