import { default as api } from 'config/authApiConf.js';
import { default as frontApi } from 'config/frontApiConf.js';

export const addContentService = async (data = {}) => {
	try {
		let result = await api.post('/cms/add-portfolio-content', data);
		return result;
	} catch (error) {
		return error;
	}
};
export const listLogoService = async (data = {}) => {
	try {
		let result = await frontApi.get('/list-logo');
		return result;
	} catch (error) {
		return error;
	}
};
