import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import './HomeVideoSection.scss';
import './HomeVideoSectionResponsive.scss';
import videoPoster from 'assets/images/common-image/video-poster.jpg';
import video1 from 'assets/images/common-image/video/video1.mov';
import { Link } from 'react-router-dom';
import review from 'assets/images/common-image/review.png';
import { useSelector, useDispatch } from 'react-redux';

function HomeVideoSection() {
	const homeContent = useSelector((state) => state.content?.home);
	const homeData = homeContent?.homeData?.content ? JSON.parse(homeContent?.homeData?.content) : '';

	return (
		<>
			<section className="relative videoBoxWrap">
				<div className="container-fluid p-h-30">
					<div className="row">
						<div className="videoWrap">
							<div className="videoWrapInner waterEffect">
								<Player
									playsInline
									poster={homeData?.VdoSection?.vdoPlaceholder ? `${process.env.REACT_APP_RESOURCE_URL}/${homeData?.VdoSection?.vdoPlaceholder}` : videoPoster}
									src={homeData?.VdoSection?.video ? `${process.env.REACT_APP_RESOURCE_URL}/${homeData?.VdoSection?.video}` : video1}
								/>
							</div>
						</div>
						<div className="boxWrap">
							<div className="boxWrapInner">
								<div
									className="nectar-highlighted-text font_size_61px "
									data-style="full_text"
									data-exp="default"
									data-user-color="true"
									style={{ color: '#000000' }}
									data-using-custom-color="true"
									data-animation-delay="false"
									data-color="#ffd74b"
									data-color-gradient
								>
									<h2
										dangerouslySetInnerHTML={{
											__html:
												homeData?.VdoSection?.description ??
												`Through bold digital designs, innovative development &amp; unique SEO techniques,&nbsp;
										<em className="animated">we’ve snagged a few awards.</em>`,
										}}
									></h2>
								</div>

								<span className="link_wrap">
									<Link to={'/about'} className="link_text">
										LEARN MORE ABOUT US
									</Link>
								</span>

								<div className="clRev">
									<img src={review} alt="" />
								</div>

								{/* <div className="row">
                                    <div className='avBox-1'>
                                        <div className='avBox waterEffect d-flex justify-content-center align-items-center bg-7'>
                                            <div className='avBoxInner text-center'>
                                                <div className='avBoxIcon'>
                                                    <img src={clutch} alt="" />
                                                </div>
                                                <div className='cluch'>
                                                    <span>4.9 star</span>on clutchcom
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='avBox-2'>
                                        <div className='avBox waterEffect d-flex justify-content-center align-items-center bg-8'>
                                            <div className='cluch text-center'>
                                                <span>Fast 50</span>Technology Fast50 Deloitte
                                            </div>

                                        </div>
                                    </div>
                                    <div className='avBox-3'>
                                        <div className='avBox waterEffect d-flex justify-content-center align-items-center bg-9'>
                                            <div className='avBoxInner text-center'>
                                                <div className='avBoxIcon'>
                                                    <img src={globe} alt="" />
                                                </div>
                                                <div className='cluch'>
                                                    <span>4.9 star</span>on clutchcom
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='avBox-4'>
                                        <a className='avBox waterEffect d-flex justify-content-center align-items-center isoBg'>
                                            <div className='avBoxIcon mb-0'>
                                                <img src={iso} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HomeVideoSection;
