import React, { useEffect } from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import HomeSuccessProduct from 'components/HomeSuccessProduct';
import SttBanner from 'components/SttBanner/SttBanner';
import ServicesTempTwoBody from 'components/ServicesTempTwoBody/ServicesTempTwoBody';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { getContentService } from './Industry.service.js';

const Industry = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, [slug]);

	const getContent = async () => {
		const result = await getContentService(slug);
		console.log('industry', result?.data?.data?.industry);
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setIndustryContent(result?.data?.data?.industry));
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<SttBanner />
			<ServicesTempTwoBody />
			<HomeSuccessProduct />
			<Footer />
		</>
	);
};
export default Industry;
