import React, { useEffect, useState } from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import LetsTalk from 'components/LetsTalk';
import HomeSuccessProduct from 'components/HomeSuccessProduct';
import HomePartner from 'components/HomePartner';
import HowWeWorkBanner from 'components/HowWeWorkBanner';
import HowWeWorkInnerContent from 'components/HowWeWorkInnerContent';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService } from './HowWeWork.service.js';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import './HowWeWork.scss';

const HowWeWork = () => {
	const dispatch = useDispatch();

	const howWeWorkContent = useSelector((state) => state?.content?.howWeWork);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
		// getContent();
	}, []);

	const getContent = async () => {
		// dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setHowWeWorkContent(result?.data?.data));
		} else {
			toast.error(result.data.error);
		}
		// dispatch(allActions.loadingAction.hideLoading());
	};
	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<HowWeWorkBanner />
			<HowWeWorkInnerContent />
			<HomeSuccessProduct />
			<HomePartner />
			<Footer />
			<LetsTalk />
		</>
	);
};

export default HowWeWork;
