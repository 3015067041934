import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';

import { addContentService } from './AddService.service.js';
import './AddService.scss';

const AddService = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm({
		defaultValues: {
			section4Item: [
				{
					title: '',
				},
			],
			faq: [
				{
					question: '',
					answer: '',
				},
			],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'section4Item',
	});

	const {
		fields: faqFields,
		append: faqAppend,
		remove: faqRemove,
	} = useFieldArray({
		control,
		name: 'faq',
	});

	const section5item = [1, 2, 3, 4, 5, 6];
	const [section1Img, setSection1Img] = useState('');
	const [section2Img, setSection2Img] = useState('');
	const [section3Img, setSection3Img] = useState('');
	const [section4Img, setSection4Img] = useState([]);
	const [section6Img, setSection6Img] = useState('');
	const [bannerImg, setBannerImg] = useState('');
	const [iconImg, setIconImg] = useState('');
	const [reRender, setReRender] = useState(0);

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const handleSection6Img = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setSection6Img(img);
		}
	};
	const handleSection2Img = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setSection2Img(img);
		}
	};
	const handleSection3Img = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setSection3Img(img);
		}
	};
	const handleSection4Img = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			section4Img[index] = img;
			setSection4Img([...section4Img]);
			setReRender(!reRender);
		}
	};
	const handleIconImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setIconImg(img);
		}
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const onSubmit = async (data) => {
		// console.log(data);
		const img1 = section4Img.filter(function (element) {
			return element !== undefined;
		});
		if (!bannerImg || !section2Img || !section3Img || !iconImg || !section6Img || data?.section4Item?.length !== img1?.length) {
			toast.error(`Please select all image`);
			return false;
		}

		let formData = {
			...data,
			section2Img,
			section3Img,
			section4Img,
			section6Img,
			bannerImg,
			iconImg,
		};

		console.log(formData);

		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
			reset();
			setSection1Img('');
			setSection2Img('');
			setSection3Img('');
			setSection4Img([]);
			setSection6Img('');
			setBannerImg('');
			setIconImg('');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	const removeSection4Img = async (index) => {
		remove(index);
		setSection4Img(section4Img.filter((val, i) => i !== index));
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Service' },
				]}
				title="Service Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-banner-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-banner"
										aria-expanded="true"
										aria-controls="panelsStayOpen-banner"
									>
										Banner
									</button>
								</h2>
								<div id="panelsStayOpen-banner" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-banner-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="bannerImg" onChange={handleBannerImg} />
														<label htmlFor="bannerImg">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading :</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('bannerHeading', { required: 'Heading is required' })}
													/>
													{errors.bannerHeading && <InputErrorMsg error={errors.bannerHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Banner Box Title :</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														{...register('bannerTitle', { required: 'Title is required' })}
													/>
													{errors.bannerTitle && <InputErrorMsg error={errors.bannerTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('bannerStatus', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.bannerStatus && <InputErrorMsg error={errors.bannerStatus?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('bannerDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Section 1
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section1heading', { required: 'Heading is required' })}
													/>
													{errors.section1heading && <InputErrorMsg error={errors.section1heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('section1Status', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.section1Status && <InputErrorMsg error={errors.section1Status?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section1Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section1Description && <InputErrorMsg error={errors.section1Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading2">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse2"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse2"
									>
										Section 2
									</button>
								</h2>
								<div id="panelsStayOpen-collapse2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading2">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="section2Img" onChange={handleSection2Img} />
														<label htmlFor="section2Img">
															{!section2Img && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{section2Img && (
																<div className="preview-img">
																	<img src={section2Img} className="img-block" alt="section2Img" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section2heading', { required: 'Heading is required' })}
													/>
													{errors.section2heading && <InputErrorMsg error={errors.section2heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('section2Status', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.section2Status && <InputErrorMsg error={errors.section2Status?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section2Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section2Description && <InputErrorMsg error={errors.section2Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading3">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse3"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse3"
									>
										Section 3
									</button>
								</h2>
								<div id="panelsStayOpen-collapse3" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading3">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="section3Img" onChange={handleSection3Img} />
														<label htmlFor="section3Img">
															{!section3Img && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{section3Img && (
																<div className="preview-img">
																	<img src={section3Img} className="img-block" alt="section3Img" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section3heading', { required: 'Heading is required' })}
													/>
													{errors.section3heading && <InputErrorMsg error={errors.section3heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('section3Status', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.section3Status && <InputErrorMsg error={errors.section3Status?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section3Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section3Description && <InputErrorMsg error={errors.section3Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading4">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse4"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse4"
									>
										Section 4
									</button>
								</h2>
								<div id="panelsStayOpen-collapse4" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section4heading', { required: 'Heading is required' })}
													/>
													{errors.section4heading && <InputErrorMsg error={errors.section4heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('section4Status', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.section4Status && <InputErrorMsg error={errors.section4Status?.message} />}
												</div>
											</div>
											<div className="col-12">
												{fields.map((item, index) => (
													<>
														<div className="card" key={index}>
															<div className="card-header">
																<div className="row justify-content-between align-items-center">
																	<div className="col-auto">
																		<div className="list-head">item {index + 1}</div>
																	</div>
																	{index !== 0 && (
																		<div className="col-auto">
																			<div className="quotes-delete">
																				<button type="button" className="cmn-btn-tag" onClick={() => removeSection4Img(index)}>
																					<i className="fas fa-trash-alt"></i>
																				</button>
																			</div>
																		</div>
																	)}
																</div>
															</div>
															<div className="card-body">
																<div className="row justify-content-center">
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Image</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`section4Img${index}`} onChange={(e) => handleSection4Img(e, index)} />
																				<label htmlFor={`section4Img${index}`}>
																					{!section4Img?.[index] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Image...</p>
																						</div>
																					)}
																					{section4Img?.[index] && (
																						<div className="preview-img">
																							<img src={section4Img?.[index]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																		<label className="admin-lbl">Title</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control admim-input-style"
																				placeholder="Title"
																				{...register(`section4Item[${index}].title`, {
																					required: 'Title is required',
																				})}
																			/>
																			{errors?.section4Item?.[index]?.title && (
																				<InputErrorMsg error={errors?.section4Item?.[index]?.title?.message} />
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												))}
												<div className="cmn-btn-tag-wrap">
													<button
														type="button"
														className="cmn-btn-tag black-bg"
														onClick={() =>
															append({
																title: '',
															})
														}
													>
														Add more item
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading5">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse5"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse5"
									>
										Section 5
									</button>
								</h2>
								<div id="panelsStayOpen-collapse5" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading5">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section5heading', { required: 'Heading is required' })}
													/>
													{errors.section5heading && <InputErrorMsg error={errors.section5heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('section5Status', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.section5Status && <InputErrorMsg error={errors.section5Status?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section5Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section5Description && <InputErrorMsg error={errors.section5Description?.message} />}
												</div>
											</div>
										</div>
										<div className="item-box-wrap">
											<div className="row">
												{section5item?.map((val, i) => (
													<div className="col-lg-4 col-md-4 col-sm-6 col-12" key={`item-${val}`}>
														<h4 className="items-list-head">item {val}</h4>
														<div className="items-list">
															<label className="admin-lbl">Title:</label>
															<div className="admim-input-wrap">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Title"
																	{...register(`section5Item[${i}].title`, { required: 'Title is required' })}
																/>
																{errors.section5Item?.[i]?.title && <InputErrorMsg error={errors.section5Item?.[i]?.title?.message} />}
															</div>
														</div>
														<div className="items-list">
															<label className="admin-lbl">Description:</label>
															<div className="admim-input-wrap">
																<textarea
																	className="form-control"
																	placeholder="Description"
																	rows={3}
																	{...register(`section5Item[${i}].description`, {
																		required: 'Description is required',
																	})}
																></textarea>
																{errors.section5Item?.[i]?.description && <InputErrorMsg error={errors.section5Item?.[i]?.description?.message} />}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading6">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse6"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse6"
									>
										Faq Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapse6" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading6">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="section6Img" onChange={handleSection6Img} />
														<label htmlFor="section6Img">
															{!section6Img && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{section6Img && (
																<div className="preview-img">
																	<img src={section6Img} className="img-block" alt="section6Img" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section6heading', { required: 'Heading is required' })}
													/>
													{errors.section6heading && <InputErrorMsg error={errors.section6heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Status:</label>
												<div className="admim-select-wrap">
													<select
														className="form-control"
														{...register('section6Status', {
															required: 'Status is required',
														})}
													>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
													{errors.section6Status && <InputErrorMsg error={errors.section6Status?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section6Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section6Description && <InputErrorMsg error={errors.section6Description?.message} />}
												</div>
											</div>
											<div className="col-12">
												{faqFields.map((item, index) => (
													<>
														<div className="card" key={`faq-` + index}>
															<div className="card-header">
																<div className="row justify-content-between align-items-center">
																	<div className="col-auto">
																		<div className="list-head">Faq {index + 1}</div>
																	</div>
																	{index !== 0 && (
																		<div className="col-auto">
																			<div className="quotes-delete">
																				<button type="button" className="cmn-btn-tag" onClick={() => faqRemove(index)}>
																					<i className="fas fa-trash-alt"></i>
																				</button>
																			</div>
																		</div>
																	)}
																</div>
															</div>
															<div className="card-body">
																<div className="row ">
																	<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																		<label className="admin-lbl">Faq Question</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control admim-input-style"
																				placeholder="Question"
																				{...register(`faq[${index}].question`, {
																					required: 'Question is required',
																				})}
																			/>
																			{errors?.faq?.[index]?.question && <InputErrorMsg error={errors?.faq?.[index]?.question?.message} />}
																		</div>
																	</div>
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Answer:</label>
																		<div className="admim-input-wrap">
																			<textarea
																				className="form-control"
																				placeholder="Answer"
																				rows={5}
																				{...register(`faq[${index}].answer`, {
																					required: 'Answer is required',
																				})}
																			></textarea>
																			{errors?.faq?.[index]?.answer && <InputErrorMsg error={errors?.faq?.[index]?.answer?.message} />}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												))}
												<div className="cmn-btn-tag-wrap">
													<button
														type="button"
														className="cmn-btn-tag black-bg"
														onClick={() =>
															faqAppend({
																question: '',
															})
														}
													>
														Add more item
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading8">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse8"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse8"
									>
										Info Section (For Header)
									</button>
								</h2>
								<div id="panelsStayOpen-collapse8" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading8">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<label htmlFor="">Icon</label>
														<input type="file" id="iconImg" onChange={handleIconImg} />
														<label htmlFor="iconImg">
															{!iconImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload Icon...</p>
																</div>
															)}
															{iconImg && (
																<div className="preview-img">
																	<img src={iconImg} className="img-block" alt="iconImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Service Name:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Service Name"
														{...register('serviceName', { required: 'Service Name is required' })}
													/>
													{errors.serviceName && <InputErrorMsg error={errors.serviceName?.message} />}
												</div>
											</div>

											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Short Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={3}
														{...register('infoShortDesc', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.infoShortDesc && <InputErrorMsg error={errors.infoShortDesc?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="cmn-btn-tag-wrap">
									<button type="submit" className="cmn-btn-tag">
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddService;
