import React from 'react';
import './Projects.scss';
import './ProjectsResponsive.scss';
import { Link } from 'react-router-dom';
import imgOne from 'assets/images/common-image/portfolio/1.jpg';
import imgTwo from 'assets/images/common-image/portfolio/2.jpg';
import imgThree from 'assets/images/common-image/portfolio/3.jpg';
import imgFour from 'assets/images/common-image/portfolio/4.jpg';
import imgFive from 'assets/images/common-image/portfolio/5.jpg';
import { useSelector, useDispatch } from 'react-redux';

const Projects = () => {
	const portfolioDtlContent = useSelector((state) => state.content?.portfolioDetail);
	const portfolioDtlData = portfolioDtlContent?.portfolioDetailData?.content ? JSON.parse(portfolioDtlContent?.portfolioDetailData?.content) : '';

	return (
		<>
			<section className="projectsArea">
				<div className="container-fluid p-h-40">
					<div className="row gx-lg-5 gx-md-4 gx-sm-3 gy-lg-5 gy-md-4 gy-sm-3 justify-content-center">
						{
							portfolioDtlData?.problemSection?.gallery?.map((val, i) => (
								<div className="col-lg-4 col-md-4 col-sm-6 col-6" key={`project-${i}`}>
									<div className="imgBox">
										<img src={val ? `${process.env.REACT_APP_RESOURCE_URL}/${val}` : imgTwo} alt="" />
									</div>
								</div>
							))
						}
						{/* <div className="col-lg-4 col-md-4 col-sm-6 col-6">
							<div className="imgBox">
								<img src={imgOne} alt="" />
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-6">
							<div className="imgBox">
								<img src={imgTwo} alt="" />
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-6">
							<div className="imgBox">
								<img src={imgThree} alt="" />
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-6">
							<div className="imgBox">
								<img src={imgThree} alt="" />
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-6">
							<div className="imgBox">
								<img src={imgOne} alt="" />
							</div>
						</div> */}
					</div>
				</div>
			</section>
			{/* <section class="projectsImages">

				<div class="imgBox">
					<img src={imgFour} alt="" />
				</div>

				<div class="imgBox">
					<img src={imgFive} alt="" />
				</div>
			</section>
		</>
	);
};

                <div class="imgBox">
                    <img src={imgFive} alt="" />
                </div>

            </section> */}
		</>
	);
};

export default Projects;
