import { default as api } from 'config/authApiConf.js';

export const careerCategoryDetailService = async (id = null) => {
	try {
		let result = await api.get(`/cms/career-category-details/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const careerCategoryEditService = async (id = null, data = {}) => {
	try {
		let result = await api.put(`/cms/edit-career-category/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
