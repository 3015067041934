const auth = (state = { id: '', role: '', avatar: '', name: '' }, action) => {
	switch (action.type) {
		case 'SET_AUTH_USER':
			return {
				id: action.user.id,
				role: action.user.role,
				avatar: action.user.avatar,
				name: action.user.name,
			};
		default:
			return state;
	}
};
export default auth;
