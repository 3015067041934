import React, { useEffect, useState } from 'react';
import './Blog.scss';
import './BlogResponsive.scss';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BlogBanner from 'components/BlogBanner';
import blogOne from 'assets/images/common-image/bolg/1.jpg';
import ReactPaginate from 'react-paginate';
import BlogLatestNews from 'components/BlogLatestNews';
import { getContentService } from './Blog.service.js';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Helmet } from 'react-helmet';

const Blog = () => {
	const dispatch = useDispatch();
	const [blog, setBlog] = useState([]);
	const [blogMeta, setBlogMeta] = useState({});
	const [recentBlog, setRecentBlog] = useState([]);
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, [page]);

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			setBlog(result?.data?.data?.records);
			setBlogMeta(result?.data?.data?.meta);
			setRecentBlog(result?.data?.data?.recentBlog);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<section className="blogTitle">
				<h2>blog</h2>
			</section>
			<section className="p-v-40 blog">
				<div className="container">
					<div className="row">
						<div className="col-md-9 col-sm-9 col-12">
							{blog?.map((val, i) => (
								<div className="d-flex flex-wrap blogInner align-items-center">
									<div className="imgPart relative">
										<div className="box-img fullwidth">
											<Link to={`/blog-details/${val?.slug}`}>
												<img src={val?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${val?.img}` : blogOne} alt="" />
											</Link>
										</div>
										<div className="box-date">
											<div className="date">
												{moment(val?.createdAt).format('DD-MM-YYYY')[0]}
												<span>{moment(val?.createdAt).format('DD-MM-YYYY')[1]}</span>
											</div>
											<div className="month">{moment(val?.createdAt).format('MMM')}</div>
										</div>
									</div>
									<div className="tetailPart">
										<div className="box-content fullwidth">
											<Link
												to={`/blog-details/${val?.slug}`}
												className="title"
												dangerouslySetInnerHTML={{
													__html: val?.title ?? `Many people limit themselves`,
												}}
											></Link>
											<div className="blogText fullwidth m-b-20">
												<p
													dangerouslySetInnerHTML={{
														__html:
															val?.description ??
															`Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor, sit amet consectetur
													adipisicing elit. Minima doloremque fugiat.`,
													}}
												></p>
											</div>
											<div className="fullwidth btncover">
												<span className="stylishBtn">
													<Link to={`/blog-details/${val?.slug}`}>Read More</Link>
												</span>
											</div>
										</div>
									</div>
								</div>
							))}
							{blog.length > 0 && (
								<ReactPaginate
									previousLabel={'<'}
									nextLabel={'>'}
									breakLabel={'...'}
									pageCount={blogMeta?.totalPages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={(page) => setPage(page.selected + 1)}
									containerClassName={'pagination justify-content-center custom-paginate'}
									pageClassName={'page-item'}
									pageLinkClassName={'page-link'}
									previousClassName={'page-item'}
									previousLinkClassName={'page-link'}
									nextClassName={'page-item'}
									nextLinkClassName={'page-link'}
									breakClassName={'page-item'}
									breakLinkClassName={'page-link'}
									activeClassName={'active'}
								/>
							)}
						</div>
						<div className="col-md-3 col-sm-3 col-12">
							<BlogLatestNews data={recentBlog} />
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Blog;
