import React from 'react';
import './ServicesFaq.scss';
import './ServicesFaqResponsive.scss';
import faqBg from 'assets/images/common-image/services/faq.jpg';
import { useSelector } from 'react-redux';

const ServicesFaq = () => {
	const serviceContent = useSelector((state) => state.content?.service);
	const serviceData = serviceContent?.content ? JSON.parse(serviceContent?.content) : '';

	return (
		<>
			<section className="servicesFaq">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12">
							<div className="servicesFaqTitle text-center">
								<h2
									dangerouslySetInnerHTML={{
										__html: serviceData?.section6?.heading ?? `Frequently Ask Questions`,
									}}
								></h2>
								<p
									dangerouslySetInnerHTML={{
										__html:
											serviceData?.section6?.description ??
											`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis `,
									}}
								></p>
							</div>
						</div>
						<div className="col-6">
							<div className="accordion faqAccordion" id="myAccordion">
								{serviceData?.section6?.faq?.map((val, i) => (
									<div className="accordion-item" key={`faqAccordion-${i}`}>
										<h2 className="accordion-header" id={`headingOne${i}`}>
											<button
												type="button"
												className={`accordion-button ${i === 1 ? 'collapsed' : ''}`}
												data-bs-toggle="collapse"
												data-bs-target={`#collapseOne${i}`}
											>
												{val?.question}
											</button>
										</h2>
										<div id={`collapseOne${i}`} className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`} data-bs-parent="#myAccordion">
											<div className="card-body">
												<p>{val?.answer}</p>
											</div>
										</div>
									</div>
								))}
								{/* <div className="accordion-item">
									<h2 className="accordion-header" id="headingOne">
										<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
											Lorem ipsum dolor sit amet, consectetur?
										</button>
									</h2>
									<div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
										<div className="card-body">
											<p>HTML stands for HyperText Markup Language. HTML is the standard markup language for describing the structure of web pages.</p>
										</div>
									</div>
								</div> */}
								{/* <div className="accordion-item">
									<h2 className="accordion-header" id="headingTwo">
										<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
											Lorem ipsum dolor sit amet, consectetur dolor ?
										</button>
									</h2>
									<div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#myAccordion">
										<div className="card-body">
											<p>
												Bootstrap is a sleek, intuitive, and powerful front-end framework for faster and easier web development. It is a collection of CSS
												and HTML conventions.
											</p>
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<h2 className="accordion-header" id="headingThree">
										<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
											Lorem ipsum dolor sit amet, consectetur?
										</button>
									</h2>
									<div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
										<div className="card-body">
											<p>
												CSS stands for Cascading Style Sheet. CSS allows you to specify various style properties for a given HTML element such as colors,
												backgrounds, fonts etc.
											</p>
										</div>
									</div>
								</div>

								<div className="accordion-item">
									<h2 className="accordion-header" id="headingFour">
										<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
											Lorem ipsum dolor sit amet, consectetur?
										</button>
									</h2>
									<div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
										<div className="card-body">
											<p>
												CSS stands for Cascading Style Sheet. CSS allows you to specify various style properties for a given HTML element such as colors,
												backgrounds, fonts etc.
											</p>
										</div>
									</div>
								</div>

								<div className="accordion-item">
									<h2 className="accordion-header" id="headingFive">
										<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">
											Lorem ipsum dolor sit amet, consectetur?
										</button>
									</h2>
									<div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
										<div className="card-body">
											<p>
												CSS stands for Cascading Style Sheet. CSS allows you to specify various style properties for a given HTML element such as colors,
												backgrounds, fonts etc.
											</p>
										</div>
									</div>
								</div>

								<div className="accordion-item">
									<h2 className="accordion-header" id="headingSix">
										<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">
											Lorem ipsum dolor sit amet, consectetur?
										</button>
									</h2>
									<div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
										<div className="card-body">
											<p>
												CSS stands for Cascading Style Sheet. CSS allows you to specify various style properties for a given HTML element such as colors,
												backgrounds, fonts etc.
											</p>
										</div>
									</div>
								</div> */}
							</div>
						</div>
						<div className="col-6">
							<div className="imgContainer">
								<img src={serviceData?.section6?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${serviceData?.section6?.img}` : faqBg} alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ServicesFaq;
