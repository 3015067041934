import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';

import { addContentService, getContentService } from './Settings.service.js';

const Settings = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const [content, setContent] = useState({});
	const [clutchImg, setClutchImg] = useState('');
	const [googleImg, setGoogleImg] = useState('');
	const [trustpilotImg, setTrustpilotImg] = useState('');
	const [isoImg, setIsoImg] = useState('');
	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.emailId = content?.content?.emailId;
		defaultValues.facebookId = content?.content?.facebookId;
		defaultValues.phone = content?.content?.phone;
		defaultValues.linkedinId = content?.content?.linkedinId;
		defaultValues.skypeId = content?.content?.skypeId;
		defaultValues.twitterId = content?.content?.twitterId;
		defaultValues.wpNo = content?.content?.wpNo;
		defaultValues.announcementStatus = content?.content?.announcementStatus;
		defaultValues.hiringStatus = content?.content?.hiringStatus;

		defaultValues.clutchLink = content?.content?.clutchLink;
		defaultValues.googleLink = content?.content?.googleLink;
		defaultValues.trustpilotLink = content?.content?.trustpilotLink;
		defaultValues.isoLink = content?.content?.isoLink;

		reset({ ...defaultValues });

		if (content?.content?.clutchImg) {
			setClutchImg(`${process.env.REACT_APP_RESOURCE_URL}/${content?.content?.clutchImg}`);
		}
		if (content?.content?.googleImg) {
			setGoogleImg(`${process.env.REACT_APP_RESOURCE_URL}/${content?.content?.googleImg}`);
		}
		if (content?.content?.trustpilotImg) {
			setTrustpilotImg(`${process.env.REACT_APP_RESOURCE_URL}/${content?.content?.trustpilotImg}`);
		}
		if (content?.content?.isoImg) {
			setIsoImg(`${process.env.REACT_APP_RESOURCE_URL}/${content?.content?.isoImg}`);
		}
	};

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			console.log(result?.data?.data);
			setContent(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
	};

	const onSubmit = async (data) => {
		if (!clutchImg || !googleImg || !trustpilotImg || !isoImg) {
			toast.error(`Please select all images !`);
			return false;
		}
		const formData = {
			...data,
			clutchImg,
			googleImg,
			trustpilotImg,
			isoImg,
		};
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const handleClutchImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setClutchImg(img);
		}
	};
	const handleGoogleImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setGoogleImg(img);
		}
	};
	const handleTrustpilotImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setTrustpilotImg(img);
		}
	};
	const handleIsoImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setIsoImg(img);
		}
	};
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Settings' },
				]}
				title="Settings Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row g-3">
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Skype Id:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Skype Id" rows={5} {...register('skypeId', { required: 'Skype Id is required' })} />
								{errors.skypeId && <InputErrorMsg error={errors.skypeId?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Email Id:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Email Id" rows={5} {...register('emailId', { required: 'Email Id is required' })} />
								{errors.emailId && <InputErrorMsg error={errors.emailId?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Phone Number:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Phone Number" rows={5} {...register('phone', { required: 'Phone Number is required' })} />
								{errors.phone && <InputErrorMsg error={errors.phone?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Facebook Id:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Facebook Id" rows={5} {...register('facebookId')} />
								{errors.facebookId && <InputErrorMsg error={errors.facebookId?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Twitter Id:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Twitter Id" rows={5} {...register('twitterId')} />
								{errors.twitterId && <InputErrorMsg error={errors.twitterId?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Linkedin Id:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Linkedin Id" rows={5} {...register('linkedinId')} />
								{errors.linkedinId && <InputErrorMsg error={errors.linkedinId?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">WhatsApp Number:</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control"
									placeholder="WhatsApp Number"
									rows={5}
									{...register('wpNo', { required: 'WhatsApp Number is required' })}
								/>
								{errors.wpNo && <InputErrorMsg error={errors.wpNo?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Announcement Status:</label>
							<div className="admim-select-wrap">
								<select
									className="form-select"
									{...register('announcementStatus', {
										required: 'Status is required',
									})}
								>
									<option value="1">Active</option>
									<option value="0">Inactive</option>
								</select>
								{errors.announcementStatus && <InputErrorMsg error={errors.announcementStatus?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Hiring Status:</label>
							<div className="admim-select-wrap">
								<select
									className="form-select"
									{...register('hiringStatus', {
										required: 'Status is required',
									})}
								>
									<option value="1">Active</option>
									<option value="0">Inactive</option>
								</select>
								{errors.hiringStatus && <InputErrorMsg error={errors.hiringStatus?.message} />}
							</div>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-3">
							<div className="upload-img-box">
								<label htmlFor="">Clucth Logo</label>
								<div className="upload-img-wrap">
									<input type="file" id="img" onChange={handleClutchImg} />
									<label htmlFor="img">
										{!clutchImg && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Logo...</p>
											</div>
										)}
										{clutchImg && (
											<div className="preview-img">
												<img src={clutchImg} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
							<div className="">
								<label className="admin-lbl">Clutch Link:</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control"
										placeholder="Clutch Link"
										rows={5}
										{...register('clutchLink', { required: 'Clutch Link is required' })}
									/>
									{errors.clutchLink && <InputErrorMsg error={errors.clutchLink?.message} />}
								</div>
							</div>
						</div>
						<div className="col-3">
							<div className="upload-img-box">
								<label htmlFor="">Google Logo</label>
								<div className="upload-img-wrap">
									<input type="file" id="img2" onChange={handleGoogleImg} />
									<label htmlFor="img2">
										{!googleImg && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Logo...</p>
											</div>
										)}
										{googleImg && (
											<div className="preview-img">
												<img src={googleImg} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
							<div className="">
								<label className="admin-lbl">Google Link:</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control"
										placeholder="Google Link"
										rows={5}
										{...register('googleLink', { required: 'Google Link is required' })}
									/>
									{errors.googleLink && <InputErrorMsg error={errors.googleLink?.message} />}
								</div>
							</div>
						</div>
						<div className="col-3">
							<div className="upload-img-box">
								<label htmlFor="">Trustpilot Logo</label>
								<div className="upload-img-wrap">
									<input type="file" id="img3" onChange={handleTrustpilotImg} />
									<label htmlFor="img3">
										{!trustpilotImg && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Logo...</p>
											</div>
										)}
										{trustpilotImg && (
											<div className="preview-img">
												<img src={trustpilotImg} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
							<div className="">
								<label className="admin-lbl">Trustpilot Link:</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control"
										placeholder="Trustpilot Link"
										rows={5}
										{...register('trustpilotLink', { required: 'Trustpilot Link is required' })}
									/>
									{errors.trustpilotLink && <InputErrorMsg error={errors.trustpilotLink?.message} />}
								</div>
							</div>
						</div>
						<div className="col-3">
							<div className="upload-img-box">
								<label htmlFor="">Iso Logo</label>
								<div className="upload-img-wrap">
									<input type="file" id="img4" onChange={handleIsoImg} />
									<label htmlFor="img4">
										{!isoImg && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload Logo...</p>
											</div>
										)}
										{isoImg && (
											<div className="preview-img">
												<img src={isoImg} className="img-block" alt="logo" />
											</div>
										)}
									</label>
								</div>
							</div>
							<div className="">
								<label className="admin-lbl">Iso Link:</label>
								<div className="admim-input-wrap">
									<input type="text" className="form-control" placeholder="Iso Link" rows={5} {...register('isoLink', { required: 'Iso Link is required' })} />
									{errors.isoLink && <InputErrorMsg error={errors.isoLink?.message} />}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default Settings;
