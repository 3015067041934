import React from 'react';
import './DevServOne.scss';
import './DevServOneponsive.scss';
import heroOne from 'assets/images/common-image/dev-serv/1.png';

const DevServOne = ({ content }) => {
	return (
		<>
			<section className="devServOneSec d-flex align-items-center relative">
				<div className="devServOneSecCont">
					<div
						className="devServOneSecContInner"
						dangerouslySetInnerHTML={{
							__html: content?.description ?? `Web Development`,
						}}
					>
						{/* <div className="dsolBox">
							<h4>Lorem ipsum dolor sit amet</h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
								ultrices gravida.{' '}
							</p>
						</div>
						<div className="dsolBox">
							<h4>Lorem ipsum dolor sit amet</h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
								ultrices gravida.{' '}
							</p>
						</div>
						<div className="dsolBox">
							<h4>Lorem ipsum dolor sit amet</h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
								ultrices gravida.{' '}
							</p>
						</div> */}
					</div>
				</div>
				<div className="devServOneSecImg">
					<div className="devServOneSecImgInner">
						<img src={content?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.img}` : heroOne} alt="" />
					</div>
				</div>
			</section>
		</>
	);
};

export default DevServOne;
