import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LetsTalk from 'components/LetsTalk';
import PortfolioBanner from 'components/PortfolioBanner';
import PortfolioOverview from 'components/PortfolioOverview';
import PortfolioClientSay from 'components/PortfolioClientSay';
import Projects from 'components/Projects';
import PortfolioReliableApp from 'components/PortfolioReliableApp';
import PortfolioTechnologis from 'components/PortfolioTechnologis/PortfolioTechnologis';
import PortfolioWhatWeDid from 'components/PortfolioWhatWeDid';
import HomeSuccessProduct from 'components/HomeSuccessProduct';
import TheProblem from 'components/TheProblem';
import { useParams } from 'react-router-dom';
import { getContentService } from './PortfolioDetails.service.js';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Helmet } from 'react-helmet';

const PortfolioDetails = () => {
	const { slug } = useParams();

	const dispatch = useDispatch();
	const portfolioDtlContent = useSelector((state) => state.content?.portfolioDetail);
	const portfolioDtlData = portfolioDtlContent?.portfolioDetailData?.content ? JSON.parse(portfolioDtlContent?.portfolioDetailData?.content) : '';
	const clientSayData = {
		avatar: portfolioDtlData?.clientSay?.avatar,
		clientDesignation: portfolioDtlData?.clientSay?.clientDesignation,
		clientName: portfolioDtlData?.clientSay?.clientName,
		description: portfolioDtlData?.clientSay?.description,
		heading: portfolioDtlData?.clientSay?.heading,
		logo: portfolioDtlData?.clientSay?.logo,
	}

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, []);

	const getContent = async () => {
		const result = await getContentService(slug);
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setPortfolioDetailContent(result?.data?.data));
		} else {
			toast.error(result.data.error);
		}
	};
	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<PortfolioBanner />
			<PortfolioTechnologis />
			<PortfolioOverview />
			<Projects />
			<TheProblem />
			<PortfolioReliableApp />
			<PortfolioWhatWeDid />
			<PortfolioClientSay data={clientSayData} />
			<HomeSuccessProduct />
			<Footer />
			<LetsTalk />
		</>
	);
};

export default PortfolioDetails;
