import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from 'react-redux';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarouselRtl from 'react-owl-carousel-rtl';
import './HomeBanner.scss';
import './HomeBanner.responsive.scss';
import qImage from 'assets/images/common-image/q.svg';
import mobBan from 'assets/images/common-image/home-mobile-ban.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function HomeBanner() {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const homeContent = useSelector((state) => state.content?.home);
	const homeData = homeContent?.homeData?.content ? JSON.parse(homeContent?.homeData?.content) : '';

	return (
		<>
			<section className="banner d-flex justify-content-between align-items-center mobNone">
				<div className="bannerLeft">
					<h6>Introducingh</h6>
					<h2 dangerouslySetInnerHTML={{ __html: homeData?.banner?.title ?? `Hire a developer and monetize your business with <span>AquaLeaf IT Solutions</span>` }}></h2>
					<p
						dangerouslySetInnerHTML={{
							__html:
								homeData?.banner?.description ??
								`Bring your go-to-market teams together to deliver personalized customer experiences in real time – so you can increase your revenue, shorten your sales
						cycles, and strengthen your brand.`,
						}}
					></p>
				</div>
				<div className="bannerRight">
					<div className="qSvg">
						<img src={homeData?.banner?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${homeData?.banner?.img}` : qImage} alt="" />
					</div>
				</div>
			</section>
			<section className="homeBanMobArea">
				<img src={homeData?.banner?.mobileImg ? `${process.env.REACT_APP_RESOURCE_URL}/${homeData?.banner?.mobileImg}` : mobBan} alt="" />
				<div className="bannerLeft">
					<h6>Introducingh</h6>
					<h2 dangerouslySetInnerHTML={{ __html: homeData?.banner?.title ?? `Hire a developer and monetize your business with <span>AquaLeaf IT Solutions</span>` }}></h2>
					<p
						dangerouslySetInnerHTML={{
							__html:
								homeData?.banner?.description ??
								`Bring your go-to-market teams together to deliver personalized customer experiences in real time – so you can increase your revenue, shorten your sales
						cycles, and strengthen your brand.`,
						}}
					></p>
				</div>
			</section>
		</>
	);
}

export default HomeBanner;
