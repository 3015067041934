import React from 'react';
import './Footer.scss';
function Footer() {
	return (
		<>
			<footer className="admin-footer">
				<div className="container-fluid">
					<div className="row text-muted">
						<div className="col-lg-6 col-12 text-start">
							<div className="ftr-copyrights-text">
								<p className="mb-0">
									<a className="text-muted" href="#" target="_blank">
										<strong>© 2022 by Hassad. </strong>Designed by
									</a>{' '}
									-{' '}
									<a className="text-muted" href="#" target="_blank">
										<strong>Aqualeaf It Solution Pvt. Ltd.</strong>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
