import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import './AdminLayout.scss';
import './AdminLayout.responsive.scss';
import Sidebar from 'components/Admin/Sidebar';
import Header from 'components/Admin/Header';
import Footer from 'components/Admin/Footer';
import { getAuthUser } from './AdminLayout.service.js';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import NoImage from 'assets/images/avatar.png';

function DashboardLayout() {
	const [redirect, setRedirect] = useState(0);
	const dispatch = useDispatch();
	useEffect(() => {
		const auth_token = localStorage.getItem('auth_token');
		if (!auth_token) {
			setRedirect(1);
		}
		getAuthUser().then((res) => {
			if (res.data.status === 200) {
				const user = res?.data?.data?.user;
				if (user?.role !== 1) {
					setRedirect(1);
				}
				dispatch(
					allActions.authAction.setAuthUser({
						id: user.id,
						role: user.role,
						avatar: user?.avatar ? user.avatar : NoImage,
						name: user.name,
					})
				);
			} else {
				setRedirect(1);
			}
		});
		document.body.dir = 'ltr';
	}, []);

	if (redirect) {
		return <Navigate to="/" replace />;
	}
	return (
		<>
			<div className="wrapper">
				<Sidebar />
				<div className="main">
					<Header />
					<main className="content">
						<div className="container-fluid p-0">
							<Outlet />
						</div>
					</main>
					<Footer />
				</div>
			</div>
		</>
	);
}

export default DashboardLayout;
