import React, { useEffect, useState } from 'react';

import logo from 'assets/images/common-image/logo.svg';
import qImage from 'assets/images/common-image/q.svg';
import menuIconOne from 'assets/images/common-image/menu-icon-1.svg';

import $ from 'jquery';
// import i18next, { changeLanguage } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import allActions from 'redux/actions';
// import LoadingBar from 'react-top-loading-bar'
import { Link, NavLink } from 'react-router-dom';

import { getContentService } from './Header.service.js';
import { useTranslation } from 'react-i18next';
// import { values } from 'lodash';
import { MdOutlineArrowDropDown } from 'react-icons/md';

import { RxEnvelopeClosed } from 'react-icons/rx';
import { SlSocialSkype } from 'react-icons/sl';
import { BsTelephone } from 'react-icons/bs';

import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

import './Header.scss';
import './Header.responsive.scss';
import { toast } from 'react-toastify';

const languageMap = {
	en: { label: 'English', dir: 'ltr', active: true, value: 'en' },
	fr: { label: 'Français', dir: 'ltr', active: false, value: 'fr' },
	ar: { label: 'العربية', dir: 'rtl', active: false, value: 'ar' },
};

function Header() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const selected = localStorage.getItem('i18nextLng').split('-')[0] || 'en';
	const [langSeleted, setLangSelected] = useState(selected);
	const auth = useSelector((state) => state.auth);
	const [animate, setAnimate] = useState(0);
	const [services, setServices] = useState([]);
	const [industries, setIndustries] = useState([]);

	const lang = useSelector((state) => state.lang);
	const path = window.location.pathname.split('/').pop();

	const settings = useSelector((state) => state.content.settings);
	const settingsContent = settings?.content ? JSON.parse(settings?.content) : {};
	// console.log(settingsContent);

	useEffect(() => {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 200) {
				$('header').addClass('sticky');
			} else {
				$('header').removeClass('sticky');
			}
		});

		document.body.dir = languageMap[langSeleted].dir;
		document.body.className = `style-${languageMap[langSeleted].dir}`;
		dispatch(allActions.langAction.changeLanguage(languageMap[langSeleted].value));
	}, [langSeleted]);

	useEffect(() => {
		getContent();
	}, []);

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setSettingsContent(result?.data?.data?.settingsData));
			setServices(result?.data?.data?.serviceData);
			setIndustries(result?.data?.data?.industryData);
		} else {
			toast.error(result.data.error);
		}
	};

	const handleClick = () => {
		setAnimate(!animate);
	};
	const slideToggle = () => {
		$('.subMenuArea').slideToggle();
	};

	return (
		<>
			<header className={'mainHeader'}>
				<div className="topHeader">
					<div className="container-fluid p-h-40">
						<div className="row justify-content-between align-items-center">
							<div className="col-auto">
								<ul className="d-flex adressPhone">
									<li>
										<Link to={'#'}>
											<SlSocialSkype /> {settingsContent?.skypeId}
										</Link>
									</li>
									<li>
										<Link to={'#'}>
											<RxEnvelopeClosed /> {settingsContent?.emailId}
										</Link>
									</li>
									<li>
										<Link to={'#'}>
											<BsTelephone /> {settingsContent?.phone}
										</Link>
									</li>
								</ul>
							</div>
							<div className="col-auto">
								<ul className="d-flex social">
									<li className="findUs">Find us on :</li>
									<li>
										<a href={`${settingsContent?.facebookId}`} target="_blank" rel="noreferrer">
											<FaFacebook />
										</a>
									</li>
									<li>
										<a href={`${settingsContent?.twitterId}`} target="_blank" rel="noreferrer">
											<FaTwitter />
										</a>
									</li>
									<li>
										<a href={`${settingsContent?.linkedinId}`} target="_blank" rel="noreferrer">
											<FaLinkedin />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="headerInner">
					<div className="container-fluid p-h-40">
						<div className="row justify-content-between align-items-center">
							<div className="col-auto d-flex">
								<div className="logo">
									<Link to={'/home'}>
										<img src={logo} alt="" />
									</Link>
								</div>
							</div>
							<div className="col-auto d-flex align-items-center">
								<button type="button" className="humbergar" onClick={handleClick}>
									<span></span>
									<span></span>
									<span></span>
								</button>

								<nav className={'navigation mobHide'}>
									<div className="navInner">
										<ul>
											<li>
												<Link to="#">
													Services <MdOutlineArrowDropDown />
												</Link>
												<div className="subMenu">
													<div className="container-fluid">
														<div className="row">
															<div className="col-md-3 menu-left d-flex align-items-center mobHide">
																<div className="subMenuLogo">
																	<img src={qImage} alt="" />
																</div>
															</div>
															<div className="col-md-6 pt-4">
																<div className="row no-gutters">
																	{services?.map((val, i) => (
																		<div className="col-6" key={`services-${i}`}>
																			<Link className="dropdown-item" to={`/service/${val?.slug}`}>
																				<img alt="" className src={`${process.env.REACT_APP_RESOURCE_URL}/${val?.logo}`} />
																				<span>
																					{val?.serviceName}
																					<small>{val?.ShortDesc}</small>
																				</span>
																			</Link>
																		</div>
																	))}
																</div>
															</div>

															<div className="col-md-3 py-4 menu-right d-flex align-items-start flex-column justify-content-start mobHide">
																<h3>How we Work?</h3>
																<ul className="ql">
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Discovery <small>Explore a wide-range of projects</small>
																			</span>
																		</div>
																	</li>

																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Development <small>Agile Working Methodologies.</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Testing
																				<small>Rigorous Testing &amp; QA</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Deployment
																				<small>Seamless Launch of Product.</small>
																			</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</li>
											<li>
												<Link to="#">
													Industries <MdOutlineArrowDropDown />
												</Link>
												<div className="subMenu">
													<div className="container-fluid">
														<div className="row">
															<div className="col-md-3 menu-left d-flex align-items-center mobHide">
																<div className="subMenuLogo">
																	<img src={qImage} alt="" />
																</div>
															</div>
															<div className="col-md-6 pt-4">
																<div className="row no-gutters">
																	{industries?.map((val, i) => (
																		<div className="col-6" key={`industries-${i}`}>
																			<Link className="dropdown-item" to={`/industry/${val?.slug}`}>
																				<img alt="" className src={`${process.env.REACT_APP_RESOURCE_URL}/${val?.logo}`} />
																				<span>
																					{val?.industryName}
																					<small>{val?.ShortDesc}</small>
																				</span>
																			</Link>
																		</div>
																	))}
																	{/* <div className="col-6">
																		<Link className="dropdown-item" to={`/industry/test`}>
																			<span>
																				test
																				<small>test</small>
																			</span>
																		</Link>
																	</div> */}
																</div>
															</div>

															<div className="col-md-3 py-4 menu-right d-flex align-items-start flex-column justify-content-start mobHide">
																<h3>How we Work?</h3>
																<ul className="ql">
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Discovery <small>Explore a wide-range of projects</small>
																			</span>
																		</div>
																	</li>

																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Development <small>Agile Working Methodologies.</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Testing
																				<small>Rigorous Testing &amp; QA</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Deployment
																				<small>Seamless Launch of Product.</small>
																			</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</li>
											<li>
												<Link to="#" className={(path === 'how-we-work' || path === 'About') && 'active'}>
													About <MdOutlineArrowDropDown />
												</Link>
												<div className="subMenu">
													<div className="container-fluid">
														<div className="row">
															<div className="col-md-3 menu-left d-flex align-items-center">
																<div className="subMenuLogo">
																	<img src={qImage} alt="" />
																</div>
															</div>
															<div className="col-md-6 pt-4">
																<div className="row no-gutters">
																	<div className="col-6">
																		<Link className="dropdown-item" to="/how-we-work">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				How We Work
																				<small>Best Game App Developers.</small>
																			</span>
																		</Link>
																		<Link className="dropdown-item" to="/testimonial">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Testimonial
																				<small>Best Game App Developers.</small>
																			</span>
																		</Link>
																	</div>
																	<div className="col-6">
																		<Link className="dropdown-item" to="/about">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Who We Are
																				<small>Best Game App Developers.</small>
																			</span>
																		</Link>
																		<Link className="dropdown-item" to="/blog">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				Blog
																				<small>Best Game App Developers.</small>
																			</span>
																		</Link>
																	</div>
																</div>
															</div>
															<div className="col-md-3 py-4 menu-right d-flex align-items-start flex-column justify-content-start">
																<h3>How we Work?</h3>
																<ul className="ql">
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				10+ <small>Years of Experience</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				600+ <small>Employees</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				700+ <small>Projects</small>
																			</span>
																		</div>
																	</li>
																	<li>
																		<div className="dropdown-item">
																			<img alt="" className src={menuIconOne} />
																			<span>
																				500+
																				<small>Clients</small>
																			</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</li>
											<li>
												<NavLink to="/portfolio">Portfolio</NavLink>
											</li>
											<li>
												<NavLink to="/career">Careers</NavLink>
											</li>
											{/* <li>
												<Link to="/">Blog</Link>
											</li> */}
											<li>
												<NavLink to="/contact">Get in Touch</NavLink>
											</li>
										</ul>
									</div>
									<Link to="/" className="navClose"></Link>
								</nav>
								<div className="bookCall">
									<a className="bookCallBtn" target={'_blank'} href={'https://calendly.com/aqualeafitsol/30min'} rel="noreferrer">
										Book a call
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* className={animate ? 'consultationHolder active' : 'consultationHolder'}
				<div className="for-mobile active"> */}
				<div className={animate ? 'for-mobile active' : 'for-mobile'}>
					<button className="mobile-menu-btn" onClick={handleClick}>
						<i className="fas fa-times"></i>
					</button>
					<div className="mobile-menu">
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="#" className="subMenuBtn" onClick={slideToggle}>
									Services <MdOutlineArrowDropDown />
								</Link>
								<ul className="subMenuArea" style={{ display: 'none' }}>
									{services?.map((val, i) => (
										<li key={`mobile-service-${i}`}>
											<Link to={`/service/${val?.slug}`}>{val?.serviceName}</Link>
										</li>
									))}
								</ul>
							</li>
							<li>
								<Link to="/about">About</Link>
							</li>
							<li>
								<Link to="/portfolio">Portfolio</Link>
							</li>
							<li>
								<Link to="/career">Career</Link>
							</li>
							<li>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
					<div className="socialAdderss">
						<ul className="">
							<li>
								<Link to={'/'}>
									<SlSocialSkype /> Skype33
								</Link>
							</li>
							<li>
								<Link to={'/'}>
									<RxEnvelopeClosed /> info@gmail.com
								</Link>
							</li>
							<li>
								<Link to={'/'}>
									<BsTelephone /> 1234567890
								</Link>
							</li>
						</ul>
					</div>
					<div className="socialLink">
						<ul className="d-flex">
							<li>
								<Link to={'/'}>
									<FaFacebook />
								</Link>
							</li>
							<li>
								<Link to={'/'}>
									<FaTwitter />
								</Link>
							</li>
							<li>
								<Link to={'/'}>
									<FaLinkedin />
								</Link>
							</li>
						</ul>
					</div>
					<div className="socialLink">
						<a href={'https://calendly.com/aqualeafitsol/30min'} target={'_blank'} className="scheduleMeeting" rel="noreferrer">
							Schedule a Meeting
						</a>
					</div>
				</div>
			</header>
		</>
	);
}

export default Header;
