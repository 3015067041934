import React from 'react';
import './Footer.scss';
import './Footer.responsive.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import qImage from 'assets/images/common-image/q.svg';

import { BsEnvelopeFill } from 'react-icons/bs';
import { MdPhone } from 'react-icons/md';
import { useSelector } from 'react-redux';

function Footer() {
	const { t } = useTranslation();
	const settings = useSelector((state) => state.content.settings);
	const settingsContent = settings?.content ? JSON.parse(settings?.content) : {};

	return (
		<>
			<footer>
				<div className="footerTop">
					<div className="container-fluid p-h-40">
						<div className="row justify-content-between">
							<div className="col-lg-3 col-md-3 col-sm-4 col-12 mobHide">
								<div className="footerBoxLogo">
									<img src={qImage} alt="" />
									<ul className="d-flex">
										<li>
											<a href={`${settingsContent?.facebookId}`} target="_blank" rel="noreferrer">
												<i className="fab fa-facebook"></i>
											</a>
										</li>
										<li>
											<a href={`${settingsContent?.twitterId}`} target="_blank" rel="noreferrer">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href={`${settingsContent?.linkedinId}`} target="_blank" rel="noreferrer">
												<i className="fab fa-linkedin"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-md-3 col-sm-4 col-6">
								<div className="footerBox">
									<h5>Services</h5>
									<ul>
										<li>
											<Link to="">Hire PHP Developers</Link>
										</li>
										<li>
											<Link to="">Hire CodeIgniter Developers</Link>
										</li>
										<li>
											<Link to="">Hire Symfony Developers</Link>
										</li>
										<li>
											<Link to="">Hire Python Developers</Link>
										</li>
										<li>
											<Link to="">Hire Django Developers</Link>
										</li>
										<li>
											<Link to="">Hire PHP Developers</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-4 col-6">
								<div className="footerBox">
									<h5>Pages</h5>
									<ul>
										<li>
											<Link to="/about">About us</Link>
										</li>
										<li>
											<Link to="/how-we-work">How we work</Link>
										</li>
										<li>
											<Link to="/portfolio">Case Studies</Link>
										</li>
										<li>
											<Link to="/career">Career</Link>
										</li>
										<li>
											<Link to="/blog">Blog</Link>
										</li>
										<li>
											<Link to="/contact">Get in Touch</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-12">
								<div className="footerBoxContact">
									<h5>Contact Us</h5>
									<ul>
										<li>
											<Link to="">
												<BsEnvelopeFill /> {settingsContent?.emailId}
											</Link>
										</li>
										<li>
											<Link to="">
												<MdPhone /> {settingsContent?.phone}
											</Link>
										</li>
									</ul>
									<p>
										GoodCore Software Ltd. Airport House, Purley Way, Croydon CR0 0XZ Registered in England and Wales Company No. 11461678, VAT Reg. No. 303
										2679 24{' '}
									</p>
									<p>Based out of the UK. Providing software development services and support to clients all over the world.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footerBtm">
					<div className="container-fluid ph-40">
						<div className="row justify-content-between">
							<div className="col">
								<ul className="d-flex flex-wrap">
									<li>
										<Link to="">Terms & Conditions</Link>
									</li>
									<li>
										<Link to="">Cookies Policy</Link>
									</li>
									<li>
										<Link to="">Privacy Policy</Link>
									</li>
									<li>
										<Link to="">Recruitment Policy</Link>
									</li>
									<li>
										<Link to="">Information Security Policy</Link>
									</li>
									<li>
										<Link to="">Sitemap</Link>
									</li>
								</ul>
							</div>
							<div className="col-auto">© Copy Right.{new Date().getFullYear()} . All Rights Reserved.</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
