import React from 'react';
import './WeSay.scss';
import './WeSayResponsive.scss';
import weSay from 'assets/images/common-image/about/we-say.png';
import { useDispatch, useSelector } from 'react-redux';

const WeSay = () => {
	const aboutContent = useSelector((state) => state?.content?.about);
	const content = aboutContent?.aboutData?.content ? JSON.parse(aboutContent?.aboutData?.content) : '';

	return (
		<>
			<section className="weSay">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-5 col-md-5 col-sm-4 col-12">
							<div className="ewSayImg">
								<img src={content?.weTalk?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.weTalk?.img}` : weSay} alt="" />
							</div>
						</div>
						<div className="col-lg-7 col-md-7 col-sm-8 col-12">
							<div className="weSayContent">
								<h3
									dangerouslySetInnerHTML={{
										__html:
											content?.weTalk?.description ??
											`“Pixated's work has generated a big increase in our online conversions. We are particularly impressed with their professionalism and knowledge.”`,
									}}
								></h3>
								<p dangerouslySetInnerHTML={{ __html: content?.weTalk?.ceoName ?? `Barnaby Hughes, CEO at Actiph Water` }}></p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default WeSay;
