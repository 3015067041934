import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';
import { addContentService, getContentService } from './HowWeWorkContent.service.js';

const HowWeWorkContent = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'steps',
	});
	const [bannerImg, setBannerImg] = useState('');
	const [stepImg, setStepImg] = useState([]);
	const [reRender, setReRender] = useState(0);
	const [content, setContent] = useState({});

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.bannerTitle = content?.content?.banner?.title;
		defaultValues.bannerDescription = content?.content?.banner?.description;
		defaultValues.bannerLink = content?.content?.banner?.link;

		const stepArr = [];
		const stepImgArr = [];
		content?.content?.steps.map((val, i) => {
			stepArr.push({
				title: val?.title,
				description: val?.description,
				link: val?.link,
			});
			stepImgArr.push(`${content?.fileBaseUrl}/uploads/${val?.img}`);
		});

		if (stepArr.length > 0) {
			defaultValues.steps = stepArr;
		} else {
			defaultValues.steps = [1, 2, 3, 4, 5, 6];
		}

		await reset({ ...defaultValues });

		if (content?.content?.banner?.img) {
			setBannerImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.img}`);
		}
		if (stepImgArr) {
			setStepImg(stepImgArr);
		}
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	const handleStepImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			stepImg[index] = img;
			setStepImg([...stepImg]);
			setReRender(!reRender);
		}
	};

	const onSubmit = async (data) => {
		const img = stepImg.filter(function (element) {
			return element !== undefined;
		});
		if (!bannerImg || img.length !== data?.steps.length) {
			toast.error(`Please select all image`);
			return false;
		}
		const formData = {
			...data,
			bannerImg,
			stepImg,
		};
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};
	const getContent = async () => {
		// dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			console.log(result?.data?.data);
			setContent(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
		// dispatch(allActions.loadingAction.hideLoading());
	};

	// const steps = [0, 1, 2, 3, 4, 5];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'How We Work' },
				]}
				title="How We Work Content"
			/>
			<div className="admin-white-box">
				<div className="row">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Banner Section
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<input type="file" id="content_img" onChange={handleBannerImg} />
														<label htmlFor="content_img">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Title:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Title"
														rows={5}
														{...register('bannerTitle', { required: 'Title is required' })}
													/>
													{errors.bannerTitle && <InputErrorMsg error={errors.bannerTitle?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('bannerDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Link:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Link"
														rows={5}
														{...register('bannerLink', { required: 'Link is required' })}
													/>
													{errors.bannerLink && <InputErrorMsg error={errors.bannerLink?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading2">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse2"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse2"
									>
										START IN 3 STEPS
									</button>
								</h2>
								<div id="panelsStayOpen-collapse2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading2">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												{fields.map((val, i) => {
													return (
														<div className="card">
															<h5 className="card-header">Step {`${i + 1}`}</h5>
															<div className="card-body">
																<div className="row justify-content-center">
																	<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																		<label> Logo</label>
																		<div className="upload-img-box">
																			<div className="upload-img-wrap">
																				<input type="file" id={`stepImage${i}`} onChange={(e) => handleStepImg(e, i)} />
																				<label htmlFor={`stepImage${i}`}>
																					{!stepImg?.[i] && (
																						<div className="upload-text">
																							<i className="fa fa-download"></i>
																							<p>Upload Logo...</p>
																						</div>
																					)}
																					{stepImg?.[i] && (
																						<div className="preview-img">
																							<img src={stepImg?.[i]} className="img-block" alt="product" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Title:</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control admim-input-style"
																				placeholder="Title"
																				{...register(`steps[${i}].title`, {
																					required: 'Title is required',
																				})}
																			/>
																			{errors.steps?.[i]?.title && <InputErrorMsg error={errors.steps?.[i]?.title?.message} />}
																		</div>
																	</div>
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Description:</label>
																		<div className="admim-input-wrap">
																			<textarea
																				className="form-control admim-input-style"
																				placeholder="Description"
																				rows={5}
																				{...register(`steps[${i}].description`, {
																					required: 'Description is required',
																				})}
																			></textarea>
																			{errors.steps?.[i]?.description && <InputErrorMsg error={errors.steps?.[i]?.description?.message} />}
																		</div>
																	</div>
																	<div className="col-sm-6 col-md-6 col-lg-4 col-12">
																		<label className="admin-lbl">Link:</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control"
																				placeholder="Link"
																				{...register(`steps[${i}].link`, {
																					required: 'Link is required',
																				})}
																			/>
																			{errors.steps?.[i]?.link && <InputErrorMsg error={errors.steps?.[i]?.link?.message} />}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="cmn-btn-tag-wrap">
									<button type="submit" className="cmn-btn-tag">
										Save
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default HowWeWorkContent;
