import { default as api } from 'config/frontApiConf';

export const contactUsService = async (data) => {
	try {
		let result = await api.post(`/contact-us`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getContentService = async () => {
	try {
		let result = await api.get(`/get-about-content`);
		return result;
	} catch (error) {
		return error;
	}
};
