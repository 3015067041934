const setHomeContent = (content) => {
	return { content: content, type: 'SET_HOME_CONTENT' };
};
const setHowWeWorkContent = (content) => {
	return { content: content, type: 'SET_HOW_WE_WORK_CONTENT' };
};
const setCareerContent = (content) => {
	return { content: content, type: 'SET_CAREER_CONTENT' };
};
const setAboutContent = (content) => {
	return { content: content, type: 'SET_ABOUT_CONTENT' };
};
const setPortfolioDetailContent = (content) => {
	return { content: content, type: 'SET_PORTFOLIO_DETAIL_CONTENT' };
};
const setSettingsContent = (content) => {
	return { content: content, type: 'SET_SETTINGS_CONTENT' };
};
const setSeviceContent = (content) => {
	return { content: content, type: 'SET_SERVICE_CONTENT' };
};
const setIndustryContent = (content) => {
	return { content: content, type: 'SET_INDUSTRY_CONTENT' };
};
const data = {
	setHomeContent,
	setHowWeWorkContent,
	setCareerContent,
	setAboutContent,
	setPortfolioDetailContent,
	setSettingsContent,
	setSeviceContent,
	setIndustryContent,
};
export default data;
