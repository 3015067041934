import React, { useEffect, useState } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';
import { addContentService, industryDetailService } from './EditIndustry.service.js';
import { useParams } from 'react-router-dom';

const EditIndustry = () => {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm({});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'section2Item',
	});
	const [bannerImg, setBannerImg] = useState('');
	const [bannerDisplayImg, setBannerDisplayImg] = useState('');
	const [section2Img, setSection2Img] = useState([]);
	const [iconImg, setIconImg] = useState('');
	const [reRender, setReRender] = useState(0);
	const [industryDtl, setIndustryDtl] = useState({});

	useEffect(() => {
		getIndustryDetails();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [industryDtl]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		const content = industryDtl?.content ? JSON.parse(industryDtl?.content) : {};
		console.log('content', content);

		defaultValues.bannerHeading = content?.banner?.heading;
		defaultValues.bannerDescription = content?.banner?.description;

		defaultValues.section1heading = content?.section1?.heading;
		defaultValues.section1Description = content?.section1?.description;

		defaultValues.section2heading = content?.section2?.heading;
		defaultValues.section3heading = content?.section3?.heading;
		defaultValues.section3Description = content?.section3?.description;

		const section2ItemArr = [];
		const section2ImgArr = [];
		content?.section2?.item?.map((val, i) => {
			section2ItemArr.push({
				description: val.description,
			});
			section2ImgArr.push(`${process.env.REACT_APP_RESOURCE_URL}/${val?.img}`);
			return true;
		});
		defaultValues.section2Item = section2ItemArr;

		defaultValues.industryName = industryDtl?.industryName;
		defaultValues.infoShortDesc = industryDtl?.ShortDesc;

		await reset({ ...defaultValues });

		if (content?.banner?.img) {
			setBannerImg(`${process.env.REACT_APP_RESOURCE_URL}/${content?.banner?.img}`);
		}
		if (content?.banner?.bannerDisplayImg) {
			setBannerDisplayImg(`${process.env.REACT_APP_RESOURCE_URL}/${content?.banner?.bannerDisplayImg}`);
		}
		if (section2ImgArr?.length > 0) {
			setSection2Img(section2ImgArr);
		}
		if (industryDtl?.logo) {
			setIconImg(`${process.env.REACT_APP_RESOURCE_URL}/${industryDtl?.logo}`);
		}
	};

	const getIndustryDetails = async () => {
		const result = await industryDetailService(id);
		if (result.data.status === 200) {
			setIndustryDtl(result?.data?.data?.industry);
		} else {
			toast.error(result.data.error);
		}
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const handleIconImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setIconImg(img);
		}
	};
	const handleBannerDisplayImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerDisplayImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				// console.log(baseURL);
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const handleSection2Img = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			section2Img[index] = img;
			setSection2Img([...section2Img]);
			setReRender(!reRender);
		}
	};
	const removeSection2Img = async (index) => {
		remove(index);
		setSection2Img(section2Img.filter((val, i) => i !== index));
	};
	const onSubmit = async (data) => {
		const img1 = section2Img.filter(function (element) {
			return element !== undefined;
		});
		if (!bannerImg || data?.section2Item?.length !== img1?.length || !iconImg || !bannerDisplayImg) {
			toast.error(`Please select all image`);
			return false;
		}
		let formData = {
			...data,
			section2Img,
			bannerImg,
			bannerDisplayImg,
			iconImg,
		};
		console.log('onSubmit', formData);

		const result = await addContentService(id, formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Industry' },
				]}
				title="Industry Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="accordion" id="accordionPanelsStayOpenExample">
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-banner-headingOne">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-banner"
										aria-expanded="true"
										aria-controls="panelsStayOpen-banner"
									>
										Banner
									</button>
								</h2>
								<div id="panelsStayOpen-banner" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-banner-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Background Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="bannerImg" onChange={handleBannerImg} />
														<label htmlFor="bannerImg">
															{!bannerImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerImg && (
																<div className="preview-img">
																	<img src={bannerImg} className="img-block" alt="bannerImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="upload-img-box">
													<label htmlFor="">Image</label>
													<div className="upload-img-wrap">
														<input type="file" id="bannerDisplayImg" onChange={handleBannerDisplayImg} />
														<label htmlFor="bannerDisplayImg">
															{!bannerDisplayImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload image...</p>
																</div>
															)}
															{bannerDisplayImg && (
																<div className="preview-img">
																	<img src={bannerDisplayImg} className="img-block" alt="bannerDisplayImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading :</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('bannerHeading', { required: 'Heading is required' })}
													/>
													{errors.bannerHeading && <InputErrorMsg error={errors.bannerHeading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('bannerDescription', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne"
									>
										Section 1
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section1heading', { required: 'Heading is required' })}
													/>
													{errors.section1heading && <InputErrorMsg error={errors.section1heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section1Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section1Description && <InputErrorMsg error={errors.section1Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading4">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse4"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse4"
									>
										Section 2
									</button>
								</h2>
								<div id="panelsStayOpen-collapse4" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section2heading', { required: 'Heading is required' })}
													/>
													{errors.section2heading && <InputErrorMsg error={errors.section2heading?.message} />}
												</div>
											</div>

											<div className="col-12">
												{fields.map((item, index) => (
													<div className="card" key={`fields-${index}`}>
														<div className="card-header">
															<div className="row justify-content-between align-items-center">
																<div className="col-auto">
																	<div className="list-head">item {index + 1}</div>
																</div>
																{index !== 0 && (
																	<div className="col-auto">
																		<div className="quotes-delete">
																			<button type="button" className="cmn-btn-tag" onClick={() => removeSection2Img(index)}>
																				<i className="fas fa-trash-alt"></i>
																			</button>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className="card-body">
															<div className="row justify-content-center">
																<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																	<label> Image</label>
																	<div className="upload-img-box">
																		<div className="upload-img-wrap">
																			<input type="file" id={`section2Img${index}`} onChange={(e) => handleSection2Img(e, index)} />
																			<label htmlFor={`section2Img${index}`}>
																				{!section2Img?.[index] && (
																					<div className="upload-text">
																						<i className="fa fa-download"></i>
																						<p>Upload Image...</p>
																					</div>
																				)}
																				{section2Img?.[index] && (
																					<div className="preview-img">
																						<img src={section2Img?.[index]} className="img-block" alt="product" />
																					</div>
																				)}
																			</label>
																		</div>
																	</div>
																</div>
																<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																	<label className="admin-lbl">Description</label>
																	<div className="admim-input-wrap">
																		<textarea
																			type="text"
																			rows={5}
																			className="form-control admim-input-style"
																			placeholder="Description"
																			{...register(`section2Item[${index}].description`, {
																				required: 'Description is required',
																			})}
																		/>
																		{errors?.section2Item?.[index]?.description && (
																			<InputErrorMsg error={errors?.section2Item?.[index]?.description?.message} />
																		)}
																	</div>
																</div>
															</div>
														</div>
													</div>
												))}
												<div className="cmn-btn-tag-wrap">
													<button
														type="button"
														className="cmn-btn-tag black-bg"
														onClick={() =>
															append({
																description: '',
															})
														}
													>
														Add more item
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-headingOne3">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapseOne3"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapseOne3"
									>
										Section 3
									</button>
								</h2>
								<div id="panelsStayOpen-collapseOne3" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne3">
									<div className="accordion-body">
										<div className="row">
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Heading:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Heading"
														{...register('section3heading', { required: 'Heading is required' })}
													/>
													{errors.section3heading && <InputErrorMsg error={errors.section3heading?.message} />}
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={5}
														{...register('section3Description', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.section3Description && <InputErrorMsg error={errors.section3Description?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header" id="panelsStayOpen-heading8">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#panelsStayOpen-collapse8"
										aria-expanded="true"
										aria-controls="panelsStayOpen-collapse8"
									>
										Info Section (For Header)
									</button>
								</h2>
								<div id="panelsStayOpen-collapse8" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading8">
									<div className="accordion-body">
										<div className="row">
											<div className="col-12">
												<div className="upload-img-box">
													<div className="upload-img-wrap">
														<label htmlFor="">Icon</label>
														<input type="file" id="iconImg" onChange={handleIconImg} />
														<label htmlFor="iconImg">
															{!iconImg && (
																<div className="upload-text">
																	<i className="fa fa-download"></i>
																	<p>Upload Icon...</p>
																</div>
															)}
															{iconImg && (
																<div className="preview-img">
																	<img src={iconImg} className="img-block" alt="iconImg" />
																</div>
															)}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Industry Name:</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control"
														placeholder="Industry Name"
														{...register('industryName', { required: 'Industry Name is required' })}
													/>
													{errors.industryName && <InputErrorMsg error={errors.industryName?.message} />}
												</div>
											</div>

											<div className="col-sm-6 col-md-6 col-lg-4 col-12">
												<label className="admin-lbl">Short Description:</label>
												<div className="admim-input-wrap">
													<textarea
														className="form-control"
														placeholder="Description"
														rows={3}
														{...register('infoShortDesc', {
															required: 'Description is required',
														})}
													></textarea>
													{errors.infoShortDesc && <InputErrorMsg error={errors.infoShortDesc?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default EditIndustry;
