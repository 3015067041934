import { default as api } from 'config/authApiConf.js';

export const listTestimonialService = async (page = 1) => {
	try {
		let result = await api.get(`/cms/list-testimonial?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};
