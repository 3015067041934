import React from 'react';
import { Link } from 'react-router-dom';
import './PortfolioOverview.scss';
import './PortfolioOverviewResponsive.scss';
import { useSelector, useDispatch } from 'react-redux';

const PortfolioOverview = () => {
	const portfolioDtlContent = useSelector((state) => state.content?.portfolioDetail);
	const portfolioDtlData = portfolioDtlContent?.portfolioDetailData?.content ? JSON.parse(portfolioDtlContent?.portfolioDetailData?.content) : '';

	return (
		<>
			<section className="overView">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="overViewInner relative">
								<h2 dangerouslySetInnerHTML={{ __html: portfolioDtlData?.aboutClient?.heading ?? `About the Client` }}></h2>
								<p dangerouslySetInnerHTML={{
									__html: portfolioDtlData?.aboutClient?.description ?? `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
									suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.` }}>

								</p>
							</div>
						</div>
						{/* <div className="col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="teamComposition relative">
								<h3>Team Composition</h3>
								<ul>
									<li>ReactJS TechLead and three developers</li>
									<li>Laravel/Node.js Team Lead and three developers</li>
									<li>Project Manager/Functional Analyst</li>
									<li>UIX Designer</li>
									<li>Manual QA Engineer</li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>
			</section>
		</>
	);
};

export default PortfolioOverview;
