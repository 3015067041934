import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import InputErrorMsg from 'components/InputErrorMsg';
import './Contact.scss';
import './ContactResponsive.scss';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LetsTalk from 'components/LetsTalk';
// contact-banner.png
import contactBanner from 'assets/images/common-image/contact-banner.png';
import captcha from 'assets/images/common-image/captcha.gif';
import direction from 'assets/images/common-image/direction.png';
import { BsEnvelopeFill } from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';
import { IoLogoSkype, IoLogoWhatsapp } from 'react-icons/io';
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService } from './Contact.service.js';

const Contact = () => {
	const [token, setToken] = useState('');
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.content.settings);
	const settingsContent = settings?.content ? JSON.parse(settings?.content) : {};
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());

		setTimeout(function () {
			dispatch(allActions.loadingAction.hideLoading());
		}, 2000);
	}, []);

	useMemo(() => {
		// console.log(token);
	}, [token]);

	const onSubmit = async (data) => {
		const formData = {
			...data,
			token,
		};

		const result = await getContentService(formData);
		if (result.data.status === 200) {
			toast.success('Thank your for contacting us !');
			reset();
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	return (
		<>
			{/* <Helmet>
				<script src="https://www.google.com/recaptcha/api.js?render=6LduWEwkAAAAAMiKQyuMg5FGaSXh2DIdrU49s0NB"></script>
			</Helmet> */}
			<Header />
			<section className="contactBanner">
				<img src={contactBanner} alt="contact banner img" />
			</section>
			<section className="contactFormArea">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div class="cnFrmTitle text-center">
								<h2>Little about you</h2>
								<p>For general queries fill this form or have a look at end of the page for more options.</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-12">
							<div className="address relative">
								<h4>Aqualeaf IT Solutions Private Limited </h4>
								<p>
									AD 218 , Sector 1 ,<br />
									Near AD Park, <br /> Saltlake City, <br /> Kolkata 700064, <br /> West Bengal, <br /> India.
								</p>
								<a href={'https://goo.gl/maps/WgGxuRd1ADqQzEUc7'} target="_blank" className="getDirection" rel="noreferrer">
									<img src={direction} alt="direction" />
									GET DIRECTION
								</a>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="cntForm">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<input
													id="contactName"
													type="text"
													placeholder="Name"
													className="form-control"
													{...register('name', { required: 'Name is required' })}
												/>
												<i className="fa fa-star" />
												{errors.name && <InputErrorMsg error={errors.name?.message} />}
											</div>
											<div className="form-group">
												<input
													id="ph_no"
													type="text"
													placeholder="Phone No"
													className="form-control"
													{...register('ph_no', { required: 'Phone No is required' })}
												/>
												<i className="fa fa-star" />
												{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
											</div>
											<div className="form-group">
												<input
													id="contactEmail"
													type="text"
													placeholder="Email"
													className="form-control"
													{...register('email', { required: 'Email is required' })}
												/>
												<i className="fa fa-star" />
												{errors.email && <InputErrorMsg error={errors.email?.message} />}
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>Write your message here</label>
												<textarea
													id="contactMessage"
													className="form-control"
													placeholder="Please write your message in this box"
													{...register('message', { required: 'Message is required' })}
												/>
												{/* <i className="fa fa-star" style={{ top: 50 }} /> */}
												{errors.message && <InputErrorMsg error={errors.message?.message} />}
											</div>
											<div className="form-group mb-0">
												<ul className="d-flex align-items-center justify-content-between">
													{/* <li className="captcha">
														<img src={captcha} alt="" />
													</li> */}
													<li>
														<button type="submit" className="sendBtn">
															<span>Send</span>
														</button>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-12 odr1">
							<div className="connectUs">
								<h3>Connect with us directly</h3>
								<p>You can also contact us via Email or Phone</p>
								<ul>
									<li>
										<Link to={'/'}>
											<BsEnvelopeFill /> {settingsContent?.emailId}
										</Link>
									</li>
									<li>
										<Link to={'/'}>
											<IoMdCall /> {settingsContent?.phone}
										</Link>
									</li>
									<li>
										<Link to={'/'}>
											<IoLogoSkype /> {settingsContent?.skypeId}
										</Link>
									</li>
									<li>
										<Link to={'/'}>
											<IoLogoWhatsapp /> {settingsContent?.wpNo}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="projectWork">
				<div className="container">
					<div className="row g-4">
						<div className="col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="enqCon">
								<h3>Start a project</h3>
								<p>Looking to discuss a project with us?</p>
								<Link to={'/'}>ENQUIRY</Link>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="enqCon">
								<h3>Work with us</h3>
								<p>Do you want to join our tribe?</p>
								<Link to={'/'}>CAREERS</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div id="recaptcha"></div>
			<LetsTalk />
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
				container={{
					// optional to render inside custom element
					element: 'recaptcha',
					parameters: {
						badge: 'bottomleft', // optional, default undefined
						theme: 'dark', // optional, default undefined
					},
				}}
			>
				<GoogleReCaptcha onVerify={(token) => setToken(token)} />
			</GoogleReCaptchaProvider>

			<Footer />
		</>
	);
};

export default Contact;
