import React, { useEffect, useState, useMemo, useCallback } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { toast } from 'react-toastify';

import { addContentService, getContentService } from './PortFolioBanner.service.js';

const PortFolioBanner = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const [bannerImg, setBannerImg] = useState('');
	const [backgroundImg, setBackgroundImg] = useState('');
	const [reRender, setReRender] = useState(0);
	const [content, setContent] = useState({});

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.bannerTitle = content?.content?.banner?.title;
		defaultValues.bannerDescription = content?.content?.banner?.description;

		await reset({ ...defaultValues });

		if (content?.content?.banner?.img) {
			setBannerImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.img}`);
		}
		if (content?.content?.banner?.img) {
			setBackgroundImg(`${content?.fileBaseUrl}/uploads/${content?.content?.banner?.backgroundImg}`);
		}
	};

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			console.log(result?.data?.data);
			setContent(result?.data?.data);
		} else {
			toast.error(result.data.error);
		}
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBannerImg(img);
		}
	};
	const handleBackgroundImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 1000000) {
			toast.error('Image size is too large. Image size must be less than 1 MB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setBackgroundImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	const onSubmit = async (data) => {
		if (!bannerImg || !backgroundImg) {
			toast.error(`Please select all image`);
			return false;
		}
		const formData = {
			...data,
			bannerImg,
			backgroundImg,
		};
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success('Content Saved Successfully !');
		} else {
			toast.error(result?.data?.error?.message);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: 'Content Management' },
					{ link: '#', name: 'Portfolio Banner' },
				]}
				title="Portfolio Banner Content"
			/>
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-6">
							<div className="upload-img-box">
								<label htmlFor="">Image</label>
								<div className="upload-img-wrap">
									<input type="file" id="content_img" onChange={handleBannerImg} />
									<label htmlFor="content_img">
										{!bannerImg && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload image...</p>
											</div>
										)}
										{bannerImg && (
											<div className="preview-img">
												<img src={bannerImg} className="img-block" alt="bannerImg" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="upload-img-box">
								<label htmlFor="">Background Image</label>
								<div className="upload-img-wrap">
									<input type="file" id="backgroundImg" onChange={handleBackgroundImg} />
									<label htmlFor="backgroundImg">
										{!backgroundImg && (
											<div className="upload-text">
												<i className="fa fa-download"></i>
												<p>Upload image...</p>
											</div>
										)}
										{backgroundImg && (
											<div className="preview-img">
												<img src={backgroundImg} className="img-block" alt="backgroundImg" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Title:</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control" placeholder="Title" rows={5} {...register('bannerTitle', { required: 'Title is required' })} />
								{errors.bannerTitle && <InputErrorMsg error={errors.bannerTitle?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-4 col-12">
							<label className="admin-lbl">Description:</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control"
									placeholder="Description"
									rows={5}
									{...register('bannerDescription', {
										required: 'Description is required',
									})}
								></textarea>
								{errors.bannerDescription && <InputErrorMsg error={errors.bannerDescription?.message} />}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default PortFolioBanner;
