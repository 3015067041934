import React, { useEffect, useState } from 'react';
import './Breadcrumb.scss';
import { Link } from 'react-router-dom';
function Breadcrumb(props) {
	return (
		<>
			<div className="row justify-content-between breadcrumb-title-wrap align-items-center">
				<div className="col-auto">
					<h4>{props.title}</h4>
				</div>
				<div className="col-auto">
					<ul className="d-flex breadcrumb">
						<li>
							<Link to="/admin/dashboard">Dashboard</Link>
						</li>
						{props.data.map((res, i) => {
							return (
								<li className="active" key={i}>
									<Link to={res.link}>{res.name}</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</>
	);
}

export default Breadcrumb;
