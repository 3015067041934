import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import './Dashboard.scss';
import { dashboardDtlService } from './Dashboard.service.js';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Dashboard() {
	const [dashboardDtl, setDashboardDtl] = useState([]);
	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		// getDashboardDtl();
	}, []);

	const getDashboardDtl = async () => {
		const result = await dashboardDtlService();
		if (result.data.status === 200) {
			setDashboardDtl(result.data.data);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb data={[{ link: '#', name: 'Dashboard' }]} title="Dashboard" />
			<div className="row g-3">
				{/* {auth.role === 2 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/vendor-product/list'} className="text-green">
													Total Product
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-truck" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_products']}</h1>
									
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/vendor-order/list'} className="text-green">
													Total Product Sale
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_product_sale']}</h1>
									
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/vendor-order/list'} className="text-green">
													Total Sale Amount
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_sale_amount']} MRU</h1>
									
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund-raiser/list-fund'} className="text-green">
													Total Raise Fund
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raise']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'#'} className="text-green">
													Total Donation
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_donation']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}

				{auth.role === 1 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'#'} className="text-green">
													Total Product
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-truck" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_products']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/order/list'} className="text-green">
													Total Product Sale
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_product_sale']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/order/list'} className="text-green">
													Total Sale Amount
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_sale_amount']} MRU</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/users'} className="text-green">
													Total Customer
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-users" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_customer']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/users'} className="text-green">
													Total Vendor
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-users" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_vendor']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/users'} className="text-green">
													Total Fund Raiser
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-users" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raiser']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund/raise-fund-list'} className="text-green">
													Total Raise Fund
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raise']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund/donaiton-list'} className="text-green">
													Total Donation
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_donation']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}
				{auth.role === 6 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund-raiser/list-fund'} className="text-green">
													Total Raise Fund
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raise']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'#'} className="text-green">
													Total Donation
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_donation']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}

				{auth.role === 4 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/delivery-order/list'} className="text-green">
													Total Order Assigned
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hands-helping" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_order_assigned']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/delivery-order/list'} className="text-green">
													Total Shipping Amount
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_shipping_amount']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)} */}
			</div>
		</>
	);
}

export default Dashboard;
