import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './About.scss';
import './About.responsive.scss';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import { contactUsService, getContentService } from './About.service.js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import AboutBanner from 'components/AboutBanner';
import AboutStory from 'components/AboutStory';
import HomePartner from 'components/HomePartner';
import AboutOurValue from 'components/AboutOurValue';
import WeSay from 'components/WeSay';
import { Helmet } from 'react-helmet';

function About() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const [content, setContent] = useState({});

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, []);

	const lang = useSelector((state) => state.lang);

	const getContent = async () => {
		// dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setAboutContent(result?.data?.data));
		} else {
			toast.error(result.data.error);
		}
		// dispatch(allActions.loadingAction.hideLoading());
	};

	const onSubmit = async (data) => {
		dispatch(allActions.loadingAction.showLoading());
		let result = await contactUsService(data);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			reset();
			toast.success('Thank you for your message !');
		} else {
			toast.error(result.data.error);
		}
	};
	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<AboutBanner />
			<AboutStory />
			<HomePartner />
			<AboutOurValue />
			<WeSay />
			<Footer />
		</>
	);
}

export default About;
