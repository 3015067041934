import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Footer from 'components/Footer';
import HomeBanner from 'components/HomeBanner';
import HomeVideoSection from 'components/HomeVideoSection';
import HomeCaseStudy from 'components/HomeCaseStudy';
import HomeAppSection from 'components/HomeAppSection';
// import HomeTechnology from 'components/HomeTechnology';
import HomeTestimonials from 'components/HomeTestimonials';
import HomeSuccessProduct from 'components/HomeSuccessProduct';
import HomePartner from 'components/HomePartner';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService, getHomeContentService } from './Home.service.js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import LetsTalk from 'components/LetsTalk';
import { Helmet } from 'react-helmet';

function Home() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const homeContent = useSelector((state) => state?.content?.home);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, []);

	const getContent = async () => {
		// dispatch(allActions.loadingAction.showLoading());
		const result = await getHomeContentService();
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setHomeContent(result?.data?.data));
		} else {
			toast.error(result.data.error);
		}
		// dispatch(allActions.loadingAction.hideLoading());
	};
	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			<HomeBanner />
			<HomeVideoSection />
			<HomeCaseStudy />
			<HomeAppSection />
			{/* <HomeTechnology /> */}
			<HomeTestimonials />
			<HomeSuccessProduct />
			<HomePartner />
			<Footer />
			<LetsTalk />
		</>
	);
}

export default Home;