import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import './HowWeWorkInnerContent.scss';
import './HowWeWorkInnerContentResponsive.scss';
// import iconOne from 'assets/images/common-image/how-we-work/1.svg';
// import iconTwo from 'assets/images/common-image/how-we-work/2.svg';

import stepsOne from 'assets/images/common-image/steps/1.jpg';
import stepsTwo from 'assets/images/common-image/steps/2.jpg';
import stepsThree from 'assets/images/common-image/steps/3.jpg';
import stepsFour from 'assets/images/common-image/steps/4.jpg';
import stepsFive from 'assets/images/common-image/steps/5.jpg';
import stepsSix from 'assets/images/common-image/steps/6.jpg';

const HowWeWorkInnerContent = () => {
	const howWeWorkContent = useSelector((state) => state?.content?.howWeWork);
	const content = howWeWorkContent?.howWeWorkData?.content ? JSON.parse(howWeWorkContent?.howWeWorkData?.content) : '';

	useEffect(() => {
		// getContent();
		// var createShape = document.getElementById("shape");
		// var length = createShape.getTotalLength();
		// createShape.style.strokeDasharray = length;
		// createShape.style.strokeDashoffset = length;
		// window.onscroll = function myFunction() {
		//     var scrollpercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
		//     var draw = length * scrollpercent;
		//     createShape.style.strokeDashoffset = length - draw;
		// }
	}, []);
	return (
		<>
			<section className="stepsOnly relative text-center">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="w-100 text-center aboutTitle">
								<h2>Start In 3 Steps Only</h2>
							</div>
						</div>
						<div className="col-12 d-flex align-items-center min-h-655">
							<div className="stepsInner d-flex relative">
								<div className="stepsInnerLeft">
									<div className="stepsInnerLeftTop">
										<h4 dangerouslySetInnerHTML={{ __html: content?.steps?.[0]?.title ?? 'Cost-Effectiveness' }}></h4>
										<p
											dangerouslySetInnerHTML={{
												__html:
													content?.steps?.[0]?.description ?? 'We tend to perceive each project as an opportunity to make use of our intrinsic passion',
											}}
										></p>
										{/* <div className="d-flex w-100 justify-content-end">
											<Link to={'/'}>
												Learn More <span></span>
											</Link>
										</div> */}
									</div>
									<div className="stepsInnerCircle">1</div>
									<div className="stepsInnerLeftBtm">
										<img src={content?.steps?.[0]?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.steps?.[0]?.img}` : stepsOne} alt="step 1 img" />
									</div>
								</div>
								<div className="stepsInnerMid">
									<div className="stepsInnerMidTop">
										<img src={content?.steps?.[1]?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.steps?.[1]?.img}` : stepsThree} alt="step 2 img" />
									</div>
									<div className="stepsInnerCircle">2</div>
									<div className="stepsInnerMidBtm">
										<h4 dangerouslySetInnerHTML={{ __html: content?.steps?.[1]?.title ?? 'Client Prioritization' }}></h4>
										<p
											dangerouslySetInnerHTML={{
												__html:
													content?.steps?.[1]?.description ?? `We tend to perceive each project as an opportunity to make use of our intrinsic passion`,
											}}
										></p>
										{/* <div className="d-flex w-100 justify-content-end">
											<Link to={'/'}>
												Learn More <span></span>
											</Link>
										</div> */}
									</div>
								</div>
								<div className="stepsInnerRight">
									<div className="stepsInnerLeftTop">
										<h4 dangerouslySetInnerHTML={{ __html: content?.steps?.[2]?.title ?? '24 hours Availability' }}></h4>
										<p
											dangerouslySetInnerHTML={{
												__html:
													content?.steps?.[2]?.description ?? `We tend to perceive each project as an opportunity to make use of our intrinsic passion`,
											}}
										></p>
										{/* <div className="d-flex w-100 justify-content-end">
											<Link to={'/'}>
												Learn More <span></span>
											</Link>
										</div> */}
									</div>
									<div className="stepsInnerCircle">3</div>
									<div className="stepsInnerLeftBtm ">
										<img src={content?.steps?.[2]?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.steps?.[2]?.img}` : stepsTwo} alt="step 3 img" />
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 d-flex align-items-center min-h-655">
							<div className="stepsInner d-flex relative">
								<div className="stepsInnerLeft">
									<div className="stepsInnerLeftTop">
										<h4 dangerouslySetInnerHTML={{ __html: content?.steps?.[3]?.title ?? 'Latest Technology' }}></h4>
										<p
											dangerouslySetInnerHTML={{
												__html:
													content?.steps?.[3]?.description ?? `We tend to perceive each project as an opportunity to make use of our intrinsic passion`,
											}}
										></p>
										{/* <div className="d-flex w-100 justify-content-end">
											<Link to={'/'}>
												Learn More <span></span>
											</Link>
										</div> */}
									</div>
									<div className="stepsInnerCircle">4</div>
									<div className="stepsInnerLeftBtm">
										<img src={content?.steps?.[3]?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.steps?.[3]?.img}` : stepsFour} alt="step 4 img" />
									</div>
								</div>
								<div className="stepsInnerMid">
									<div className="stepsInnerMidTop">
										<img src={content?.steps?.[4]?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.steps?.[4]?.img}` : stepsFive} alt="step 5 img" />
									</div>
									<div className="stepsInnerCircle">5</div>
									<div className="stepsInnerMidBtm">
										<h4 dangerouslySetInnerHTML={{ __html: content?.steps?.[4]?.title ?? 'On Time Assistance' }}></h4>
										<p
											dangerouslySetInnerHTML={{
												__html:
													content?.steps?.[4]?.description ?? `We tend to perceive each project as an opportunity to make use of our intrinsic passion`,
											}}
										></p>
										{/* <div className="d-flex w-100 justify-content-end">
											<Link to={'/'}>
												Learn More <span></span>
											</Link>
										</div> */}
									</div>
								</div>
								<div className="stepsInnerRight">
									<div className="stepsInnerLeftTop">
										<h4 dangerouslySetInnerHTML={{ __html: content?.steps?.[5]?.title ?? 'Customizable Solutions' }}></h4>
										<p
											dangerouslySetInnerHTML={{
												__html:
													content?.steps?.[5]?.description ?? `We tend to perceive each project as an opportunity to make use of our intrinsic passion`,
											}}
										></p>
										{/* <div className="d-flex w-100 justify-content-end">
											<Link to={'/'}>
												Learn More <span></span>
											</Link>
										</div> */}
									</div>
									<div className="stepsInnerCircle">6</div>
									<div className="stepsInnerLeftBtm ">
										<img src={content?.steps?.[5]?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${content?.steps?.[5]?.img}` : stepsSix} alt="step 6 img" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <section className='hwiInnerContent'>
                <div className="container">
                    <div className="row m-0">
                        <svg id="mySVG" width={400} height={1500}>
                            <path d="M 200 5 A 50 50 0 1 0 200 200 A 50 50 0 1 1 200 400 A 50 50 0 1 0 200 600 A 50 50 0 1 1 200 800 A 50 50 0 1 0 200 1000 A 50 50 0 1 1 200 1200 A 50 50 0 1 0 200 1400" fill="none" stroke="#b8fbe8" strokeWidth="4px" id="shape" style={{ strokeDasharray: '10', strokeDashoffset: '10' }} />
                        </svg>
                    </div>
                    <div className="row align-items-center right">
                        <div className="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
                            <h2 class="light">01</h2>
                            <h2 class="bold">Intellectual Property</h2>
                        </div>
                        <div className="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
                            <img src={iconOne} alt="" />
                        </div>
                        <div className="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-right text-md-left mt-3 mt-md-0 aos-init aos-animate">
                            <p class="hero">All our projects are started with non-disclosure agreements to ensure the safety of sensitive data.</p>
                        </div>
                    </div>
                    <div class="row flex-row-reverse flex-md-row align-items-center left">
                        <div class="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-left text-md-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
                            <h2 class="light">02</h2>
                            <h2 class="bold">Discovery Session</h2>
                        </div>
                        <div class="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
                            <img alt="" src={iconTwo} />
                        </div>
                        <div class="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-left aos-init aos-animate">
                            <p class="hero">Our team of experts run a product through a thorough analysis to deliberate the main outcome of the business.</p>
                        </div>
                    </div>
                    <div className="row align-items-center right">
                        <div className="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
                            <h2 class="light">03</h2>
                            <h2 class="bold">Rapid Strategy Workshop</h2>
                        </div>
                        <div className="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
                            <img src={iconOne} alt="" />
                        </div>
                        <div className="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-right text-md-left mt-3 mt-md-0 aos-init aos-animate">
                            <p class="hero">All our projects are started with non-disclosure agreements to ensure the safety of sensitive data.</p>
                        </div>
                    </div>
                    <div class="row flex-row-reverse flex-md-row align-items-center left">
                        <div class="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-left text-md-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
                            <h2 class="light">04</h2>
                            <h2 class="bold">User Interfaces & Experiences</h2>
                        </div>
                        <div class="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
                            <img alt="" src={iconTwo} />
                        </div>
                        <div class="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-left aos-init aos-animate">
                            <p class="hero">Our team of experts run a product through a thorough analysis to deliberate the main outcome of the business.</p>
                        </div>
                    </div>

					<div className="row align-items-center right">
						<div className="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
							<h2 class="light">05</h2>
							<h2 class="bold">Agile Development</h2>
						</div>
						<div className="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
							<img src={iconOne} alt="" />
						</div>
						<div className="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-right text-md-left mt-3 mt-md-0 aos-init aos-animate">
							<p class="hero">
								All our projects are started with non-disclosure agreements to ensure the safety of
								sensitive data.
							</p>
						</div>
					</div>
					<div class="row flex-row-reverse flex-md-row align-items-center left">
						<div class="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-left text-md-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
							<h2 class="light">06</h2>
							<h2 class="bold">Quality Assurance</h2>
						</div>
						<div class="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
							<img alt="" src={iconTwo} />
						</div>
						<div class="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-left aos-init aos-animate">
							<p class="hero">
								Our team of experts run a product through a thorough analysis to deliberate the main
								outcome of the business.
							</p>
						</div>
					</div>

                    <div className="row align-items-center right">
                        <div className="order-2 order-sm-0 col-12 col-sm-6 col-md-4 text-center text-sm-right pr-auto pr-md-5 pr-xl-0 aos-init aos-animate">
                            <h2 class="light">07</h2>
                            <h2 class="bold">Support and maintenance</h2>
                        </div>
                        <div className="order-1 order-sm-0 col-6 mx-auto mx-sm-0 col-sm-6 col-md-4 text-center mb-res aos-init aos-animate">
                            <img src={iconOne} alt="" />
                        </div>
                        <div className="order-3 order-sm-0 col-sm-6 col-md-4 text-center text-sm-right text-md-left mt-3 mt-md-0 aos-init aos-animate">
                            <p class="hero">All our projects are started with non-disclosure agreements to ensure the safety of sensitive data.</p>
                        </div>
                    </div>
                </div>
            </section> */}
		</>
	);
};

export default HowWeWorkInnerContent;
