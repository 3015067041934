import React, { useEffect, useState } from 'react';
import './BlogDetails.scss';
import './BlogDetailsResponsive.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';

import BlogLatestNews from 'components/BlogLatestNews';

// import BlogBanner from 'components/BlogBanner/BlogBanner';
import blogOne from 'assets/images/common-image/bolg/1.jpg';
import { getContentService } from './BlogDetails.service.js';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const BlogDetails = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();
	const [blog, setBlog] = useState({});
	const [recentBlog, setRecentBlog] = useState([]);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getContent()]).then(() => {
			setTimeout(function () {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		});
	}, []);

	const getContent = async () => {
		const result = await getContentService(slug);
		if (result.data.status === 200) {
			setBlog(result?.data?.data?.blogDetail);
			setRecentBlog(result?.data?.data?.recentBlog);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Helmet>
				<meta httpEquiv="content-language" content="en" />
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="keywords" content="website, blog, foo, bar" />
				<meta name="description" content="This short description describes my website. " />
				<meta name="robots" content="index, follow" />
				<title>Aqualeaf It Solutions</title>
			</Helmet>
			<Header />
			{/* <BlogBanner /> */}
			<section className="blogTitle">
				<h2>blog details</h2>
			</section>
			<section className="p-v-40 blogDetails">
				<div className="container">
					<div className="row">
						<div className="col-lg-9 col-md-9 col-sm-12 col-12">
							<div className="relative">
								<div className="box-img fullwidth relative">
									<img src={blog?.img ? `${process.env.REACT_APP_RESOURCE_URL}/${blog?.img}` : blogOne} alt="" />
								</div>
								<div className="box-date bgColor">
									<div className="date">
										{moment(blog?.createdAt).format('DD-MM-YYYY')[0]}
										<span>{moment(blog?.createdAt).format('DD-MM-YYYY')[1]}</span>
									</div>
									<div className="month">{moment(blog?.createdAt).format('MMM')}</div>
								</div>
							</div>
							<div className="box-content p-l-0 mt-3">
								{/* <div className="meta-tag">
									<div className="info d-flex">
										<div className="item blogAuthor">
											Posted By : <a href="#">John Smith</a>
										</div>
										<div className="item views">
											<a href="#">
												<i className="fa fa-user" /> View : 56
											</a>
										</div>
									</div>
								</div> */}
								<h4
									dangerouslySetInnerHTML={{
										__html: blog?.title ?? `Many people limit themselves`,
									}}
								></h4>
								<div
									className="blogText blog-entry fullwidth m-b-20"
									dangerouslySetInnerHTML={{
										__html: blog?.description ?? `Many people limit themselves`,
									}}
								></div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-12">
							<BlogLatestNews data={recentBlog} />
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default BlogDetails;
